import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Dropzone from "react-dropzone";
import LinearProgress from "@material-ui/core/LinearProgress";
import {db, storage} from "common/firebase";
import ReactTimeout from "react-timeout";
import Fab from "@material-ui/core/Fab";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { StyledButton } from "common/theme";
import classNames from 'classnames';
import Loader from "components/Loader";
import * as constants from "common/constants";
import moment from "moment";
import * as utils from "common/utils";

const styles = theme => ({
    info: {
        padding: '5px 20px',
        margin: '15px 0'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    dropzone: {
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        padding: theme.spacing(3),
        textAlign: 'center',
    },
    dropzoneAdd: {
        background: 'lightgreen',
        cursor: 'copy',
    },
    dropzoneErrorMessage: {
        color: theme.palette.secondary.main,
    },
    warning: {
        color: theme.palette.secondary.main,
    },
    doneLinearProgress: {
        backgroundColor: 'lightgreen',
    }
});

const SUFFIX_DEBUG = "";
const TRANSFORMATION_CHECK_INTERVAL = 5000;
const CONTENT_TYPE = {
    // mime: 'application/vnd.ms-excel',
    // extension: 'xls',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    extension: 'xlsx',
};

class FoodsRewe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            transformationsData: [],
            allTransformationsData: [],
            uploadDialogOpen: false,
            uploadingProgress: 0,
            dropzoneCssClasses: [this.props.classes.dropzone],
            dropzoneErrorMessages: [],
            fileToUpload: null,
            startExportButtonDisabled: true,
        };
    }

    componentDidMount() {

        this.checkTransformations()
            .then(() => {
                this.setState({loading: false});
                this.props.setInterval(this.checkTransformations, TRANSFORMATION_CHECK_INTERVAL);
            });
    }

    shouldButtonStartExportShouldBeDisabled = () => {
        let disabled = false;
        // eslint-disable-next-line
        this.state.allTransformationsData.some(transformationsDatum => {
            if (transformationsDatum.progress < 1) {
                disabled = true;
            }
        });
        return disabled;
    };

    handleOpenUploadDialog = () => {
        this.setState({uploadDialogOpen: true});
    };

    handleCloseUploadDialog = () => {
        this.setState({
            uploadDialogOpen: false,
            dropzoneErrorMessages: [],
            fileToUpload: null,
        });
    };

    handleSaveUpload = () => {
        this.setState({
            uploadingProgress: 0,
        });

        if (!this.state.fileToUpload) {
            this.state.dropzoneErrorMessages.push('Invalid file for upload!');
            return;
        }

        // Create the file metadata
        const metadata = {
            contentType: CONTENT_TYPE.mime,
        };

        const id = new Date().getTime();
        const filePath = `uploads/rewe-${id}.${CONTENT_TYPE.extension}`;

        // Create a reference to the file we want to upload
        const pathReference = storage.ref(filePath);

        let uploadTask = pathReference.put(this.state.fileToUpload, metadata);
        this.setState({uploadTask: uploadTask});

        uploadTask.on('state_changed', (snapshot) => {
            this.setState({
                uploadingProgress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            });
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                default:
                    break;
            }
        }, (error) => {
            const errorPrefix = 'Firebase-put-file:: ';
            let errorMessage = '';
            switch (error.code) {
                case 'storage/unauthorized':
                    errorMessage = "User doesn't have permission to access the object";
                    break;
                case 'storage/canceled':
                    errorMessage = "User canceled the upload";
                    break;
                case 'storage/unknown':
                    errorMessage = "Unknown error occurred, inspect the error.serverResponse";
                    break;
                default:
                    errorMessage = "All gone haywire!!";
                    break;
            }
            console.error(errorPrefix + errorMessage)
        }, async () => {
            uploadTask.snapshot.ref.getDownloadURL().then(async downloadURL => {
                console.log('File available at', downloadURL);
                console.info(id);
                await db
                    .collection(`transformations${SUFFIX_DEBUG}`)
                    .doc(`${constants.TRANSFORMATION_TYPE_REWE}-${moment().format('YYYY-MM-DD-HH-mm-ss')}`)
                    .set({
                        filename: `rewe-${id}.${CONTENT_TYPE.extension}`,
                        originalFilename: this.state.fileToUpload.name,
                        pointer: 0,
                        progress: 0,
                        counter: 0,
                        status: 'inProgress',
                        created: new Date(),
                        type: constants.TRANSFORMATION_TYPE_REWE,
                    }, {merge: true});
                this.checkTransformations()
                    .then(() => {
                        this.setState({
                            uploadDialogOpen: false,
                            uploadingProgress: 0,
                            dropzoneErrorMessages: [],
                            fileToUpload: null,
                        });
                    });
            });
        });
    };

    handleDropFile = (files) => {
        console.info(files);
    };

    handleDropFileAccepted = (files, event) => {
        this.setState({
            dropzoneErrorMessages: [],
            fileToUpload: files[0],
        });
        this.resetDropzoneCSS();
    };

    handleDropFileRejected = (files, event) => {

        this.setState({
            dropzoneErrorMessages: [],
        });

        if (files.length > 1) {
            this.state.dropzoneErrorMessages.push('Only one file is allowed!');
        }

        this.resetDropzoneCSS();
    };

    handleDragEnter = (event) => {
        const dropzoneCssClasses = this.state.dropzoneCssClasses;
        dropzoneCssClasses.push(this.props.classes.dropzoneAdd);
        this.setState({dropzoneCssClasses: dropzoneCssClasses});
    };

    handleDragLeave = (event) => {
        this.resetDropzoneCSS();
    };

    resetDropzoneCSS = () => {
        const dropzoneCssClasses = this.state.dropzoneCssClasses.filter(el => el !== this.props.classes.dropzoneAdd);
        this.setState({
            dropzoneCssClasses: dropzoneCssClasses,
        });
    };

    checkTransformations = async () => {

        await db.collection(`transformations${SUFFIX_DEBUG}`)
            .orderBy('created', 'desc')
            .get()
            .then(async transformationsDocs => {
                await this.setState({
                    allTransformationsData: transformationsDocs.docs
                        .map(transformationsDoc => utils.getDataWithIdFromFirebaseDocument(transformationsDoc)),
                    transformationsData: transformationsDocs.docs
                        .filter(transformationsDoc => transformationsDoc.data().type === constants.TRANSFORMATION_TYPE_REWE)
                        .map(transformationsDoc => utils.getDataWithIdFromFirebaseDocument(transformationsDoc)),
                })
            })
            .catch(e => {
                console.error('E0049', e);
            });

        await this.setState({
            startExportButtonDisabled: this.shouldButtonStartExportShouldBeDisabled(),
        });
    };

    render() {

        const {classes, fullScreen} = this.props;

        const {startExportButtonDisabled, loading} = this.state;

        if (loading) {
            return <Loader/>;
        }

        return (

            <React.Fragment>
                <React.Fragment>
                    <MUIDataTable
                        title={"REWE and Dr.Almond Upload History"}
                        data={this.state.transformationsData}
                        columns={[
                            {
                                name: "created",
                                label: 'Uploaded at',
                                options: {
                                    filter: true,
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        if (value !== null && value !== undefined) {
                                            return moment.unix(value.seconds).format("LLL");
                                        } else {
                                            return '';
                                        }
                                    },
                                }
                            },
                            {
                                name: "originalFilename",
                                label: 'Filename',
                                options: {
                                    filter: true,
                                    sort: true,
                                }
                            },
                            {
                                name: "progress",
                                label: 'Progress',
                                options: {
                                    filter: true,
                                    sort: true,
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        return (
                                            <LinearProgress
                                                classes={{
                                                    barColorPrimary: value === 1 && classes.doneLinearProgress
                                                }}
                                                variant="determinate"
                                                value={value * 100}
                                            />
                                        );
                                    },
                                }
                            },
                            {
                                name: "status",
                                label: 'Status',
                                options: {
                                    filter: false,
                                    sort: false,
                                }
                            },
                        ]}
                        options={{
                            responsive: "scroll",
                            print: false,
                            download: false,
                            search: false,
                            filter: false,
                            rowHover: false,
                            selectableRows: false,
                            customSort: (data, colIndex, order) => {
                                return data.sort((a, b) => {
                                    return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
                                });
                            },
                        }}
                    />
                    <Fab
                        className={classes.fab}
                        color={"secondary"}
                        onClick={this.handleOpenUploadDialog}
                        disabled={startExportButtonDisabled}
                    >
                        <AddIcon/>
                    </Fab>

                    <Dialog
                        fullScreen={fullScreen}
                        open={this.state.uploadDialogOpen}
                        onClose={this.handleCloseUploadDialog}
                        aria-labelledby="responsive-dialog-title"
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                    >
                        <DialogTitle id="responsive-dialog-title">{"Uploading Rewe and Dr.Almond file"}</DialogTitle>
                        {this.state.uploadingProgress ? (
                            <React.Fragment>
                                <DialogContent>
                                    <DialogContentText>
                                        Please wait...<br/>
                                        <span className={classes.warning}>
                                                    Leaving this page before the upload is done will cancel transformation!
                                                </span>
                                    </DialogContentText>
                                    <LinearProgress variant="determinate" value={this.state.uploadingProgress}/>
                                </DialogContent>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <DialogContent>
                                    <DialogContentText>
                                        This will upload file and start generating structure in the database.
                                    </DialogContentText>
                                    <Dropzone
                                        onDrop={this.handleDropFile}
                                        onDropAccepted={this.handleDropFileAccepted}
                                        onDropRejected={this.handleDropFileRejected}
                                        onDragEnter={this.handleDragEnter}
                                        onDragLeave={this.handleDragLeave}
                                        accept={CONTENT_TYPE.mime}
                                        multiple={false}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p className={classNames(this.state.dropzoneCssClasses)}>
                                                        Drag 'n' drop a file here, or click to select file
                                                    </p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    <Typography>{this.state.fileToUpload && this.state.fileToUpload.name}</Typography>
                                    {this.state.dropzoneErrorMessages.map((errorMessage, key) => (
                                        <Typography key={key}
                                                    className={classes.dropzoneErrorMessage}>{errorMessage}</Typography>
                                    ))}
                                </DialogContent>
                                <DialogActions>
                                    <StyledButton onClick={this.handleCloseUploadDialog}>
                                        Cancel
                                    </StyledButton>
                                    <StyledButton 
                                        disabled={!this.state.fileToUpload} 
                                        onClick={this.handleSaveUpload}
                                        color="primary" 
                                        variant="contained" 
                                        autoFocus
                                    >
                                        Upload
                                    </StyledButton>
                                </DialogActions>
                            </React.Fragment>
                        )}
                    </Dialog>
                </React.Fragment>
            </React.Fragment>
        )
    }
}

FoodsRewe.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(FoodsRewe));
import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Images from "components/Images";

// import Ingredient from "./Ingredient";

const styles = theme => ({
  panel: {
    margin: "16px"
  },
  panelDetails: {
    flexDirection: "column"
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  deleteContainer: {
    justifyContent: "center",
    width: "100%"
  }
});

class Tools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      //TODO implement adding new tool
      data: this.props.data,
      changed: false
    };
  }

  componentDidUpdate() {
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  handleExpandedChange() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleChange(e) {
    let { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data, changed: true });
  }

  handleImageChange(data) {
    const { property, value, id } = data;
    // console.log(`property: ${property}`, `value: ${value}`, `id: ${id}`);
    const toolImages = [...this.props.toolImages];
    toolImages[id] = value;
    this.props.setProperty({ property, value: toolImages });
  }

  handleBlur(e) {
    if (this.state.changed) {
      const { id } = e.target;
      this.setState(
        { changed: false },
        () =>
          this.props.callback &&
          this.props.callback({
            i: id,
            data: this.state.data[id]
          })
      );
    }
  }

  handleDelete(e) {
    this.props.onDelete && this.props.onDelete(e.currentTarget.id);
  }

  handleImageDelete() {
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  render() {
    const { classes, lang, template } = this.props;
    const forbiddenAccess =
      template.forbiddenAccess &&
      template.forbiddenAccess.includes(this.props.userLevel);
    return this.state.data && this.state.data.length > 0 ? (
      <ExpansionPanel
        className={classes.panel}
        expanded={this.state.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {this.state.data && this.state.data.length > 0
              ? !this.state.expanded
                ? this.state.data.map(
                    (el, i) =>
                      `${el}${i < this.state.data.length - 1 ? ", " : ""}`
                  )
                : template.label[lang]
              : forbiddenAccess
              ? null
              : "Add new Tool..."}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.subcategoriesPanelDetails}>
          <Grid container className={classes.panelDetails}>
            {this.state.data && this.state.data.length > 0 &&
              this.state.data.map((tool, i) => {
                return (
                  <React.Fragment key={`tool-${tool}-${i}`}>
                    <TextField
                      className={classes.textField}
                      autoFocus
                      id={`${i}`}
                      label={`${template.label[lang]}`}
                      value={tool}
                      // error={this.props.getValidatedValue(value, element) === false}
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange.bind(this)}
                      onBlur={this.handleBlur.bind(this)}
                    />
                    <Images
                      id={i}
                      key={template.children.toolImages.name}
                      template={template.children.toolImages}
                      lang={lang}
                      images={this.props.toolImages[i]}
                      callback={this.handleImageChange.bind(this)}
                      onDelete={this.handleImageDelete.bind(this)}
                      disabled={
                        template.children.toolImages.forbiddenAccess &&
                        template.children.toolImages.forbiddenAccess.includes(
                          this.props.userLevel
                        )
                      }
                    />
                    {forbiddenAccess ? null : (
                      <Grid container className={classes.deleteContainer}>
                        <IconButton
                          id={i}
                          onClick={this.handleDelete.bind(this)}
                          className={classes.delete}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })}
            {forbiddenAccess ? null : (
              <Paper className={classes.addButtonContainer}>
                <Button
                  className={classes.addButton}
                  onClick={() =>
                    this.props.callback &&
                    this.props.callback({
                      i: this.state.data.length,
                      data: null
                    })
                  }
                >
                  <AddIcon />
                </Button>
              </Paper>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ) : null;
  }
}

export default withStyles(styles, { withTheme: true })(Tools);

import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";

import Ingredient from "./Ingredient";
import Images from "components/Images";
import LocalizedProperty from "components/LocalizedProperty";

const styles = theme => ({
  panel: {
    margin: "16px"
  },
  panelDetails: {
    flexDirection: "column"
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  badge: {
    padding: theme.spacing(0, 1)
  },
  deleteContainer: {
    justifyContent: "center",
    width: "100%"
  }
});

class IngredientsGroup extends React.Component {
  constructor(props) {
    super(props);

    let expanded = this.props.data.expanded;
    if (expanded === undefined) {
      expanded = false;
    }
    this.state = {
      //TODO implement adding new ingredientGroup
      data: { ...this.props.data, expanded },
      // data: this.props.data,
      // expanded: this.props.data.expanded,
      changed: false,
      error: this.props.error
    };
  }

  handleExpandedChange(e) {
    let { data } = this.state;
    data.expanded = !this.state.data.expanded;
    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data,
          changed: this.state.changed
        })
    );
  }

  handleBlur(obj) {
    let { data, changed } = this.state;
    if (obj && obj.value != null) {
      if (!data.name) {
        data.name = {};
      }
      data.name[obj.lang] = obj.value;
      changed = true;
    }

    if (changed) {
      this.setState(
        { changed: false, data },
        () =>
          this.props.callback &&
          this.props.callback({
            i: this.props.i,
            data: this.state.data
            // changed: true
          })
      );
    }
  }

  handleImageChange(obj) {
    const { /*id, property,*/ value } = obj;
    let data = { ...this.state.data };
    data.images = value;
    this.setState(
      { data, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data,
          changed: this.state.changed
        })
    );
  }

  handleIngredientChange(i, ingredient) {
    let data = { ...this.state.data };
    data.ingredients[i] = ingredient;
    this.setState(
      { data, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data,
          changed: this.state.changed
        })
    );
  }

  handleDelete() {
    // const { tab } = this.props.template;
    // this.setState(
    //   { error: false },
    //   () => this.props.onError && this.props.onError({ error: false, tab })
    // );
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  onDeleteIngredient(i) {
    this.props.onDeleteIngredient &&
      this.props.onDeleteIngredient({ parent: this.props.i, i });
  }

  onError(obj) {
    const { tab } = this.props.template;
    const { error, name } = obj;
    // console.log("ingredient group", error, name, tab);
    this.setState(
      { error },
      () =>
        this.props.onError &&
        this.props.onError({ error, name: `${this.props.i}-${name}`, tab })
    );
  }

  render() {
    const { classes, lang, template } = this.props;

    const forbiddenAccessIngredient =
      template.children.ingredient.forbiddenAccess &&
      template.children.ingredient.forbiddenAccess.includes(
        this.props.userLevel
      );

    // console.log(template.children.images, this.props.userLevel);
    return (
      <ExpansionPanel
        className={classes.panel}
        expanded={this.state.data.expanded}
        // expanded={this.state.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Badge
            color="secondary"
            variant="dot"
            className={classes.badge}
            invisible={!this.state.error}
          >
            <Typography className={classes.heading}>
              {!this.state.data.name ||
              (!this.state.data.name.en_GB && !this.state.data.name.de_DE)
                ? "New Ingredient Group"
                : `EN: ${
                    this.state.data.name.en_GB
                      ? this.state.data.name.en_GB
                      : "-"
                  }, DE: ${
                    this.state.data.name.de_DE
                      ? this.state.data.name.de_DE
                      : "-"
                  }`}
            </Typography>
          </Badge>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.subcategoriesPanelDetails}>
          <Grid container className={classes.panelDetails}>
            <LocalizedProperty
              value={this.state.data.name ? this.state.data.name : "-"}
              errors={this.props.errors}
              callback={this.handleBlur.bind(this)}
              // onError={this.props.setError}
              template={template.children.name}
              lang={lang}
              disabled={
                template.children.name.forbiddenAccess &&
                template.children.name.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            <Images
              id={this.props.i}
              key={template.name}
              template={template.children.images}
              lang={lang}
              images={this.state.data.images}
              callback={this.handleImageChange.bind(this)}
              onDelete={this.props.onDelete}
              disabled={
                template.children.images.forbiddenAccess &&
                template.children.images.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            {this.state.data.ingredients.length > 0 ? (
              this.state.data.ingredients.map((el, i) => (
                <Ingredient
                  key={`ingredient-${i}-${this.props.i}`}
                  i={i}
                  id={this.props.i}
                  data={el}
                  template={template.children.ingredient}
                  lang={lang}
                  callback={this.handleIngredientChange.bind(this)}
                  onBlur={this.handleBlur.bind(this)}
                  onError={this.onError.bind(this)}
                  onDelete={this.onDeleteIngredient.bind(this)}
                  userLevel={this.props.userLevel}
                />
              ))
            ) : forbiddenAccessIngredient ? null : (
              <Typography>Add some ingredient...</Typography>
            )}
            {forbiddenAccessIngredient ? null : (
              <Grid container className={classes.deleteContainer}>
                <IconButton
                  onClick={this.handleDelete.bind(this)}
                  className={classes.delete}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
            {forbiddenAccessIngredient ? null : (
              <Paper className={classes.addButtonContainer}>
                <Button
                  className={classes.addButton}
                  onClick={() =>
                    this.handleIngredientChange(
                      this.state.data.ingredients.length,
                      {
                        expanded: true,
                        amount: null,
                        baseUnit: null,
                        name: null,
                        notes: null,
                        ref: null
                      }
                    )
                  }
                >
                  <AddIcon />
                </Button>
              </Paper>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles, { withTheme: true })(IngredientsGroup);

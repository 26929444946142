import * as constants from 'common/constants';
import * as routes from "common/routes";

class Auth {

    static async isAdmin(user) {
        return new Promise((resolve) => {
            user.getIdTokenResult()
                .then((idTokenResult) => {
                    resolve(idTokenResult.claims.accessLevel === constants.USER_LEVEL_ADMIN);
                });
        });
    };

    static async getMobileToken(mobile, code) {
        return new Promise(async resolve => {
            return mobile + code;
        })
    }

    static async validate(user) {

        return new Promise(async (resolve, reject) => {


            await user.getIdTokenResult()
                .then(async res => {
                    const accessLevel = res.claims.accessLevel;

                    // email not verified
                    if (!user.emailVerified) {
                        // await auth.signOut();
                        // redirect to info
                        reject({
                            pathname: routes.INFO,
                            state: {
                                from: routes.LOGIN,
                                message: 'Please verify your email',
                            }
                        });
                    }

                    // redirect to info if user is not promoted by admin
                    if (accessLevel === constants.USER_LEVEL_NEW) {
                        // await auth.signOut();
                        reject({
                            pathname: routes.INFO,
                            state: {
                                from: routes.LOGIN,
                                message: 'Please contact product admin to give you access rights...',
                                actionButtons: [
                                    {
                                        text: 'Login',
                                        url: routes.LOGIN,
                                    },
                                ],
                            }
                        })
                    }

                    // all else (USER_LEVEL_ADMIN, USER_LEVEL_EMPLOYEE, USER_LEVEL_EXTERNAL_EMPLOYEE
                    // redirect to admin
                    resolve({
                        pathname: routes.ADMIN,
                        state: {
                            from: routes.LOGIN,
                        }
                    });
                })
                .catch(e => {
                    console.info('E0022', e.toString());
                    // auth.signOut();
                    reject({
                        pathname: routes.INFO,
                        state: {
                            from: routes.LOGIN,
                            message: 'Invalid User, please contact admin!',
                            actionButtons: [{
                                text: 'Contact Admin',
                                url: 'https://unknown.com',
                            }],
                        }
                    })
                });
        });

    }

    static async isValidPromise(user) {
        return new Promise(async (resolve, reject) => {

            if (!user.emailVerified) {
                resolve({
                    status: false,
                    details: 'emailNotVerified',
                })
            }

            await user.getIdTokenResult()
                .then((idTokenResult) => {
                    // resolve(!!idTokenResult.claims.admin);


                })
                .catch(e => {
                    console.error('E0021', e.toString());
                    reject({
                        status: false,
                        details: 'invalidIdToken',
                    });
                })
        });
    }
}

export default Auth;

import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";

import DeleteIcon from "@material-ui/icons/Delete";

import Instruction from "./Instruction";
import Images from "components/Images";
import BasicProperty from "components/BasicProperty";
import LocalizedProperty from "components/LocalizedProperty";

const styles = theme => ({
  panel: {
    margin: "16px"
  },
  panelDetails: {
    flexDirection: "column"
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  deleteContainer: {
    justifyContent: "center",
    width: "100%"
  }
});

class Instructions extends React.Component {
  constructor(props) {
    super(props);

    let expanded = this.props.data.expanded;
    if (expanded === undefined) {
      expanded = false;
    }
    this.state = {
      data: { ...this.props.data, expanded },
      changed: false
    };
  }

  handleExpandedChange() {
    let { data } = this.state;
    data.expanded = !this.state.data.expanded;
    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data
        })
    );
  }

  handleChange(obj) {
    const { property, value, lang } = obj;
    let { data } = this.state;
    if (lang) {
      data[property][lang] = value;
    } else {
      data[property] = value;
    }
    // console.log(property, value, obj);
    this.setState(
      { data, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data
        })
    );
  }

  handleBlur() {
    if (this.state.changed) {
      this.setState(
        { changed: false },
        () =>
          this.props.callback &&
          this.props.callback({
            i: this.props.i,
            data: this.state.data
          })
      );
    }
  }

  handleImageChange(obj) {
    const { value } = obj;
    let data = { ...this.state.data };
    data.images = value;
    this.setState(
      { data, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data
        })
    );
  }

  handleInstructionChange(i, instruction) {
    // console.log(i, instruction);
    let { data } = this.state;
    // console.log("before", data);
    data.step[i] = instruction;
    // console.log("after", data);
    this.setState(
      { data, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          i: this.props.i,
          data: this.state.data
        })
    );
  }

  handleDelete() {
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  onDeleteStep(i) {
    this.props.onDeleteStep &&
      this.props.onDeleteStep({ parent: this.props.i, i });
  }

  render() {
    const { classes, lang, template } = this.props;
    const forbiddenAccess =
      template.forbiddenAccess &&
      template.forbiddenAccess.includes(this.props.userLevel);
    return (
      <ExpansionPanel
        className={classes.panel}
        expanded={this.state.data.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {!this.state.data.name ||
            (!this.state.data.name.en_GB && !this.state.data.name.de_DE)
              ? "New Instruction"
              : `EN: ${
                  this.state.data.name.en_GB ? this.state.data.name.en_GB : "-"
                }, DE: ${
                  this.state.data.name.de_DE ? this.state.data.name.de_DE : "-"
                }`}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.subcategoriesPanelDetails}>
          <Grid container className={classes.panelDetails}>
            <LocalizedProperty
              className={classes.item}
              template={template.children.name}
              lang={lang}
              value={this.state.data.name}
              callback={this.handleChange.bind(this)}
              disabled={
                template.children.name.forbiddenAccess &&
                template.children.name.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            <Images
              id={this.props.i}
              template={template.children.images}
              lang={lang}
              images={this.state.data.images}
              callback={this.handleImageChange.bind(this)}
              onDelete={this.props.onDelete}
              disabled={
                template.children.images.forbiddenAccess &&
                template.children.images.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            <BasicProperty
              className={classes.item}
              template={template.children.prepTime}
              lang={lang}
              value={this.state.data.prepTime}
              callback={this.handleChange.bind(this)}
              disabled={
                template.children.prepTime.forbiddenAccess &&
                template.children.prepTime.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            <BasicProperty
              className={classes.item}
              template={template.children.cookingTime}
              lang={lang}
              value={this.state.data.cookingTime}
              callback={this.handleChange.bind(this)}
              disabled={
                template.children.cookingTime.forbiddenAccess &&
                template.children.cookingTime.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            <BasicProperty
              className={classes.item}
              template={template.children.restTime}
              lang={lang}
              value={this.state.data.restTime}
              callback={this.handleChange.bind(this)}
              disabled={
                template.children.restTime.forbiddenAccess &&
                template.children.restTime.forbiddenAccess.includes(
                  this.props.userLevel
                )
              }
            />
            {this.state.data.step.length > 0 ? (
              this.state.data.step.map((el, i) => {
                return (
                  <Instruction
                    ingredients={this.props.ingredients}
                    tools={this.props.tools}
                    key={`step-${i}-${this.props.i}`}
                    i={i}
                    id={this.props.i}
                    data={el}
                    template={template.children.step}
                    lang={lang}
                    callback={this.handleInstructionChange.bind(this)}
                    onDelete={this.onDeleteStep.bind(this)}
                    userLevel={this.props.userLevel}
                  />
                );
              })
            ) : forbiddenAccess ? null : (
              <Typography>Add some step...</Typography>
            )}
            {forbiddenAccess ? null : (
              <Grid container className={classes.deleteContainer}>
                <IconButton
                  onClick={this.handleDelete.bind(this)}
                  className={classes.delete}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
            {forbiddenAccess ? null : (
              <Paper className={classes.addButtonContainer}>
                <Button
                  className={classes.addButton}
                  onClick={() =>
                    this.handleInstructionChange(this.state.data.step.length, {
                      expanded: true,
                      selectedIngredients: [],
                      selectedTools: [],
                      text: null
                    })
                  }
                >
                  <AddIcon />
                </Button>
              </Paper>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Instructions);

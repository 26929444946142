import React from "react";
import { connect } from "react-redux";

import { getUserLevel } from "store/selectors/auth";
import * as selectors from "store/selectors/categories";
import {
  setRecipe,
  deleteRecipe,
  setProperty,
  setError,
  setToDelete,
  saveAction,
  setInitData,
  discardAllData
} from "store/reducers/categories";

import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";

import { db } from "common/firebase";
import RecipeView from "./RecipeView";
import BasicProperty from "components/BasicProperty";
import LocalizedProperty from "components/LocalizedProperty";
import ConfirmationDialog from "components/ConfirmationDialog";
import EditActions from "components/EditActions";
import { dataTemplate } from "data/categories";

const styles = theme => ({
  container: {
    // border: "1px solid grey"
  },
  content: {
    flexDirection: "column",
    margin: theme.spacing(2)
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  root: {
    width: "100%"
  },
  badge: {
    padding: theme.spacing(0, 1)
  }
});

const stateToProps = state => ({
  name: selectors.getName(state),
  recipes: selectors.getRecipes(state),
  editedPreviously: selectors.getEditedPreviously(state),
  errors: selectors.getErrors(state),
  saveData: selectors.getData(state),
  userLevel: getUserLevel(state)
});

const actionsToProps = dispatch => ({
  setRecipe: payload => dispatch(setRecipe(payload)),
  deleteRecipe: payload => dispatch(deleteRecipe(payload)),
  setProperty: payload => dispatch(setProperty(payload)),
  setError: payload => dispatch(setError(payload)),
  setToDelete: payload => dispatch(setToDelete(payload)),
  saveAction: payload => dispatch(saveAction(payload)),
  setInitData: payload => dispatch(setInitData(payload)),
  discardAllData: payload => dispatch(discardAllData(payload))
});

class CategoryEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      loading: true,
      data: null,
      changes: {},
      shadowDoc: null,
      panelStructure: {},
      topProperties: [],
      confirmationDialog: false
    };
  }

  componentDidMount() {
    if (this.props.doc && this.props.doc.data()) {
      this.props.setInitData(JSON.parse(JSON.stringify(this.props.doc.data())));
      this.checkData();
    } else {
      this.new = true;
    }
  }

  checkData() {
    let data = this.props.doc.data();
    dataTemplate.forEach(el => {
      if (el.required) {
        if (
          data[el.name] === undefined ||
          data[el.name] === null ||
          data[el.name] === ""
        ) {
          const { name, tab } = el;
          this.props.setError({ error: true, name, tab });
        }
      }
    });
  }

  handleSave = async () => {
    if (Object.values(this.props.errors).length > 0) {
      this.setState({
        confirmationAction: "save",
        confirmationDialog: true,
        confirmationText:
          "There are errors in filled in data. You need to fix them before you save."
      });
    } else {
      this.save();
    }
  };

  async save() {
    // console.log(!this.props.errors.length);
    if (!this.props.errors.length && this.props.editedPreviously) {
      this.setState({ loading: true });
      // console.log(this.props.saveData);
      let doc = null;
      if (this.props.doc) {
        // console.log(this.props.doc);
        doc = this.props.doc.ref;
      } else {
        doc = db.collection("/categories").doc();
      }

      doc
        .set(
          this.props.saveData
          // { merge: true }
        )
        .then(() => {
          this.props.handleCancel(this.new ? "new" : this.props.saveData);
          this.props.discardAllData();
        });
    } else {
      this.props.handleCancel(this.new ? "new" : this.props.saveData);
      this.props.discardAllData();
    }
  }

  handleReset = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "reset",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to reset?"
      });
    }
  };

  handleClose = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "cancel",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to cancel?"
      });
    } else {
      this.props.handleCancel();
      this.props.discardAllData();
    }
  };

  handleConfirmation() {
    switch (this.state.confirmationAction) {
      case "cancel":
        this.props.handleCancel();
        this.props.discardAllData();
        break;
      case "reset":
        this.props.setInitData(this.props.doc ? this.props.doc.data() : null);
        break;

      default:
        break;
    }
    this.setState({ confirmationDialog: false });
  }

  changeTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes, lang, open } = this.props;
    // console.log(this.props.description);
    return (
      <React.Fragment>
        <Dialog open={open} disableEnforceFocus fullScreen>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid container className={classes.content}>
                {dataTemplate.map(template => {
                  const forbiddenAccess =
                    template.forbiddenAccess &&
                    template.forbiddenAccess.includes(this.props.userLevel);
                  switch (template.type) {
                    case "textField":
                    case "number":
                      return (
                        <LocalizedProperty
                          key={template.name}
                          value={this.props[template.name]}
                          errors={this.props.errors}
                          callback={this.props.setProperty}
                          onError={this.props.setError}
                          template={template}
                          lang={lang}
                          disabled={forbiddenAccess}
                        />
                      );
                    case "select":
                      return (
                        <BasicProperty
                          key={template.name}
                          template={template}
                          lang={lang}
                          multiple={template.multiple}
                          value={this.props[template.name]}
                          array={template.selectData.map((el, i) => {
                            return { label: el.label[lang], value: i };
                          })}
                          callback={this.props.setProperty}
                          onError={this.props.setError}
                          disabled={forbiddenAccess}
                        />
                      );
                    case "recipes":
                      return (
                        <React.Fragment key={template.name}>
                          {this.props.recipes.length > 0 ? (
                            this.props.recipes.map((recipe, i) => {
                              return (
                                <RecipeView
                                  key={`recipes-${recipe.name}-${i}`}
                                  i={i}
                                  lang={lang}
                                  data={recipe}
                                  template={template}
                                  callback={this.props.setRecipe}
                                  onDelete={this.props.deleteRecipe}
                                  onError={this.props.setError}
                                  error={
                                    this.props.errors &&
                                    this.props.errors[template.tab]
                                      ? this.props.errors[
                                          template.tab
                                        ].filter(el => el.startsWith(i))
                                          .length > 0
                                      : false
                                  }
                                  userLevel={this.props.userLevel}
                                />
                              );
                            })
                          ) : forbiddenAccess ? null : (
                            <Typography>Add some recipes...</Typography>
                          )}
                          {forbiddenAccess ? null : (
                            <Paper className={classes.addButtonContainer}>
                              <Button
                                className={classes.addButton}
                                onClick={() =>
                                  this.props.setRecipe({
                                    i: this.props.recipes.length,
                                    data: {
                                      ref: null,
                                      title: null
                                    }
                                  })
                                }
                              >
                                <AddIcon />
                              </Button>
                            </Paper>
                          )}
                        </React.Fragment>
                      );
                    default:
                      return (
                        <Typography key={template.name}>
                          Not existing field type {template.type} for{" "}
                          {template.name}
                        </Typography>
                      );
                  }
                })}
              </Grid>
            </Grid>
          </DialogContent>
          <EditActions
            reset={this.handleReset}
            cancel={this.handleClose}
            save={this.handleSave}
            edited={this.props.editedPreviously}
          />
        </Dialog>
        <ConfirmationDialog
          action={this.state.confirmationAction}
          open={this.state.confirmationDialog}
          title={this.state.confirmationText}
          cancel={() => this.setState({ confirmationDialog: false })}
          confirm={this.handleConfirmation.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(stateToProps, actionsToProps)(CategoryEdit)
);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import myTheme from "common/theme";
import * as utils from "common/utils";
import * as constants from "common/constants";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({});

class StatusChip extends React.Component {
  render() {
    const { classes, value } = this.props;

    return (
      <MuiThemeProvider theme={myTheme}>
        <Chip
          // avatar={<Avatar><BlockIcon/></Avatar>}
          label={utils.jsUcfirst(value)}
          color={
            value === constants.ENTITY_STATUS_ACTIVE ? "primary" : "secondary"
          }
          className={classes.chip}
          clickable={true}
        />
      </MuiThemeProvider>
    );
  }
}

StatusChip.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(StatusChip);

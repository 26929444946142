// import adminTheme from "common/theme";

export default theme => ({
    // ...adminTheme,
    ...{
        container: {
            marginBottom: theme.spacing(.5),
            padding: theme.spacing(1),
        },
        servingSizesGroup: {
            marginBottom: theme.spacing(3),
            padding: theme.spacing(1),
            boxShadow: "3.1px 3.1px #3e3e3e21",
            border: "1px solid #3e3e3e1f",
        },
        servingSizesTranslationInput: {
            marginBottom: theme.spacing(1.5)
        },
    }
});

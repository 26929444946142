import { createAction } from "redux-actions";

export const SET_PROPERTY = "recipes@@SET_PROPERTY";
export const SET_ERROR = "recipes@@SET_ERROR";
export const SET_INGREDIENT_GROUP = "recipes@@SET_INGREDIENT_GROUP";
export const DELETE_INGREDIENT_GROUP = "recipes@@DELETE_INGREDIENT_GROUP";
export const DELETE_INGREDIENT = "recipes@@DELETE_INGREDIENT";
export const DELETE_INSTRUCTION = "recipes@@DELETE_INSTRUCTION";
export const DELETE_STEP = "recipes@@DELETE_STEP";
export const DELETE_TOOL = "recipes@@DELETE_TOOL";
export const SET_TOOLS = "recipes@@SET_TOOLS";
export const SET_INSTRUCTIONS = "recipes@@SET_INSTRUCTIONS";
export const SET_DIFFICULTY = "recipes@@SET_DIFFICULTY";
export const SET_FOOD_CHOICE = "recipes@@SET_FOOD_CHOICE";
export const SET_TO_DELETE = "recipes@@SET_TO_DELETE";
export const SAVE_ACTION = "recipes@@SAVE_ACTION";
export const SET_INIT_DATA = "recipes@@SET_INIT_DATA";
export const DISCARD_ALL_DATA = "recipes@@DISCARD_ALL_DATA";
export const RESTORE_VERSION_DATA = "recipes@@RESTORE_VERSION_DATA";

const initialState = {
  editedPreviously: false,
  errors: null,
  toDelete: [],
  images: [],
  title: null,
  shortDescription: null,
  description: null,
  servings: null,
  ingredientsGroups: [],
  toolImages: [],
  tools: [],
  instructions: [],
  duration: null,
  difficulty: null,
  foodChoice: null,
  allergens: null,
  nutritionTypes: null,
  mealTypes: null,
  occasions: null,
  mainIngredients: null,
  culinaryRegions: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY: {
      let newState = { ...state };
      const { property, value, lang } = action.payload;
      // console.log("SET PROPERTY");
      // console.log(property, value, lang);

      if (lang) {
        if (!newState[property]) {
          newState[property] = {};
        }
        newState[property][lang] = value;
      } else {
        newState[property] = value;
      }

      // console.log(newState);

      return {
        ...newState,
        editedPreviously: true
      };
    }
    case SET_ERROR: {
      const { error, tab, name } = action.payload;
      // console.log("SET ERROR", error, tab, name);
      const errors = { ...state.errors };
      if (errors[tab] === undefined) {
        errors[tab] = [];
      }

      if (!errors[tab].includes(name)) {
        if (error) {
          errors[tab].push(name);
        }
      } else {
        if (!error) {
          for (var i = 0; i < errors[tab].length; i++) {
            if (errors[tab][i] === name) {
              errors[tab].splice(i, 1);
            }
          }
        }
      }

      if (errors[tab].length === 0) {
        delete errors[tab];
      }

      // console.log("SET ERROR", errors);
      return {
        ...state,
        errors
      };
    }
    case SET_INGREDIENT_GROUP: {
      let { ingredientsGroups } = state;
      let { i, data, changed } = action.payload;

      ingredientsGroups[i] = { ...data };

      // console.log("ingredientsGroups", ingredientsGroups);
      return {
        ...state,
        ingredientsGroups: ingredientsGroups.slice(0),
        editedPreviously: state.editedPreviously
          ? state.editedPreviously
          : changed
      };
    }
    case SET_TOOLS: {
      let tools = [...state.tools];
      let toolImages = [...state.toolImages];
      let { i, data } = action.payload;
      if (data === null) {
        data = "";
        toolImages[i] = [];
      }

      tools[i] = data;
      // toolImages[i] = data;
      // console.log("tools", tools);
      return {
        ...state,
        tools: tools.slice(0),
        toolImages,
        editedPreviously: true
      };
    }
    case SET_INSTRUCTIONS: {
      let { instructions } = state;
      let { i, data } = action.payload;

      instructions[i] = { ...data };

      // console.log("instructions", data);
      return {
        ...state,
        instructions: instructions.slice(0),
        editedPreviously: true
      };
    }
    case DELETE_INGREDIENT: {
      const { parent, i } = action.payload;
      delete state.ingredientsGroups[parent].ingredients[i];
      // let { errors } = state;
      // console.log(`${parent}-${i}`);
      // for (var j = 0; j < errors["ingredients"].length; j++) {
      //   if (errors["ingredients"][j] === `${parent}-${i}`) {
      //     errors["ingredients"].splice(j, 1);
      //   }
      // }
      // console.log(state.ingredientsGroups[parent].ingredients);
      // console.log(errors);
      return {
        ...state,
        // errors,
        editedPreviously: true
      };
    }
    case DELETE_INGREDIENT_GROUP: {
      delete state.ingredientsGroups[action.payload];
      return {
        ...state,
        editedPreviously: true
      };
    }
    case DELETE_INSTRUCTION: {
      delete state.instructions[action.payload];
      return {
        ...state,
        editedPreviously: true
      };
    }
    case DELETE_STEP: {
      const { parent, i } = action.payload;
      delete state.instructions[parent].step[i];
      return {
        ...state,
        editedPreviously: true
      };
    }
    case DELETE_TOOL: {
      delete state.tools[action.payload];
      delete state.toolImages[action.payload];
      return {
        ...state,
        editedPreviously: true
      };
    }
    case SET_TO_DELETE: {
      let { toDelete } = state;
      toDelete.push(action.payload);

      // console.log("todelete", toDelete);
      return {
        ...state,
        toDelete: toDelete.slice(0),
        editedPreviously: true
      };
    }
    case SAVE_ACTION: {
      // console.log("saveAction", state);
      return {
        ...state
      };
    }
    case SET_INIT_DATA: {
      let newState = { ...state };
      if (action.payload !== null) {
        for (const key in newState) {
          if (Array.isArray(newState[key])) {
            newState[key] = action.payload[key] ? action.payload[key] : [];
          } else {
            // eslint-disable-next-line
            newState[key] =
              action.payload[key] != null ? action.payload[key] : null;
          }
        }
      } else {
        newState = { ...initialState };
      }
      // console.log("set init data", newState);

      return {
        ...newState,
        editedPreviously: false
      };
    }
    case RESTORE_VERSION_DATA: {
      let newState = { ...state };
      if (action.payload !== null) {
        for (const key in newState) {
          if (Array.isArray(newState[key])) {
            newState[key] = action.payload[key] ? action.payload[key] : [];
          } else {
            // eslint-disable-next-line
            newState[key] =
              action.payload[key] != null ? action.payload[key] : null;
          }
        }
      } else {
        newState = { ...initialState };
      }
      // console.log("restore Version Data", newState);

      return {
        ...newState,
        editedPreviously: true
      };
    }
    case DISCARD_ALL_DATA: {
      // console.log("discard data", initialState);
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export const setProperty = createAction(SET_PROPERTY);
export const setError = createAction(SET_ERROR);
export const setIngredientGroup = createAction(SET_INGREDIENT_GROUP);
export const deleteIngredientGroup = createAction(DELETE_INGREDIENT_GROUP);
export const deleteIngredient = createAction(DELETE_INGREDIENT);
export const deleteInstruction = createAction(DELETE_INSTRUCTION);
export const deleteStep = createAction(DELETE_STEP);
export const deleteTool = createAction(DELETE_TOOL);
export const setTools = createAction(SET_TOOLS);
export const setInstructions = createAction(SET_INSTRUCTIONS);
export const setToDelete = createAction(SET_TO_DELETE);
export const saveAction = createAction(SAVE_ACTION);
export const setInitData = createAction(SET_INIT_DATA);
export const discardAllData = createAction(DISCARD_ALL_DATA);
export const restoreVersionData = createAction(RESTORE_VERSION_DATA);

export default reducer;

import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: { en_GB: "Active", de_DE: "" },
    name: "active",
    visibility: 1,
    display: 1,
    tab: "news",
    type: "boolean",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Title EN", de_DE: "Title DE" },
    name: "title",
    visibility: 1,
    display: 1,
    localized: true,
    required: true,
    tab: "news",
    type: "textField"
  },
  {
    label: { en_GB: "Subtitle EN", de_DE: "Subtitle DE" },
    name: "subtitle",
    visibility: 1,
    display: 0,
    localized: true,
    required: true,
    tab: "news",
    type: "textField"
  },
  {
    label: { en_GB: "Description EN", de_DE: "Description DE" },
    name: "description",
    visibility: 1,
    display: 1,
    localized: true,
    required: true,
    tab: "news",
    type: "textField"
  },
  {
    label: { en_GB: "Icon", de_DE: "" },
    name: "icon",
    visibility: 0,
    display: 0,
    required: true,
    tab: "news",
    type: "images",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Image", de_DE: "" },
    name: "image",
    visibility: 0,
    display: 0,
    tab: "news",
    type: "images",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Link EN", de_DE: "Link DE" },
    name: "link",
    visibility: 1,
    display: 0,
    localized: true,
    tab: "news",
    type: "textField",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  }
];

import myTheme from "common/theme";

export default theme => ({
    ...myTheme.spreadThis,
    ...{
        fab: {
            position: "fixed",
            right: "20px",
            bottom: "20px"
        },
        textField: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        userLevelSelect: {
            marginTop: theme.spacing(2)
        }
    }
});

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import IconEdit from "@material-ui/icons/Edit";
import {db} from "common/firebase";
import ReactTimeout from "react-timeout";
import Loader from "components/Loader";
import FoodEdit from "components/FoodEdit";
import FoodTableCell from "components/FoodTableCell";
import {connect} from "react-redux";
import * as utils from "common/utils";
import styles from "./styles";
import schema from "../../data/schema";

const MAX_TABLE_ROWS = 5000;
const TABLE_STATE_DEFAULT = 'default';
const TABLE_STATE_FILTERED = 'filtered';
const TABLE_STATE_SEARCHED = 'searched';

const relevantColumns = [
    'id_db',
    'product_name_EN',
    'product_name_DE',
    'brand_def',
    'level_vis',
    'shadowed',
];


class Foods extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            //TODO: remove unused props!!!
            loading: true,
            columns: [],
            /*food*/indexes: [],
            foodData: [],
            numberOfRows: 5,
            lastVisible: [],
            searchText: '',
            //TODO: get count from metadatas collection
            orderBy: 'id_db',
            orderDirection: 'asc',
            currentPage: 0,
            fetchingFoodsData: false,

            oneFood: null,
            foodsEditDialogOpen: false,

            tableState: TABLE_STATE_DEFAULT,
            filterList: [],

            tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
                searchText: '',
                filterList: [],
                columns: []
            },
        };
    }

    get foodCount() {
        return this.state.searchText.length < 3 ? this.state.count : Object.values(this.state.indexes).length;
    }

    get lastVisible() {
        return this.state.lastVisible[this.state.currentPage - 1];
    }

    static cleanSearchString(string) {
        return string.replace(/\s/g, '').toLowerCase();
    }

    handleChange = (action, tableState) => {

        if (action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
            this.setState({
                tableStatePersist: {
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
                },
            });
        }
    };

    getColumns = () => {

        //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
        let columns = [
            {
                label: "Actions",
                name: "id_db",
                options: {
                    filter: false,
                    sort: false,
                    download: false,
                    print: false,
                    customBodyRender: (value) => {
                        return (
                            <React.Fragment>
                                <div className={this.props.classes.actionsContainer}>
                                    <Tooltip title='Edit this food'>
                                        <Fab
                                            color="primary"
                                            aria-label="Edit"
                                            size="small"
                                            onClick={this.handleOpenEditFood(value ? value.toUpperCase() : '')}
                                        >
                                            <IconEdit/>
                                        </Fab>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        )
                    },
                },
            },
            {
                label: "Edited",
                name: "shadowed",
                options: {
                    filter: true,
                    filterOptions: ['No', 'Yes'],
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value === true ? 'Yes' : 'No'}
                            column={'shadowed'}
                        />
                    )
                },

            },
            {
                label: "ID",
                name: 'id_db',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'id_db'}
                        />
                    )
                },
            },
            {
                label: "Visibility",
                name: 'level_vis',
                options: {
                    filter: true,
                    filterOptions: ['1', '2', '3'],
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            forceString={true}
                            column={'level_vis'}
                        />
                    )
                },
            },
            {
                label: "Brand",
                name: 'brand_def',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'brand_def'}
                        />
                    )
                },
            },
            {
                label: "Category 1",
                name: 'cat_1',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'cat_1'}
                        />
                    )
                },
            },
            {
                label: "Category 2",
                name: 'cat_2',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'cat_2'}
                        />
                    )
                },
            },
            {
                label: "Category 3",
                name: 'cat_3',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'cat_3'}
                        />
                    )
                },
            },
            {
                label: "Product Name (DE)",
                name: 'product_name_DE',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'product_name_DE'}
                        />
                    )
                },
            },
            {
                label: "Product Name (EN)",
                name: 'product_name_EN',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            column={'product_name_EN'}
                        />
                    )
                },
            },
            {
                label: "Fat",
                name: 'fat_g',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'g'}
                            column={'fat_g'}
                        />
                    )
                },
            },
            {
                label: "Energy",
                name: 'energy_kcal',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'kcal'}
                            column={'energy_kcal'}
                        />
                    )
                },
            },
            {
                label: "Sugar",
                name: 'sugar_total_g',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'g'}
                            column={'sugar_total_g'}
                        />
                    )
                },
            },
            {
                label: "Fiber",
                name: 'fiber_g',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'g'}
                            column={'fiber_g'}
                        />
                    )
                },
            },
            {
                label: "Carbs",
                name: 'carbs_net_g',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'g'}
                            column={'carbs_net_g'}
                        />
                    )
                },
            },
            {
                label: "Protein",
                name: 'protein_g',
                options: {
                    filter: false,
                    sort: false,
                    display: false,
                    customBodyRender: (value) => (
                        <FoodTableCell
                            value={value}
                            unit={'g'}
                            column={'protein_g'}
                        />
                    )
                },
            },
        ];

        //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
        for (let i = 0; i < columns.length; i++) {
            //Assign the filter list to persist
            columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
            if (this.state.tableStatePersist.columns[i] !== undefined) {
                //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                if (this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
                    columns[i].options.display = this.state.tableStatePersist.columns[i].display;
                //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                if (this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
                    //The sortDirection prop only permits sortDirection for one column at a time
                    if (this.state.tableStatePersist.columns[i].sortDirection !== 'none')
                        columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
                }
            }
        }

        return columns;
    };

    getSearchText = () => {
        return this.state.tableStatePersist.searchText;
    };

    componentDidMount() {

        this.setState({count: this.props.counts.foods});

        this.reloadIndexes()
            .then(() => this.setState({loading: false}));
    }

    reloadIndexes = async () => {
        try {
            await this.setState({
                fetchingFoodsData: true,
                indexes: [],
            });

            let count = this.props.counts.foods;

            let query = db.collection('allFoodIndex');

            if (utils.notNullOrUndefined(this.state.filterList[3])) {
                query = query.where('level_vis', '==', this.state.filterList[3]);
                count = this.props.counts[`foods_visibility_${this.state.filterList[3]}`];
            }

            if (utils.notNullOrUndefined(this.state.filterList[1])) {
                query = query.where('shadowed', '==', this.state.filterList[1] === 'Yes');
                count = this.props.counts[`foods_shadowed_${this.state.filterList[1].toLowerCase()}`];
            }

            if (utils.notNullOrUndefined(this.state.filterList[1]) && utils.notNullOrUndefined(this.state.filterList[3])) {
                count = this.props.counts[`foods_shadowed_${this.state.filterList[1].toLowerCase()}_visibility_${this.state.filterList[3]}`];
            }

            query = query.orderBy('id_db');

            // pagination
            if (utils.notNullOrUndefined(this.lastVisible)) {
                query = query.startAfter(this.lastVisible);
            }

            let lastVisible = this.state.lastVisible;

            await query
                .limit(this.state.numberOfRows)
                .get()
                .then(async foodIndexDocs => {
                    lastVisible[this.state.currentPage] = foodIndexDocs.size > 0 ? foodIndexDocs.docs[foodIndexDocs.size - 1] : [];
                    await this.setState({indexes: foodIndexDocs.docs});
                    await this.reloadFoodData();
                    await this.setState({
                        fetchingFoodsData: false,
                        count: count,
                    });
                })
                .catch(e => {
                    debugger
                    console.error('E0033', e);
                });


        } catch (e) {
            debugger
            console.error('E0032', e);
        }
    };

    reloadIndexesSearch = async () => {
        try {
            await this.setState({
                // fetchingFoodsData: true,
                indexes: [],
            });

            const foodIndexIDs = [];
            const foodIndexDocs = [];

            await Promise.all(
                relevantColumns.map(async relevantColumn => {
                    await db.collection('allFoodIndex')
                        .orderBy(relevantColumn)
                        .startAt(Foods.cleanSearchString(this.state.searchText))
                        .endAt(Foods.cleanSearchString(this.state.searchText) + "\uf8ff")
                        .limit(MAX_TABLE_ROWS)
                        .get()
                        .then(async allFoodIndexDocs => {
                            // eslint-disable-next-line
                            allFoodIndexDocs.docs.map(allFoodIndexDoc => {
                                if (foodIndexIDs.indexOf(allFoodIndexDoc.id) === -1) {
                                    foodIndexIDs.push(allFoodIndexDoc.id);
                                    foodIndexDocs.push(allFoodIndexDoc);
                                }
                            })
                        })
                        .catch(e => {
                            debugger
                            console.error('E0035', e);
                        });
                })
            );


            await this.setState({indexes: foodIndexDocs});
            await this.reloadFoodData();
            await this.setState({
                // fetchingFoodsData: false,
                count: foodIndexIDs.length,
            });

        } catch (e) {
            debugger
            console.error('E0032', e);
        }
    };

    reloadFoodData = async () => {

        try {
            await this.setState({
                foodData: [],
            });

            let foodData = [];

            await Promise.all(
                this.state.indexes.map(async foodIndexDoc => {
                    foodData.push(await this.getOneFoodData(foodIndexDoc.id));
                    return false;
                })
            );

            await this.setState({
                foodData: foodData,
            });

        } catch (e) {
            debugger
            console.error('E0034', e);
        }
    };

    /**
     *
     * @param id string
     * @returns {Promise<null>}
     */
    async getOneFoodData(id) {

        let foodData = null;
        let shadowData = null;
        let combinedData = null;

        await db.collection('/foods')
            .doc(id.toUpperCase())
            .get()
            .then(result => {
                if (result.exists === true) {
                    foodData = result.data();
                }
            });

        await db.collection('/shadows')
            .doc(id.toUpperCase())
            .get()
            .then(result => {
                if (result.exists === true) {
                    shadowData = result.data();
                }
            });

        if (foodData !== null) {
            if (shadowData !== null) {
                combinedData = {...foodData, ...shadowData, ...{shadowed: true}}
            } else {
                combinedData = foodData;
            }
        } else if (shadowData !== null) {
            combinedData = shadowData;
        }

        combinedData.level_vis = `${combinedData.level_vis}`;
        return combinedData;
    }

    createEmptyFoodData() {
        const doc = {};
        schema.forEach(column => {
            doc[column.id] = null;
        });
        doc.id_db = `S${(new Date().getTime())}`;
        doc.id_ean = [];
        doc.baseUnit = 'g';
        return doc;
    };

    fetchDataForOneFood = async foodID => {

        return new Promise(async resolve => {
            const foodDoc = await db.collection('foods').doc(foodID).get();
            const shadowDoc = await db.collection('shadows').doc(foodID).get();
            let foodData = {};

            if (foodDoc.exists) {
                foodData = foodDoc.data()
            }

            if (shadowDoc.exists) {
                foodData = {
                    ...foodData,
                    ...shadowDoc.data(),
                }
            } else {
                foodData = {
                    ...this.createEmptyFoodData(),
                    ...foodData,
                };
            }

            resolve({
                foodDoc: foodDoc,
                shadowDoc: shadowDoc.exists ? shadowDoc : null,
                data: foodData,
            })
        })
    };

    resetTableState = async () => {
        await this.setState({
            currentPage: 0,
            lastVisible: [],
            searchText: '',
            count: this.props.counts.foods,
            tableState: TABLE_STATE_DEFAULT,
        });
    };

    /**
     *
     * @param changedColumn string
     * @param direction string
     * @returns {Promise<void>}
     */
    handleColumnSortChange = async (changedColumn, direction) => {
        await this.setState({
            currentPage: 0,
            lastVisible: [],
            orderBy: changedColumn,
            orderDirection: direction === 'ascending' ? 'asc' : 'desc',
        });
        await this.reloadIndexes();
    };

    /**
     *
     * @param numberOfRows int
     * @returns {Promise<void>}
     */
    handleChangeRowsPerPage = async (numberOfRows) => {
        await this.resetTableState();
        await this.setState({
            numberOfRows: numberOfRows,
        });
        await this.reloadIndexes();
    };

    /**
     *
     * @param currentPage int
     * @returns {Promise<void>}
     */
    handleChangePage = async (currentPage) => {
        this.state.lastVisible[currentPage] = this.state.indexes[this.state.numberOfRows - 1];
        await this.setState({
            currentPage: currentPage,
        });
        await this.reloadIndexes();
    };

    /**
     *
     * @returns {Promise<void>}
     */
    handleSearchClose = async () => {
        await this.resetTableState();
        await this.reloadIndexes();
    };

    handleSearchChangeRegular = async (searchText) => {
        if (searchText.length === 2) {
            await this.setState({
                tableState: TABLE_STATE_DEFAULT,
                count: this.props.counts.foods,
            });
            await this.reloadIndexes();
        }
    };

    /**
     *
     * @param searchText string
     * @returns {Promise<void>}
     */
    handleSearchChangeServerSide = async (searchText) => {

        if (searchText === '') {
            await this.setState({
                searchText: '',
                tableState: TABLE_STATE_DEFAULT,
            });
            await this.reloadIndexes();
            return;
        }

        if (searchText.length === 2 && this.state.searchText.length === 3) {
            await this.setState({
                searchText: '',
                tableState: TABLE_STATE_DEFAULT,
            });
            await this.reloadIndexes();
            return;
        }

        // do nothing
        if (searchText.length < 3) {
            await this.setState({
                searchText: '',
                tableState: TABLE_STATE_DEFAULT,
            });
            return;
        }

        await this.setState({
            currentPage: 0,
            lastVisible: [],
            searchText: searchText,
            tableState: TABLE_STATE_SEARCHED,
        });

        await this.reloadIndexesSearch();

    };

    /**
     *
     * @param changedColumn string
     * @param filterList Array
     * @returns {Promise<void>}
     */
    handleFilterChange = async (changedColumn, filterList) => {

        let filterListForState = {};
        for (let i = 0; i < filterList.length; i++) {
            if (filterList[i].length > 0) {
                filterListForState[i] = filterList[i][0];
            }
        }
        await this.setState({
            filterList: filterListForState,
            lastVisible: [],
            currentPage: 0,
            tableState: Object.values(filterListForState).length > 0 ? TABLE_STATE_FILTERED : TABLE_STATE_DEFAULT,
        });

        await this.reloadIndexes();
    };

    handleOpenEditFood = foodID => async () => {

        await this.fetchDataForOneFood(foodID)
            .then(async foodData => {
                await this.setState({
                    fetchingFoodsData: true,
                    foodsEditDialogOpen: true,
                    foodsEditDialogVariant: foodID === 'new' ? 'new' : 'edit',
                    oneFood: foodData,
                });
            })
            .catch(e => {
                console.error('E0040', e)
            });
    };

    handleCloseEditFoodAfterCancel = async () => {
        await this.resetTableState();
        await this.setState({
            foodsEditDialogOpen: false,
            fetchingFoodsData: false,
            oneFood: null,
            // oneFoodID: null,
        });
        await this.reloadIndexes();
    };

    handleCloseFoodFoodAfterChange = async () => {
        await this.resetTableState();
        await this.reloadIndexes();
        await this.setState({
            foodsEditDialogOpen: false,
            fetchingFoodsData: false,
            oneFood: null,
            // oneFoodID: null,
        });
    };

    handleOpenNewFood = async () => {
        await this.setState({
            fetchingFoodsData: true,
            foodsEditDialogOpen: true,
            oneFood: null,
            // oneFoodID: null,
        });
    };

    render() {

        const {classes} = this.props;
        const {loading, fetchingFoodsData, tableState, oneFood} = this.state;

        if (loading) {
            return <Loader/>
        }

        // if (fetchingFoodsData) {
        //     return <Loader/>
        // }

        const options = {
            responsive: "scroll",
            selectableRows: false,
            rowHover: false,
            elevation: 1,
            filter: tableState !== TABLE_STATE_SEARCHED,
            filterType: 'dropdown',
            print: false,
            download: false,
            search: true,
            searchText: this.getSearchText(),
            rowsPerPage: this.state.numberOfRows,
            rowsPerPageOptions: [3, 5, 10],
            textLabels: {
                body: {
                    noMatch:
                        'Sorry, there is no matching data to display',
                },
            },
            onSearchClose: this.handleSearchClose,
        };

        return (
          <React.Fragment>
            {!fetchingFoodsData && (
              <React.Fragment>
                {tableState === TABLE_STATE_SEARCHED ? (
                  <MUIDataTable
                    title={"Foods"}
                    data={this.state.foodData}
                    columns={this.getColumns()}
                    options={{
                      ...options,
                      serverSide: false,
                      onSearchChange: this.handleSearchChangeRegular,
                      count: this.foodCount
                    }}
                  />
                ) : (
                  <MUIDataTable
                    title={"Foods"}
                    data={this.state.foodData}
                    columns={this.getColumns()}
                    options={{
                      ...options,
                      serverSide: true,
                      page: this.state.currentPage,
                      count: this.foodCount,
                      onColumnSortChange: this.handleColumnSortChange,
                      onChangeRowsPerPage: this.handleChangeRowsPerPage,
                      onChangePage: this.handleChangePage,
                      onSearchChange: this.handleSearchChangeServerSide,
                      onFilterChange: this.handleFilterChange,
                      onTableChange: (action, tableState) =>
                        this.handleChange(action, tableState)
                    }}
                  />
                )}
                <Fab
                  className={classes.addButton}
                  color={"secondary"}
                  onClick={this.handleOpenEditFood("new")}
                >
                  <AddIcon />
                </Fab>
              </React.Fragment>
            )}

            {oneFood && (
              <FoodEdit
                foodDoc={oneFood.foodDoc}
                foodData={oneFood.foodDoc.data()}
                shadowDoc={oneFood.shadowDoc}
                shadowData={oneFood.shadowDoc ? oneFood.shadowDoc.data() : null}
                handleCancel={this.handleCloseEditFoodAfterCancel}
                handleChange={this.handleCloseFoodFoodAfterChange}
                foodsEditDialogOpen={this.state.foodsEditDialogOpen}
                foodsEditDialogVariant={this.state.foodsEditDialogVariant}
              />
            )}
          </React.Fragment>
        );
    }
}

Foods.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        counts: state.masterdata.counts.counts,
    }
};

export default connect(mapStateToProps)(ReactTimeout(withStyles(styles, {withTheme: true})(Foods)));

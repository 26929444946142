import React from "react";

import Activities from "components/activities/Activities";
import CarbsEdit from "components/carbs/CarbsEdit";
import Categories from "components/categories/Categories";
import Dashboard from "components/Dashboard";
import Employees from "components/Employees";
import Foods from "components/Foods";
import FoodsBls from "components/FoodsBls";
import FoodsExport from "components/FoodsExport";
import Localization from "components/Localization";
import News from "components/news/News";
import Nutritions from "components/nutritions/Nutritions";
import Recipes from "components/recipes/Recipes";
import Settings from "components/Settings";
import UserGeneratedFood from "components/users/UserFoods";
import FoodsRewe from "components/FoodsRewe";

import AccessibilityIcon from "@material-ui/icons/Accessibility";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ExtensionIcon from "@material-ui/icons/Extension";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FlagIcon from "@material-ui/icons/Flag";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PublishIcon from "@material-ui/icons/Publish";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PaletteIcon from "@material-ui/icons/Palette";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import ViewListIcon from "@material-ui/icons/ViewList";

import * as constants from "common/constants";

export const menus = [
  {
    visibleTo: [
      constants.USER_LEVEL_ADMIN,
      constants.USER_LEVEL_EMPLOYEE,
      constants.USER_LEVEL_EXTERNAL_EMPLOYEE
    ],
    entries: [
      {
        primary: "Dashboard",
        icon: <DashboardIcon />,
        component: <Dashboard />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      }
    ]
  },
  {
    title: "Organization",
    visibleTo: [constants.USER_LEVEL_ADMIN],
    // adminOnly: true,
    entries: [
      {
        primary: "Admin",
        icon: <AccessibilityIcon />,
        component: <Employees />,
        visibleTo: [constants.USER_LEVEL_ADMIN]
        // adminOnly: true,
      }
    ]
  },
  {
    title: "Interactions",
    visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE],
    entries: [
      {
        primary: "BLS Upload",
        icon: <PublishIcon />,
        component: <FoodsBls />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
          primary: "Rewe Upload",
          icon: <PublishIcon />,
          component: <FoodsRewe/>,
          visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
        primary: "Food Export",
        icon: <ImportExportIcon />,
        component: <FoodsExport />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
        primary: "User generated Food",
        icon: <ExtensionIcon />,
        component: <UserGeneratedFood />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      }
    ]
  },
  {
    title: "Data",
    visibleTo: [
      constants.USER_LEVEL_ADMIN,
      constants.USER_LEVEL_EMPLOYEE,
      constants.USER_LEVEL_EXTERNAL_EMPLOYEE
    ],
    entries: [
      {
        primary: "Categories",
        icon: <ListAltIcon />,
        component: <Categories />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      },
      {
        primary: "Food",
        icon: <RestaurantIcon />,
        component: <Foods />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      },
      {
        primary: "Nutrition programs",
        icon: <DateRangeIcon />,
        component: <Nutritions />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      },
      {
        primary: "News",
        icon: <QuestionAnswerIcon />,
        component: <News />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      },
      {
        primary: "Recipes",
        icon: <MenuBookIcon />,
        component: <Recipes />,
        visibleTo: [
          constants.USER_LEVEL_ADMIN,
          constants.USER_LEVEL_EMPLOYEE,
          constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        ]
      }
    ]
  },
  {
    title: "Customization",
    visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE],
    entries: [
      {
        primary: "Activities",
        icon: <BarChartIcon />,
        component: <Activities />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
        primary: "Carb Table",
        icon: <ViewListIcon />,
        component: <CarbsEdit />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
        primary: "Localization",
        icon: <FlagIcon />,
        component: <Localization />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      },
      {
        primary: "Style",
        icon: <PaletteIcon />,
        component: <Settings />,
        visibleTo: [constants.USER_LEVEL_ADMIN, constants.USER_LEVEL_EMPLOYEE]
      }
    ]
  }
];

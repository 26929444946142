import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as _ from "lodash";
import * as utils from "common/utils";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import TextField from "@material-ui/core/TextField";
import FoodOneServingSize from "components/FoodOneServingSize";
import Button from "@material-ui/core/Button";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      color: "#000",
      "&.Mui-error fieldset": {
        borderColor: "red"
      }
    }
  }
})(TextField);

class FoodServingSizes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      propertyValue: []
    };
  }

  componentDidMount() {
    try {
      let propertyValue = _.cloneDeep(
        utils.notNullOrUndefined(this.props.propertyValue)
          ? this.props.propertyValue
          : []
      );

      this.setState({
        propertyValue: propertyValue,
        loading: false
      });
    } catch (e) {
      console.error("E0043", e);
    }
  }

  updateProperty = async (i, key, val) => {
    const propertyValue = this.state.propertyValue;
    this.state.propertyValue[i][key] = val;
    this.props.updateProperty("servingSizes", propertyValue);
    // this.props.updateErrors('Serving Sizes', `Property [${key}] has invalid value!`);
  };

  updateErrors = async (i, key, msg) => {
    // const propertyValue = this.state.propertyValue;
    // this.state.propertyValue[i][key] = msg;
    // this.props.updateErrors("servingSizes", propertyValue);
  };

  handleDeleteOneServingSize = i => async () => {
    const propValue = this.state.propertyValue;
    await this.setState({
      propertyValue: propValue.filter((val, index) => index !== i)
    });
    this.props.updateProperty("servingSizes", this.state.propertyValue);
  };

  handleAddNewEmptyServingSize = async () => {
    const propValue = this.state.propertyValue;
    propValue.push({
      amount: 0,
      en0: "",
      enN: "",
      de0: "",
      deN: ""
    });
    await this.setState({ propertyValue: propValue });
    this.props.updateProperty("servingSizes", propValue);
  };

  render() {
    const { classes, accessLevel } = this.props;

    const { propertyValue, loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.container}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <CssTextField
              id={`servingSizes-helperText`}
              disabled={true}
              variant="outlined"
              fullWidth={true}
              label={"Serving Sizes"}
              value={propertyValue.map((el, i) => `${el.en0}(${el.amount})`)}
              error={this.props.shadow}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: "inherit" }}>
            {propertyValue.map((servingSize, i) => (
              <div key={utils.uuidv4()} className={classes.servingSizesGroup}>
                <FoodOneServingSize
                  accessLevel={accessLevel}
                  servingSizeIndex={i}
                  servingSizePropKey={"amount"}
                  servingSizePropLabel={"Amount"}
                  servingSizePropValue={servingSize.amount}
                  updateProperty={this.updateProperty}
                  updateErrors={this.updateErrors}
                />
                <FoodOneServingSize
                  accessLevel={accessLevel}
                  servingSizeIndex={i}
                  servingSizePropLabel={"English Singular"}
                  servingSizePropKey={"en0"}
                  servingSizePropValue={servingSize.en0}
                  updateProperty={this.updateProperty}
                  updateErrors={this.updateErrors}
                />
                <FoodOneServingSize
                  accessLevel={accessLevel}
                  servingSizeIndex={i}
                  servingSizePropLabel={"English Plural"}
                  servingSizePropKey={"enN"}
                  servingSizePropValue={servingSize.enN}
                  updateProperty={this.updateProperty}
                  updateErrors={this.updateErrors}
                />
                <FoodOneServingSize
                  accessLevel={accessLevel}
                  servingSizeIndex={i}
                  servingSizePropLabel={"German Singular"}
                  servingSizePropKey={"de0"}
                  servingSizePropValue={servingSize.de0}
                  updateProperty={this.updateProperty}
                  updateErrors={this.updateErrors}
                />
                <FoodOneServingSize
                  accessLevel={accessLevel}
                  servingSizeIndex={i}
                  servingSizePropLabel={"German Plural"}
                  servingSizePropKey={"deN"}
                  servingSizePropValue={servingSize.deN}
                  updateProperty={this.updateProperty}
                  updateErrors={this.updateErrors}
                />
                <Button
                  onClick={this.handleDeleteOneServingSize(i)}
                  variant={"contained"}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </div>
            ))}
            <Button
              onClick={this.handleAddNewEmptyServingSize}
              variant={"contained"}
              color="primary"
              autoFocus
            >
              Add New Serving Size
            </Button>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FoodServingSizes.propTypes = {
  classes: PropTypes.object,
  propertyValue: PropTypes.any,
  accessLevel: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(FoodServingSizes);

import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import LocalizedProperty from "components/LocalizedProperty";
import SearchRecipe from "components/SearchRecipe";

const styles = theme => ({
  panelDetails: {
    alignItems: "center"
  },
  badge: {
    padding: theme.spacing(0, 1)
  }
});

class RecipeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //TODO implement adding new ingredient
      //TODO when adding ingredient add it to instructions array too
      data:
        this.props.data !== null && this.props.data !== undefined
          ? // ? { ...this.props.data, expanded: false }
            this.props.data
          : {},
      changed: false,
      error: false,
      expanded: false,
      show: true
    };
  }

  componentDidMount() {
    // if (this.state.data.ref === "missing") {
    //   if (this.state.error !== true) {
    //     this.setState({ error: true });
    //   }
    // }
  }

  onError(error) {
    // eslint-disable-next-line
    const { name, tab } = this.props.template;
    // console.log("ingredient", error, name, tab);
    // this.props.onError && this.props.onError({ error, name, tab });
    this.props.onError &&
      this.props.onError({ error, name: this.props.i, tab });
  }

  handleExpandedChange() {
    // let data = { ...this.state.data };
    // data.expanded = !this.state.data.expanded;
    // this.setState(
    //   { data },
    //   () =>
    //     this.props.callback &&
    //     this.props.callback(this.props.i, this.state.data)
    // );
    this.setState({ expanded: !this.state.expanded });
  }

  handleBlur(obj) {
    let data = { ...this.state.data };
    data[obj.property] = obj.value;
    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback(this.props.i, this.state.data)
    );
  }

  handleRecipeSearch(recipeData) {
    const { data } = this.state;
    data.title = recipeData.title;
    data.ref = `recipes/${recipeData.docId}`;
    let error = this.state.error;
    this.setState({ data, error: false }, () => error && this.onError(false));
  }

  handleDelete() {
    // this.setState({ show: false });
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  render() {
    const { classes, template, lang } = this.props;
    const details = [];
    const inputs = [];

    for (const key in template.children) {
      const child = template.children[key];
      const forbiddenAccess =
        child.forbiddenAccess &&
        child.forbiddenAccess.includes(this.props.userLevel);
      if (child.type !== "searchRecipe") {
        inputs.push(
          <LocalizedProperty
            key={`recipe-${child.name}-${key}-${this.props.i}-${this.props.id}`}
            template={child}
            disabled={child.disabled || forbiddenAccess}
            lang={lang}
            errors={this.props.errors}
            value={this.state.data[child.name]}
            callback={this.handleBlur.bind(this)}
          />
        );
        let detail =
          typeof this.state.data[child.name] === "object" &&
          this.state.data[child.name]
            ? `EN: ${
                this.state.data.title.en_GB ? this.state.data.title.en_GB : "-"
              }, DE: ${
                this.state.data.title.de_DE ? this.state.data.title.de_DE : "-"
              }`
            : this.state.data[child.name] !== null
            ? this.state.data[child.name]
            : "";
        details.push(`${detail}`);
      }
    }

    const forbiddenAccess =
      template.forbiddenAccess &&
      template.forbiddenAccess.includes(this.props.userLevel);

    return this.state.show ? (
      <ExpansionPanel
        // expanded={this.state.data.expanded}
        expanded={this.state.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Badge
            color="secondary"
            variant="dot"
            className={classes.badge}
            invisible={!this.state.error}
          >
            <Typography className={classes.heading}>
              {!this.state.data.title ||
              (!this.state.data.title.en_GB && !this.state.data.title.de_DE)
                ? "New recipe"
                : !this.state.expanded
                ? details.map((el, i) => {
                    return `${el}${i < details.length - 1 ? ", " : ""}`;
                  })
                : `EN: ${
                    this.state.data.title.en_GB
                      ? this.state.data.title.en_GB
                      : ""
                  }, DE: ${
                    this.state.data.title.de_DE
                      ? this.state.data.title.de_DE
                      : ""
                  }`}
            </Typography>
          </Badge>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          {inputs.map(el => el)}
          <SearchRecipe
            template={template.children.ref}
            value={this.state.data.ref}
            error={this.state.error}
            lang={lang}
            i={this.props.i}
            callback={this.handleRecipeSearch.bind(this)}
            disabled={forbiddenAccess}
          />
          {forbiddenAccess ? null : <IconButton
            onClick={this.handleDelete.bind(this)}
            className={classes.delete}
          >
            <DeleteIcon />
          </IconButton>}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ) : null;
  }
}

export default withStyles(styles, { withTheme: true })(RecipeView);

export const getRecipeCount = state => state.masterdata.counts.recipes.count;
export const getCategoryCount = state =>
  state.masterdata.counts.categories.count;
export const getNutritionCount = state =>
  state.masterdata.counts.mealPlans.count;
export const getNewsCount = state => state.masterdata.counts.news.count;
export const getActivitiesCount = state =>
  state.masterdata.counts.activities.count;
export const getUserFoodsCount = state =>
  state.masterdata.counts.userFoods.count;

import React from "react";
import { connect } from "react-redux";

import { dataTemplate } from "data/activities";
import ActivityEdit from "components/activities/ActivityEdit";
import DataTablePage from "components/DataTablePage";

import * as masterdata from "store/selectors/masterdata";

const stateToProps = state => ({
  count: masterdata.getActivitiesCount(state)
});

const actionsToProps = dispatch => ({});

class Activities extends React.Component {
  render() {
    return (
      <DataTablePage
        collection="activities"
        count={this.props.count}
        dataTemplate={dataTemplate}
        edit={ActivityEdit}
        tableTitle="Activities"
        editTooltip="Edit this activity"
      />
    );
  }
}

export default connect(stateToProps, actionsToProps)(Activities);

import React from "react";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { dataTemplate } from "data/recipes";

const styles = theme => ({
  group: {
    // backgroundColor: "#efefef",
    margin: theme.spacing(1),
    padding: theme.spacing(0.5)
  },
  item: {
    margin: theme.spacing(1)
  }
});

class RecipePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };
  }

  componentDidMount() {
    let data = [];
    dataTemplate.forEach(template => {
      let tmp = {};
      tmp[template.name] = this.props.data[template.name];
      data.push(tmp);
    });
    this.setState({ data });
  }

  renderRecipe(el, i) {
    const { classes } = this.props;
    let result = [];

    for (const key in el) {
      let value = el[key];
      if (typeof value !== "object") {
        result.push(
          <Paper className={classes.group} key={`prop-${i}-${key}`}>
            <Typography className={classes.item}>{`${key}: ${
              value != null ? value : "-"
            }`}</Typography>
          </Paper>
        );
      } else if (Array.isArray(value)) {
        result.push(
          <Paper key={`prop-${i}-${key}`} className={classes.group}>
            <Typography>{`${key}${
              value.length === 0 ? ": -" : ""
            }`}</Typography>
            {value.length > 0
              ? value.map((e, j) => {
                  return (
                    <Paper
                      className={classes.group}
                      key={`prop-${i}-${j}-${key}`}
                    >
                      {typeof e !== "string" ? (
                        this.renderRecipe(e, j)
                      ) : (
                        <Typography className={classes.item}>{`${j}: ${
                          e != null ? e : "-"
                        }`}</Typography>
                      )}
                    </Paper>
                  );
                })
              : null}
          </Paper>
        );
      } else {
        result.push(
          <Paper className={classes.group} key={`prop-${i}-${key}`}>
            <Typography>{`${key}${value == null ? ": -" : ""}`}</Typography>
            {this.renderRecipe(value)}
          </Paper>
        );
      }
    }

    return result;
  }

  render() {

    return (
      <Grid>
        {this.state.data
          ? this.state.data.map((el, i) => {
              return this.renderRecipe(el, i);
            })
          : null}
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(RecipePreview);

import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: { en_GB: "Name EN", de_DE: "Name DE" },
    name: "name",
    visibility: 1,
    display: 1,
    localized: true,
    required: true,
    type: "textField"
  },
  {
    label: { en_GB: "Usage (Kcal/h)", de_DE: "" },
    name: "usage",
    visibility: 1,
    display: 0,
    required: true,
    type: "number"
  },
  {
    label: { en_GB: "Icon", de_DE: "" },
    name: "icon",
    visibility: 0,
    display: 0,
    required: true,
    tab: "news",
    type: "images",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  }
];

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {auth} from 'common/firebase';
import firebaseui from "firebaseui";
import * as routes from 'common/routes';
import {connect} from "react-redux";

const styles = theme => ({});

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.setState({loading: false});

        const uiConfig = ({
            signInSuccessUrl: routes.ADMIN,
            signInOptions: [
                'password'
            ],
            tosUrl: '/terms',
            signInFlow: 'popup',
            privacyPolicyUrl: '/privacy'
        });
        const ui = new firebaseui.auth.AuthUI(auth);
        ui.start("#firebaseui-auth-container", uiConfig)
    }

    render() {
        return (
            <React.Fragment>
                <div id="firebaseui-auth-container"></div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(Login));
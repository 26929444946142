export const dataTemplate = [
  {
    label: { en_GB: "ID", de_DE: "" },
    name: "id_db",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Visibility", de_DE: "" },
    name: "level_vis",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Brand", de_DE: "" },
    name: "brand_def",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Category 1", de_DE: "" },
    name: "cat_1",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Category 2", de_DE: "" },
    name: "cat_2",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Category 3", de_DE: "" },
    name: "cat_3",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Product Name (DE)", de_DE: "" },
    name: "product_name_DE",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Product Name (EN)", de_DE: "" },
    name: "product_name_EN",
    visibility: 1,
    display: 1
  },
  {
    label: { en_GB: "Fat", de_DE: "" },
    name: "fat_g",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Energy", de_DE: "" },
    name: "energy_kcal",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Sugar", de_DE: "" },
    name: "sugar_total_g",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Fiber", de_DE: "" },
    name: "fiber_g",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Carbs", de_DE: "" },
    name: "carbs_total_g",
    visibility: 1,
    display: 0
  },
  {
    label: { en_GB: "Protein", de_DE: "" },
    name: "protein_g",
    visibility: 1,
    display: 0
  }
];

import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import * as routes from "common/routes";

class Page404 extends Component {
    render() {
        return (<Redirect
                to={{
                    pathname: routes.INFO,
                    state: {
                        message: '404 Page not Found!!',
                    }
                }}
            />
        );
    }
}

export default Page404
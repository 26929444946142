import React from "react";

import { db, storage } from "common/firebase";
import { asyncForEach, handleSave, findFoodDiff } from "common/utils";

import MUIDataTable from "mui-datatables";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import IconEdit from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

import ConfirmationDialog from "components/ConfirmationDialog";

const styles = theme => ({
  floatingContainer: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  addButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginBottom: theme.spacing(7)
  },
  edit: {
    marginRight: theme.spacing(1)
  },
  accept: {
    backgroundColor: "#2dc455",
    color: "#fff",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#26a648"
    }
  },
  discard: {
    backgroundColor: "#ba2929",
    color: "#fff",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#992222"
    }
  }
});

class DataTablePage extends React.Component {
  constructor(props) {
    super(props);

    const {
      rowsPerPage = 5,
      rowsPerPageOptions = [3, 5, 10],
      orderBy = "search_string",
      orderDirection = "asc"
    } = this.props;

    this.state = {
      loading: true,
      columns: [],
      data: [],
      documents: [],
      rowsPerPage,
      rowsPerPageOptions,
      lastVisible: [],
      searchText: "",
      orderBy,
      orderDirection,
      currentPage: 0,
      fetchingData: false,
      editIndex: null,
      editDialogOpen: false,
      rowsToDelete: null,
      rowsToDeleteDialogOpen: false,
      dialogTitle: "Are you sure?",
      dialogOpen: false,
      dialogContent: null,
      action: null,
      index: null
    };

    this.lang = "en_GB";
  }

  componentDidMount() {
    this.reloadTable().then(() => this.setState({ loading: false }));
  }

  resetTableState = async () => {
    await this.setState({
      currentPage: 0,
      lastVisible: [],
      searchText: ""
    });
  };

  handleChangeRowsPerPage = async rowsPerPage => {
    await this.resetTableState();
    await this.setState({
      rowsPerPage
    });
    await this.reloadTable();
  };

  handleChangePage = async currentPage => {
    if (currentPage > 0 && !this.state.lastVisible[currentPage]) {
      this.state.lastVisible[currentPage] = this.state.documents[
        this.state.rowsPerPage - 1
      ];
    }

    await this.setState({
      currentPage: currentPage
    });
    await this.reloadTable();
  };

  async reloadTable() {
    this.setState({
      fetchingData: true,
      data: []
    });

    const collection = db.collection(this.props.collection);
    let query = collection.orderBy(
      this.state.orderBy,
      this.state.orderDirection
    );

    if (typeof this.state.lastVisible[this.state.currentPage] !== "undefined") {
      query = query.startAfter(this.state.lastVisible[this.state.currentPage]);
    }

    await query
      .limit(this.state.rowsPerPage)
      .get()
      .then(async docs => {
        let data = [];
        let documents = [];
        docs.forEach(el => {
          documents.push(el);
          data.push(el.data());
        });
        this.doColumns();
        data = this.makeTableData(data);
        await this.setState({
          data,
          documents,
          fetchingData: false
        });
      });
  }

  makeTableData(data) {
    if (data) {
      let tableData = JSON.parse(JSON.stringify(data));
      this.props.dataTemplate.forEach(el => {
        if (el.localized) {
          tableData.forEach(td => {
            td[`${el.name}-en_GB`] = td[el.name] ? td[el.name].en_GB : "-";
            td[`${el.name}-de_DE`] = td[el.name] ? td[el.name].de_DE : "-";
            delete td[el.name];
          });
        }
      });
      // console.log(tableData);
      return tableData;
    }
    return null;
  }

  doColumns() {
    const { classes } = this.props;

    const columns = [
      {
        label: "Actions",
        name: "actions",
        options: {
          filter: false,
          sort: false,
          download: false,
          print: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                {this.props.editTooltip ? (
                  <Tooltip title={this.props.editTooltip}>
                    <Fab
                      className={classes.edit}
                      color="primary"
                      aria-label="Edit"
                      size="small"
                      id={tableMeta.rowIndex}
                      onClick={this.handleEditDialog.bind(this)}
                    >
                      <IconEdit />
                    </Fab>
                  </Tooltip>
                ) : null}
                {this.props.acceptTooltip ? (
                  <Tooltip title={this.props.acceptTooltip}>
                    <Fab
                      className={classes.accept}
                      aria-label="Accept"
                      size="small"
                      id={tableMeta.rowIndex}
                      onClick={this.handleDialog.bind(
                        this,
                        { action: "accept" },
                        null
                      )}
                    >
                      <CheckIcon />
                    </Fab>
                  </Tooltip>
                ) : null}
                {this.props.discardTooltip ? (
                  <Tooltip title={this.props.discardTooltip}>
                    <Fab
                      className={classes.discard}
                      aria-label="Discard"
                      size="small"
                      id={tableMeta.rowIndex}
                      onClick={this.handleDialog.bind(
                        this,
                        { action: "discard" },
                        null
                      )}
                    >
                      <ClearIcon />
                    </Fab>
                  </Tooltip>
                ) : null}
              </React.Fragment>
            );
          }
        }
      }
    ];

    this.props.dataTemplate.forEach(column => {
      if (column.visibility !== 0) {
        const col = {
          options: {
            filter: Boolean(column.filter),
            sort: Boolean(column.sort),
            display: column.display === 1 ? "true" : "false"
          }
        };

        if (column.localized) {
          columns.push({
            ...col,
            label: column.label.en_GB,
            name: `${column.name}-en_GB`
          });
          columns.push({
            ...col,
            label: column.label.de_DE,
            name: `${column.name}-de_DE`
          });
        } else {
          columns.push({
            ...col,
            label: column.label.en_GB,
            name: column.name,
            options: {
              ...col.options,
              customBodyRender: value => (
                <React.Fragment>
                  {value === true ? "Yes" : value === false ? "No" : value}
                </React.Fragment>
              )
            }
          });
        }
      }
    });

    this.setState({
      columns: columns
    });
  }

  handleAddDialog = () => {
    this.setState({
      fetchingData: true,
      editDialogOpen: true
    });
  };

  handleEditDialog = e => {
    const i = e.currentTarget.id;
    this.setState({
      editIndex: i,
      fetchingData: true,
      editDialogOpen: true
    });
  };

  handleDialog = (obj, params, e) => {
    let { action, id } = obj;
    const i = id ? id : e.currentTarget.id;
    this.setState({
      index: i,
      params,
      action,
      dialogOpen: true,
      dialogContent: `Selected item will be ${action}ed. This action cannot be undone.`
    });
  };

  handleConfirmationAction() {
    switch (this.state.action) {
      case "accept":
        this.handleAccept();
        break;
      case "discard":
        this.handleDiscard();
        break;

      default:
        break;
    }
  }

  handleCancel = async () => {
    this.setState({
      editIndex: null,
      editDialogOpen: false,
      fetchingData: false
    });
  };

  handleChange = async () => {
    this.handleCancel();
    this.reloadTable();
  };

  async handleDiscard() {
    let userFoodsDoc = this.state.documents[
      this.state.index !== null ? this.state.index : this.state.editIndex
    ];
    await userFoodsDoc.ref.delete().then(async () => {
      this.setState(
        {
          index: null,
          action: null,
          dialogOpen: false
        },
        () => this.handleChange()
      );
    });
  }

  async handleAccept() {
    let shadowDoc, shadowData, changes, id_db, baseUnit;

    if (this.state.params !== null) {
      shadowDoc = this.state.params.shadowDoc;
      shadowData = this.state.params.shadowData;
      changes = this.state.params.changes;
      id_db = this.state.params.id_db;
      baseUnit = this.state.params.baseUnit;
    } else {
      let userFoods = this.state.documents[this.state.index].data();
      id_db = userFoods.id_db;
      baseUnit = userFoods.baseUnit;
      changes = {};

      let foodData = {};
      let shadows = {};

      await db
        .collection("foods")
        .doc(id_db)
        .get()
        .then(doc => {
          if (doc.exists) {
            foodData = doc.data() ? doc.data() : {};
          }
        });

      shadowDoc = null;
      await db
        .collection("shadows")
        .doc(id_db)
        .get()
        .then(doc => {
          if (doc.exists) {
            shadowDoc = doc;
            shadows = doc.data() ? doc.data() : {};
          }
        });
      shadowData = findFoodDiff(userFoods, foodData, shadows);
    }

    // console.log("accepted", {
    //   shadowDoc,
    //   shadowData,
    //   changes,
    //   id_db,
    //   baseUnit
    // });

    handleSave(
      {
        shadowDoc,
        shadowData,
        changes,
        id_db,
        baseUnit
      },
      this.handleDiscard.bind(this)
    );
  }

  handleOnRowsDelete = rowsDeleted => {
    this.setState({
      rowsToDeleteDialogOpen: true,
      rowsToDelete: rowsDeleted.data.map(el => el.index)
    });
  };

  resetRowsToDelete() {
    this.setState({ rowsToDelete: null, rowsToDeleteDialogOpen: false });
    this.reloadTable();
  }

  async deleteAllImages(data) {
    let images = [];
    this.props.dataTemplate.forEach(template => {
      let img = this.findImages(template, data);
      if (img) {
        images.push(img);
      }
    });

    await asyncForEach(images, async image => {
      let imgRef = storage.refFromURL(image);
      const { fullPath } = imgRef;
      await imgRef
        .delete()
        .then(function() {
          console.info("File deleted successfully", fullPath);
        })
        .catch(function(error) {
          console.info("Ops. some error occured: ", error);
        });
    });
  }

  findImages(template, data) {
    if (template.type === "images") {
      return data[template.name];
    }

    //TODO: needed extra logic for recipes/ingredients/instructions
    return null;
  }

  async confirmOnRowsDelete() {
    await asyncForEach(this.state.rowsToDelete, async i => {
      await this.deleteAllImages(this.state.data[i]);
      await this.state.documents[i].ref.delete();
    });

    delete this.state.lastVisible[this.state.currentPage];
    this.resetRowsToDelete();
  }

  declineOnRowsDelete() {
    this.resetRowsToDelete();
  }

  render() {
    const { classes } = this.props;
    const Edit = this.props.edit;
    return (
      <React.Fragment>
        <Grid className={classes.table}>
          <MUIDataTable
            title={this.props.tableTitle}
            data={this.state.data}
            columns={this.state.columns}
            options={{
              filterType: "checkbox",
              responsive: "stacked",
              selectableRows: true,
              rowHover: false,
              serverSide: true,
              elevation: 1,
              filter: false,
              print: false,
              download: false,
              search: true,
              page: this.state.currentPage,
              count: this.props.count,
              rowsPerPage: this.state.rowsPerPage,
              rowsPerPageOptions: this.state.rowsPerPageOptions,
              textLabels: {
                body: {
                  noMatch: "Sorry, there is no matching data to display"
                }
              },

              // onColumnSortChange: this.handleColumnSortChange,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
              onChangePage: this.handleChangePage,
              // onSearchClose: this.handleSearchClose,
              // onSearchChange: this.handleSearchChange,
              // onFilterChange: this.handleFilterChange,
              // onRowClick: this.handleRowClick
              onRowsDelete: this.handleOnRowsDelete
            }}
          />
        </Grid>
        {!this.props.addDisabled ? (
          <Grid className={classes.floatingContainer}>
            <Fab
              className={classes.addButton}
              color={"secondary"}
              onClick={this.handleAddDialog}
            >
              <AddIcon />
            </Fab>
          </Grid>
        ) : null}
        {this.state.editDialogOpen ? (
          <Edit
            open={this.state.editDialogOpen}
            doc={this.state.documents[this.state.editIndex]}
            handleCancel={this.handleCancel}
            handleChange={this.handleChange}
            handleAccept={this.handleDialog.bind(this, {
              action: "accept",
              id: this.state.editIndex
            })}
            handleDiscard={this.handleDialog.bind(this, {
              action: "discard",
              id: this.state.editIndex
            })}
            lang={this.lang}
          />
        ) : null}
        {this.state.rowsToDeleteDialogOpen ? (
          <Dialog open={this.state.rowsToDeleteDialogOpen}>
            <DialogTitle>{"Are you sure?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {
                  "Selected items will be deleted. This action cannot be undone."
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.confirmOnRowsDelete.bind(this)}
                color="primary"
              >
                OK
              </Button>
              <Button
                onClick={this.declineOnRowsDelete.bind(this)}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
        {this.state.dialogOpen ? (
          <ConfirmationDialog
            action={this.state.action}
            open={this.state.dialogOpen}
            title={this.state.dialogTitle}
            content={this.state.dialogContent}
            cancel={() => this.setState({ dialogOpen: false })}
            confirm={this.handleConfirmationAction.bind(this)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DataTablePage);

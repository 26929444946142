import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as _ from "lodash";
import * as utils from "common/utils";
import * as constants from "common/constants";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import TextField from "@material-ui/core/TextField";

const allowedForExternalBoiWhitelist = ["product_name_DE", "product_name_EN"];

const mandatoryProperties = ["product_name_DE", "product_name_EN"];

class FoodOneProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      propertyValue: "",
      errors: []
    };
  }

  get isAllowedForUserToEdit() {
    if (this.props.accessLevel === constants.USER_LEVEL_ADMIN) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EMPLOYEE) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EXTERNAL_EMPLOYEE) {
      return (
        allowedForExternalBoiWhitelist.indexOf(this.props.categoryData.id) !==
        -1
      );
    }

    return false;
  }

  get isTotalCategoryValue() {
    return this.props.categoryData.isTotalCategoryValue === 1;
  }

  get isCalculatedFromOtherProperties() {
    return this.props.categoryData.internal !== null;
  }

  componentDidMount() {
    const errors = [];

    if (
      mandatoryProperties.indexOf(this.props.categoryData.id) !== -1 &&
      utils.isNullOrUndefined(this.props.propertyValue)
    ) {
      errors.push(`Property [${this.props.propertyName}] is mandatory!`);
      this.props.updateErrors(this.props.propertyName, errors);
    }

    try {
      this.setState({
        propertyValue: _.cloneDeep(
          utils.notNullOrUndefined(this.props.propertyValue)
            ? this.props.propertyValue
            : ""
        ),
        errors: errors,
        loading: false
      });
    } catch (e) {
      console.error("E0044", e);
    }
  }

  handleChange = event => {
    const errors = [];

    if (
      mandatoryProperties.indexOf(this.props.categoryData.id) !== -1 &&
      event.target.value === ""
    ) {
      errors.push(`Property [${this.props.propertyName}] is mandatory!`);
    }

    this.setState({
      propertyValue: event.target.value,
      errors: errors
    });
    this.props.updateProperty(this.props.categoryData.id, event.target.value);
    this.props.updateErrors(this.props.propertyName, errors);
  };

  render() {
    const {
      classes,
      categoryData,
      propertyName,
      variant,
      level,
      foodData,
      accessLevel
    } = this.props;

    const { propertyValue, loading, errors } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.container}>
        {variant === constants.ONE_FOOD_PANEL_VARIANT_SINGLE && (
          <TextField
            disabled={
              (!this.isAllowedForUserToEdit && !this.isTotalCategoryValue) ||
              this.isCalculatedFromOtherProperties
            }
            id={`${propertyName}-helperText`}
            helperText={errors.join(", ")}
            error={errors.length > 0 || this.props.shadow}
            variant="outlined"
            fullWidth={true}
            label={propertyName}
            value={propertyValue ? propertyValue : propertyValue === 0 ? 0 : ""}
            onChange={this.handleChange}
          />
        )}
        {variant === constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE && (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <TextField
                id={`${propertyName}-helperText`}
                disabled={
                  !this.isAllowedForUserToEdit && !this.isTotalCategoryValue
                }
                variant="outlined"
                fullWidth={true}
                label={propertyName}
                value={propertyValue}
                onChange={this.handleChange}
                error={this.props.shadow}
              />
            </ExpansionPanelSummary>
            {categoryData.sub && (
              <ExpansionPanelDetails style={{ display: "inherit" }}>
                <React.Fragment>
                  {Object.entries(categoryData.sub).map(subLevelProperty => {
                    if (
                      subLevelProperty[1][`category${level + 1}`].en === null
                    ) {
                      // level2 without subcategories
                      return (
                        <FoodOneProperty
                          accessLevel={accessLevel}
                          key={utils.uuidv4()}
                          variant={constants.ONE_FOOD_PANEL_VARIANT_SINGLE}
                          propertyName={subLevelProperty[1].name.en}
                          categoryData={subLevelProperty[1]}
                          propertyValue={foodData[subLevelProperty[1].id]}
                          foodData={foodData}
                          onChange={this.handleChange}
                          classes={classes}
                          level={level + 1}
                          updateProperty={this.props.updateProperty}
                          updateErrors={this.props.updateErrors}
                        />
                      );
                    } else {
                      // level2 with subcategories
                      return (
                        <FoodOneProperty
                          accessLevel={accessLevel}
                          key={utils.uuidv4()}
                          variant={constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE}
                          propertyName={subLevelProperty[1].name.en}
                          categoryData={subLevelProperty[1]}
                          propertyValue={foodData[subLevelProperty[1].id]}
                          foodData={foodData}
                          classes={classes}
                          level={level + 1}
                          updateProperty={this.props.updateProperty}
                          updateErrors={this.props.updateErrors}
                        />
                      );
                    }
                  })}
                </React.Fragment>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        )}
        {variant === constants.ONE_FOOD_PANEL_VARIANT_SPECIAL && (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <TextField
                className={classes.propInputText}
                id={`${propertyName}-helperText`}
                disabled={
                  !this.isAllowedForUserToEdit && !this.isTotalCategoryValue
                }
                variant="outlined"
                fullWidth={true}
                label={propertyName}
                value={propertyValue}
              />
            </ExpansionPanelSummary>
            {categoryData.sub && (
              <ExpansionPanelDetails style={{ display: "inherit" }}>
                <React.Fragment>
                  {Object.entries(categoryData.sub).map(subLevelProperty => {
                    // level2 without subcategories
                    return (
                      <FoodOneProperty
                        accessLevel={accessLevel}
                        key={utils.uuidv4()}
                        variant={constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE}
                        propertyName={subLevelProperty[0]}
                        categoryData={subLevelProperty[1]}
                        propertyValue={foodData[subLevelProperty[1].id]}
                        foodData={foodData}
                        onChange={this.handleChange}
                        classes={classes}
                        level={level + 1}
                        updateProperty={this.props.updateProperty}
                        updateErrors={this.props.updateErrors}
                      />
                    );
                  })}
                </React.Fragment>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        )}
      </div>
    );
  }
}

FoodOneProperty.propTypes = {
  classes: PropTypes.object,
  categoryData: PropTypes.object.isRequired,
  foodData: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  propertyValue: PropTypes.any,
  propertyName: PropTypes.string.isRequired,
  accessLevel: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    constants.ONE_FOOD_PANEL_VARIANT_SINGLE,
    constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE,
    constants.ONE_FOOD_PANEL_VARIANT_SPECIAL
  ]),
  updateProperty: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(FoodOneProperty);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as masterdata from "store/selectors/masterdata";

import { dataTemplate } from "data/recipes";
import { db } from "common/firebase";
import MUIDataTable from "mui-datatables";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconEdit from "@material-ui/icons/Edit";
import Grid from "@material-ui/core/Grid";

import RecipeEdit from "./RecipeEdit";
import ImportDialog from "./ImportDialog";

const styles = theme => ({
  floatingContainer: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  addButton: {
    marginRight: theme.spacing(1)
  },
  table: {
    marginBottom: theme.spacing(7)
  }
});

const stateToProps = state => ({
  count: masterdata.getRecipeCount(state)
});

const actionsToProps = dispatch => ({});

class Recipes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      columns: [],
      data: [],
      documents: [],
      numberOfRows: 5,
      lastVisible: [],
      searchText: "",
      orderBy: "search_string",
      orderDirection: "asc",
      currentPage: 0,
      fetchingData: false,
      editIndex: null,
      editDialogOpen: false,
      selectedRows: null
    };

    this.lang = "en_GB";
  }

  componentDidMount() {
    this.reloadTable().then(() => {
      this.setState({ loading: false });
    });
  }

  resetTableState = async () => {
    await this.setState({
      currentPage: 0,
      lastVisible: [],
      searchText: ""
    });
  };

  handleChangeRowsPerPage = async numberOfRows => {
    await this.resetTableState();
    await this.setState({
      numberOfRows: numberOfRows
    });
    await this.reloadTable();
  };

  handleChangePage = async currentPage => {
    if (
      currentPage > 0 &&
      typeof this.state.lastVisible[currentPage] === "undefined"
    ) {
      this.state.lastVisible[currentPage] = this.state.documents[
        this.state.numberOfRows - 1
      ];
    }

    await this.setState({
      currentPage: currentPage
    });
    await this.reloadTable();
  };

  async reloadTable() {
    this.setState({
      fetchingData: true,
      data: []
    });

    const collection = db.collection("recipes");
    let query = collection.orderBy(
      this.state.orderBy,
      this.state.orderDirection
    );

    if (typeof this.state.lastVisible[this.state.currentPage] !== "undefined") {
      query = query.startAfter(this.state.lastVisible[this.state.currentPage]);
    }

    await query
      .limit(this.state.numberOfRows)
      .get()
      .then(async docs => {
        let data = [];
        let documents = [];
        docs.forEach(el => {
          documents.push(el);
          data.push(el.data());
        });
        this.doColumns();
        data = this.makeTableData(data);
        await this.setState({
          data,
          documents,
          fetchingData: false
        });
      });
  }

  makeTableData(data) {
    if (data) {
      let tableData = JSON.parse(JSON.stringify(data));
      dataTemplate.forEach(el => {
        if (el.localized) {
          tableData.forEach(td => {
            td[`${el.name}-en_GB`] =
              td[el.name] && td[el.name].en_GB ? td[el.name].en_GB : "-";
            td[`${el.name}-de_DE`] =
              td[el.name] && td[el.name].de_DE ? td[el.name].de_DE : "-";
            delete td[el.name];
          });
        }
      });
      return tableData;
    }
    return null;
  }

  doColumns() {
    const columns = [
      {
        label: "Actions",
        name: "actions",
        options: {
          filter: false,
          sort: false,
          download: false,
          print: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <React.Fragment>
                <div className={this.props.classes.actionsContainer}>
                  <Tooltip title="Edit this recipe">
                    <Fab
                      color="primary"
                      aria-label="Edit"
                      size="small"
                      id={tableMeta.rowIndex}
                      onClick={this.handleEditDialog.bind(this)}
                    >
                      <IconEdit />
                    </Fab>
                  </Tooltip>
                </div>
              </React.Fragment>
            );
          }
        }
      }
    ];

    dataTemplate.forEach(column => {
      if (column.visibility !== 0) {
        if (column.localized) {
          columns.push({
            label: column.label.en_GB,
            name: `${column.name}-en_GB`,
            options: {
              // filter: column.filter,
              // filterList: this.filterListData(
              //   column.selectData,
              //   column.localized
              // ),
              display: column.visibility === 1 ? "true" : "false",
              customBodyRender: value => {
                return <React.Fragment>{value}</React.Fragment>;
              }
            }
          });
          columns.push({
            label: column.label.de_DE,
            name: `${column.name}-de_DE`,
            options: {
              // filter: column.filter,
              // filterList: this.filterListData(
              //   column.selectData,
              //   column.localized
              // ),
              display: column.visibility === 1 ? "true" : "false",
              customBodyRender: value => {
                return <React.Fragment>{value}</React.Fragment>;
              }
            }
          });
        } else {
          columns.push({
            label: column.label.en_GB,
            name: column.name,
            options: {
              // filter: column.filter,
              // filterOptions: {
              //   names: column.selectData
              //     ? column.selectData.map(el => el.label[this.lang])
              //     : []
              // },
              display: column.visibility === 1 ? "true" : "false",
              customBodyRender: value => {
                return <React.Fragment>{value}</React.Fragment>;
              }
            }
          });
        }
      }
    });

    this.setState({
      columns: columns
    });
  }

  filterListData(data, localized) {
    if (data) {
      // let result = JSON.parse(JSON.stringify(data));
      return data.map(el => el.label[this.lang]);
    }
  }

  handleAddDialog = () => {
    this.setState({
      fetchingData: true,
      editDialogOpen: true
    });
  };

  handleEditDialog = e => {
    const i = e.currentTarget.id;
    this.setState({
      editIndex: i,
      fetchingData: true,
      editDialogOpen: true
    });
  };

  handleImport = () => {
    ImportDialog.open();
  };

  handleCancel = async () => {
    this.setState({
      editIndex: null,
      editDialogOpen: false,
      fetchingData: false
    });
  };

  handleChange = async () => {
    this.setState({
      editIndex: null,
      editDialogOpen: false,
      fetchingData: false
    });
    this.reloadTable();
  };

  handleFilterChange = async (changedColumn, filterList) => {
    // console.log(changedColumn, filterList);
    // let filterListForState = {};
    // for (let i = 0; i < filterList.length; i++) {
    //   if (filterList[i].length > 0) {
    //     filterListForState[i] = filterList[i][0];
    //   }
    // }
    // await this.setState({
    //   filterList: filterListForState,
    //   lastVisible: [],
    //   currentPage: 0
    //   // tableState: Object.values(filterListForState).length > 0 ? TABLE_STATE_FILTERED : TABLE_STATE_DEFAULT,
    // });
    // await this.reloadTable();
  };

  handleOnRowsSelect = (currentRow, selectedRows) => {
    this.setState({ selectedRows });
  };

  handleOnRowsDelete = currentRow => {
    // console.log("heeeeelooo", currentRow);
  };

  render() {
    const { classes } = this.props;
    // console.log(this.state.selectedRows);
    return (
      <React.Fragment>
        <Grid className={classes.table}>
          <MUIDataTable
            title={"Recipes"}
            data={this.state.data}
            columns={this.state.columns}
            options={{
              filterType: "multiselect",
              responsive: "stacked",
              selectableRows: true,
              rowHover: false,
              serverSide: true,
              elevation: 1,
              filter: false,
              print: false,
              download: false,
              search: true,
              page: this.state.currentPage,
              count: this.props.count,
              rowsPerPage: this.state.numberOfRows,
              rowsPerPageOptions: [3, 5, 10],
              textLabels: {
                body: {
                  noMatch: "Sorry, there is no matching data to display"
                }
              },

              // onColumnSortChange: this.handleColumnSortChange,
              onChangeRowsPerPage: this.handleChangeRowsPerPage,
              onChangePage: this.handleChangePage
              // onSearchClose: this.handleSearchClose,
              // onSearchChange: this.handleSearchChange,
              // onFilterChange: this.handleFilterChange
              // onRowClick: this.handleRowClick
            }}
          />
        </Grid>
        <Grid className={classes.floatingContainer}>
          <Fab
            className={classes.addButton}
            color={"secondary"}
            onClick={this.handleAddDialog}
          >
            <AddIcon />
          </Fab>
          <Fab
            // className={classes.importButton}
            color={"secondary"}
            onClick={this.handleImport}
          >
            <CloudUploadIcon />
          </Fab>
        </Grid>
        {this.state.editDialogOpen ? (
          <RecipeEdit
            open={this.state.editDialogOpen}
            doc={this.state.documents[this.state.editIndex]}
            handleCancel={this.handleCancel}
            handleChange={this.handleChange}
            lang={this.lang}
          />
        ) : null}
        <ImportDialog />
      </React.Fragment>
    );
  }
}

Recipes.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  connect(stateToProps, actionsToProps)(Recipes)
);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as constants from "common/constants";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class FoodOneBaseUnit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      propValue: null
    };
  }

  componentDidMount() {
    this.setState({
      propValue: this.props.propertyValue,
      loading: false
    });
  }

  get isAllowedForUserToEdit() {
    if (this.props.accessLevel === constants.USER_LEVEL_ADMIN) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EMPLOYEE) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EXTERNAL_EMPLOYEE) {
      return false;
    }

    return false;
  }

  handleChange = () => event => {
    this.setState({
      propValue: event.target.value
    });
    this.props.updateProperty("baseUnit", event.target.value);
  };

  render() {
    const { classes } = this.props;

    const { loading, propValue } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.container}>
        <FormControl
          variant="outlined"
          fullWidth={true}
          className={classes.formControl}
          error={this.props.shadow}
        >
          <InputLabel id="base-unit-select-outlined-label">
            Base Unit
          </InputLabel>
          <Select
            labelWidth={75}
            disabled={!this.isAllowedForUserToEdit}
            labelId="base-unit-select-outlined-label"
            id="base-unit-select-outlined"
            value={propValue}
            onChange={this.handleChange()}
          >
            <MenuItem value={"g"}>100g</MenuItem>
            <MenuItem value={"ml"}>100ml</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}

FoodOneBaseUnit.propTypes = {
  classes: PropTypes.object,
  propertyValue: PropTypes.any,
  accessLevel: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(FoodOneBaseUnit);

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import IconEdit from "@material-ui/icons/Edit";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import Typography from "@material-ui/core/Typography";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {db} from 'common/firebase';
import DialogContentText from "@material-ui/core/DialogContentText";
import * as _ from 'lodash';
import StaticsService from "common/StaticsServiceSettings";
import { StyledButton } from "common/theme"
import Fab from "@material-ui/core/Fab";

const MASTERDATA_DOCUMENT_NAME = 'settings';
const ALLOWED_SETTING_PREFIXES = [
    'colors',
];

class Settings extends React.Component {

    // masterdata service
    service;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            originalMasterData: {},
            masterData: {},
            version: null,
            editDialogOpen: false,
            publishDialogOpen: false,
            tmpTextEditKey: '',
            tmpTextEditValue: '',
            entityText: '',
            changes: {},
        };
    }

    get masterData() {
        const masterArray = Object.entries(this.state.masterData).map(masterDatum => {
            return {
                key: masterDatum[0],
                val: {
                    edited: this.state.originalMasterData[masterDatum[0]] !== masterDatum[1],
                    data: masterDatum[1],
                    key: masterDatum[0],
                },
            }
        });

        return masterArray.filter(val => {
            return ALLOWED_SETTING_PREFIXES.indexOf(val.key.split('-')[0]) !== -1;
        });
    }

    get publishDisabled() {
        return Object.entries(this.state.changes).length < 1;
    }

    get saveLocallyDisabled() {
        return this.state.tmpTextEditValue === this.state.masterData[this.state.tmpTextEditKey]
            && this.isHexValid;
    }

    get isHexValid() {
        return /^#([A-Fa-f0-9]{6})$/.test(this.state.tmpTextEditValue);
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {

        this.service = new StaticsService();
        this.service.fetchData()
            .then(async () => {
                const data = this.service.toJSON()[MASTERDATA_DOCUMENT_NAME];
                await this.setState({
                    version: this.service.version,
                    originalMasterData: data,
                    masterData: _.cloneDeep(data),
                    loading: false,
                })
            })
    }

    handleOpenEditDialog = (key, data) => async () => {
        this.setState({
            tmpTextEditKey: key,
            tmpTextEditValue: data,
            editDialogOpen: true,
        })
    };

    handleCloseEditDialog = async () => {
        await this.setState({
            editDialogOpen: false,
            tmpTextEditKey: '',
            tmpTextEditValue: '',
            entityText: '',
        })
    };

    handleTextFieldChange = async event => {
        await this.setState({tmpTextEditValue: event.target.value});
    };

    handleResetChanges = async () => {
        await this.fetchData();
        await this.setState({changes: []});
    };

    handleSaveOneChangeLocally = async () => {
        this.state.changes[this.state.tmpTextEditKey] = this.state.tmpTextEditValue;

        // save in local state to show in table until publish to DB
        this.handleCloseEditDialog();
        const masterData = this.state.masterData;
        masterData[this.state.tmpTextEditKey] = this.state.tmpTextEditValue;
        await this.setState({masterData: masterData});
    };

    handleRevertOneChangeLocally = key => async() => {
        const tmpValue = this.state.originalMasterData[key];
        this.state.masterData[key] = tmpValue;
        const tmpChanges = _.omit(this.state.changes, [key]);

        await this.setState({
            tmpTextEditValue: tmpValue,
            changes: tmpChanges,
        });
    };

    handleSaveAllChangesToDB = async () => {
        await this.setState({loading: true});
        const localizationDoc = await db.collection('masterdata').doc(MASTERDATA_DOCUMENT_NAME).get();
        const newVersion = localizationDoc.data()._version + 1;
        await localizationDoc.ref.set({
            _version: newVersion,
            ...this.state.changes,
        }, {merge: true})
            .then(async () => {
                await this.handleResetChanges();
                this.handleCloseConfirmPublishDialog();
                this.setState({loading: false});
            })
            .catch(e => {
                console.error('E0047', e);
            });
    };

    handleCloseConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: false});
    };

    handleOpenConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: true});
    };

    render() {

        const {classes} = this.props;
        const {
            loading, version, editDialogOpen, working, entityKey,
            tmpTextEditValue, publishDialogOpen,
        } = this.state;

        if (loading) {
            return <Loader/>
        }

        const options = {
            responsive: "scroll",
            selectableRows: false,
            rowHover: false,
            elevation: 1,
            filter: false,
            filterType: 'dropdown',
            print: false,
            download: false,
            search: true,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 15, 100],
            textLabels: {
                body: {
                    noMatch:
                        'Sorry, there is no matching data to display',
                },
            },
        };

        const columns = [
            {
                label: "Actions",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (val) => {
                        return (
                            <React.Fragment>
                                <div className={classes.actionsContainer}>
                                    <Tooltip
                                        className={classes.actionButton}
                                        title='Edit Setting'
                                    >
                                        <Fab
                                            color="primary"
                                            aria-label="Edit"
                                            size="small"
                                            onClick={this.handleOpenEditDialog(val.key, val.data)}
                                        >
                                            <IconEdit/>
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip
                                        className={classes.actionButton}
                                        title='Revert Changes'
                                    >
                                        <span>
                                            <Fab
                                                color="secondary"
                                                aria-label="Revert"
                                                size="small"
                                                disabled={!val.edited}
                                                onClick={this.handleRevertOneChangeLocally(val.key)}
                                            >
                                                <SettingsBackupRestoreIcon />
                                            </Fab>
                                        </span>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        )
                    },
                },
            },
            {
                label: "Key",
                name: "key",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {
                        return value.substr(value.indexOf('-') + 1);
                    }
                }
            },
            {
                label: "Value",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value) => {

                        return (
                        <div>
                            {value.edited ? (
                                    <div>
                                        <span
                                            className={classes.editedValue}
                                        >
                                            { value.data }
                                        </span>
                                        <span
                                            style={{background: value.data}}
                                            className={classes.colorPreview}
                                        >
                                            &nbsp;
                                        </span>
                                    </div>
                            ) : (
                                    <div>
                                        <span>
                                            { value.data }
                                        </span>
                                        <span
                                            style={{background: value.data}}
                                            className={classes.colorPreview}
                                        >
                                            &nbsp;
                                        </span>
                                    </div>
                            )}
                        </div>
                    )}
                }
            },
        ];

        return (
            <React.Fragment>
                {version && (
                    <Typography className={classes.versionLabel}>version {version}</Typography>
                )}
                <MUIDataTable
                    title={'Style'}
                    data={this.masterData}
                    columns={columns}
                    options={{...options,}}
                />
                <Grid className={classes.fabPublish}>
                    <StyledButton
                        onClick={this.handleResetChanges}
                        disabled={this.publishDisabled}
                    >
                        <Typography>Discard Changes</Typography>
                    </StyledButton>
                    <StyledButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={this.handleOpenConfirmPublishDialog}
                        disabled={this.publishDisabled}
                    >
                        <Typography>Publish</Typography>
                    </StyledButton>
                </Grid>
                <Dialog
                    open={editDialogOpen}
                    onClose={this.handleCloseEditDialog}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    {working ? (
                        <Loader/>
                    ) : (
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">
                                <strong>{entityKey}</strong>
                            </DialogTitle>
                            <DialogContent>
                                <div className={classes.hexWrapper}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        error={!this.isHexValid}
                                        helperText={!this.isHexValid ? 'You must enter a valid hex value (a # followed by 6 letters/numbers)! Example: #FF00AA' : ''}
                                        id="entityTextField"
                                        variant={"outlined"}
                                        multiline={true}
                                        fullWidth
                                        value={tmpTextEditValue}
                                        onChange={this.handleTextFieldChange}
                                    />
                                    <span
                                        className={classes.colorPreview}
                                        style={{background: tmpTextEditValue}}
                                    >&nbsp;</span>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <StyledButton
                                    onClick={this.handleCloseEditDialog}
                                >
                                    Cancel
                                </StyledButton>
                                <StyledButton
                                    onClick={this.handleSaveOneChangeLocally}
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={this.saveLocallyDisabled}
                                >
                                    Save
                                </StyledButton>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>
                <Dialog
                    open={publishDialogOpen}
                    onClose={this.handleSaveAllChangesToDB}
                    aria-labelledby="responsive-dialog-title"
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle id="responsive-dialog-title">{"Confirm Publish"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will publish changes to mobile App.<br/>
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <StyledButton
                            onClick={this.handleCloseConfirmPublishDialog}
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton
                            onClick={this.handleSaveAllChangesToDB}
                            color={'primary'}
                            variant={'contained'}
                            autoFocus
                        >
                            Confirm
                        </StyledButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Settings)
import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: "white"
  }
});

class Copyright extends Component {
  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.footer}>
        <div>
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://lowcarb-glutenfree.com/">
              Dr. Almond
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </div>
      </footer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Copyright);

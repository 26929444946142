// import adminTheme from "common/theme";

export default theme => ({
    // ...adminTheme,
    ...{
        addButton: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
        tableLoadingOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            background: 'rgba(0,0,0,0)',
            zIndex: 1000,
            display: 'block',
        },
        tableLoader: {
            position: 'absolute',
            left: '50%',
            top: '50%',
        },
        actionsContainer: {
            display: 'flex',
        },
        editFoodDialogActions: {
            display: 'block',
            textAlign: 'right',
        },
    }
});

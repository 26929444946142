import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  textField: {
    marginRight: theme.spacing(2)
  },
  inputText: {
    // color: "#000000"
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textAreaLabel: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  dialogTitleContainer: {
    justifyContent: "space-around",
    userSelect: "none"
  },
  odd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  even: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f8f0"
  },
  boolean: {
    alignItems: "start"
  }
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-error fieldset": {
        borderColor: "red"
      }
    }
  }
})(TextField);

class BasicProperty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value:
        this.props.value !== undefined && this.props.value !== null
          ? this.props.value
          : this.props.multiple
          ? []
          : "",
      changed: false,
      error: this.props.error
    };
  }

  componentDidMount() {
    if (
      this.props.template.required &&
      (this.props.value === "" || this.props.value === null) &&
      !this.props.error &&
      !this.state.error
    ) {
      this.setState({ error: true }, () => this.onError(true));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      if (this.props.value !== undefined && this.props.value !== null) {
        let error = this.props.template.required && this.props.value === "";
        this.setState(
          {
            value: this.props.value,
            error
          },
          () => this.props.template.required && this.onError(error)
        );
      } else
        this.setState(
          {
            value: this.props.multiple ? [] : "",
            error: this.props.template.required
          },
          () => this.onError(this.props.template.required)
        );
    } else {
      if (this.props.template.required) {
        if (
          (this.props.value === "" || this.props.value === null) &&
          !this.props.error &&
          !this.state.error
        ) {
          this.setState({ error: true }, () => this.onError(true));
        }
      } else {
        if (this.props.error && !this.state.error) {
          this.setState({ error: true }, () => this.onError(true));
        }
      }
    }
  }

  onError(error) {
    const { name, tab } = this.props.template;
    this.props.onError && this.props.onError({ error, name, tab });
  }

  onKeyPress(e) {
    this.props.onKeyPress && this.props.onKeyPress(e);
  }

  handleChange(e) {
    let { value } = e.target;

    if (this.props.template.type === "number") {
      value = parseFloat(value);
      if (isNaN(value)) {
        value = "";
      }
    }

    if (this.state.value !== value) {
      this.setState({ value: value, changed: true });
    }
  }

  handleBooleanChange(e) {
    this.setState(
      { value: e.target.checked, changed: true },
      () =>
        this.props.callback &&
        this.props.callback({
          property: this.props.template.name,
          value: this.state.value
        })
    );
  }

  handleSingleSelectChange(e) {
    this.setState(
      { value: e.target.value },
      () =>
        this.props.callback &&
        this.props.callback({
          property: this.props.template.name,
          value: this.state.value
        })
    );
  }

  handleBlur(e) {
    if (this.state.changed) {
      let error = false;
      if (this.props.template.required) {
        if (this.state.value === "") {
          error = true;
        }
      }

      this.setState({ changed: false, error }, () => {
        this.props.callback &&
          this.props.callback({
            property: this.props.template.name,
            value:
              this.props.template.type === "number"
                ? this.state.value !== null && this.state.value !== ""
                  ? parseFloat(this.state.value)
                  : null
                : this.state.value,
            lang: this.props.template.localized ? this.props.lang : false
          });
      });
    }
  }

  render() {
    const { classes, template, lang, array, multiple, id } = this.props;
    switch (template.type) {
      case "textArea":
        return (
          <FormControl className={classes.select}>
            <Typography className={classes.textAreaLabel}>
              {this.state.value === "" ? null : template.label[lang]}
            </Typography>
            <TextareaAutosize
              className={classes.textField}
              id={id ? id : template.name}
              rows={3}
              placeholder={template.label[lang]}
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              onBlur={this.handleBlur.bind(this)}
            />
          </FormControl>
        );
      case "textField":
        return (
          <CssTextField
            className={classes.textField}
            id={id ? id : template.name}
            label={`${template.label[lang]}`}
            value={this.state.value}
            required={template.required}
            error={this.state.error}
            margin="normal"
            variant="outlined"
            disabled={this.props.disabled}
            inputProps={{ className: classes.inputText }}
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
          />
        );
      case "number":
        return (
          <CssTextField
            className={classes.textField}
            id={id ? id : template.name}
            label={`${template.label[lang]}`}
            value={this.state.value}
            required={template.required}
            error={this.state.error}
            margin="normal"
            variant="outlined"
            disabled={this.props.disabled}
            // type="number"
            onChange={this.handleChange.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        );
      case "select": {
        return (
          <FormControl className={classes.select}>
            <InputLabel id={`${template.name}-label`}>
              {`${template.label[lang]}`}
            </InputLabel>
            <Select
              multiple={multiple}
              input={<Input />}
              id={`${template.name}-select`}
              labelId={`${template.name}-label`}
              disabled={this.props.disabled}
              renderValue={value => {
                return multiple
                  ? array
                      .filter(el => value.indexOf(el.value) > -1)
                      .map(el =>
                        typeof el.label == "object"
                          ? `[EN: ${
                              el.label.en_GB ? el.label.en_GB : "-"
                            }, DE: ${el.label.de_DE ? el.label.de_DE : "-"}]`
                          : el.label
                      )
                      .join(", ")
                  : array.filter(el => el.value === value).map(el => el.label);
              }}
              value={this.state.value}
              onChange={
                multiple
                  ? this.handleChange.bind(this)
                  : this.handleSingleSelectChange.bind(this)
              }
              onClose={this.handleBlur.bind(this)}
            >
              {/* {template.localized ? (
                <Grid container className={classes.dialogTitleContainer}>
                  <Typography>EN</Typography>
                  <Typography>DE</Typography>
                </Grid>
              ) : null} */}
              {array.map((item, i) => {
                return (
                  <MenuItem
                    key={`select-${i}-${item.value}`}
                    value={item.value}
                    className={i % 2 ? classes.odd : classes.even}
                  >
                    <Checkbox
                      checked={
                        multiple
                          ? this.state.value.indexOf(item.value) > -1
                          : this.state.value === item.value
                      }
                    />
                    {typeof item.label === "object" ? (
                      <React.Fragment>
                        <ListItemText
                          primary={`${
                            item.label.en_GB ? item.label.en_GB : "-"
                          }`}
                        />
                        <ListItemText
                          primary={`${
                            item.label.de_DE ? item.label.de_DE : "-"
                          }`}
                        />
                      </React.Fragment>
                    ) : (
                      <ListItemText primary={`${item.label}`} />
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }
      case "boolean": {
        return (
          <FormControl className={classes.boolean}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.value}
                  onChange={this.handleBooleanChange.bind(this)}
                  value={this.state.value}
                  color="primary"
                  disabled={this.props.disabled}
                  id={id ? id : template.name}
                />
              }
              label={`${template.label[lang]}`}
              labelPlacement="start"
            />
          </FormControl>
        );
      }
      default: {
        return (
          <Typography>
            Not existing field type {template.type} for {template.name}
          </Typography>
        );
      }
    }
  }
}

export default withStyles(styles, { withTheme: true })(BasicProperty);

import React from "react";

import { db } from "common/firebase";
import { findFoodDiff } from "common/utils";
import FoodEdit from "components/FoodEdit";

class WrappedFoodEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      foodDoc: null,
      foodData: null,
      shadowDoc: null,
      shadowData: null
    };
  }

  componentDidMount() {
    // console.log(this.props);
    this.setState({ loading: true }, this.findFood);
  }

  async findFood() {
    const userFoods = this.props.doc.data();

    let foodDoc = null;
    let foodData = null;

    let shadowDoc = null;
    let shadowData = {};

    await db
      .collection("foods")
      .doc(userFoods.id_db)
      .get()
      .then(doc => {
        if (doc.exists) {
          foodDoc = doc;
          foodData = doc.data() ? doc.data() : {};
        }
      });

    await db
      .collection("shadows")
      .doc(userFoods.id_db)
      .get()
      .then(doc => {
        if (doc.exists) {
          shadowDoc = doc;
          shadowData = doc.data() ? doc.data() : {};
        }
      });

    foodData = { ...foodData, ...shadowData };
    // console.log(foodData);

    this.setState({
      shadowDoc,
      shadowData: findFoodDiff(userFoods, foodData, shadowData),
      foodDoc,
      foodData,
      loading: false
    });
  }

  render() {
    const { shadowDoc, shadowData, foodDoc, foodData } = this.state;
    return !this.state.loading ? (
      <FoodEdit
        userFoods={true}
        foodDoc={foodDoc}
        foodData={foodData}
        shadowDoc={shadowDoc}
        shadowData={shadowData}
        handleCancel={this.props.handleCancel}
        handleChange={this.props.handleChange}
        handleDiscard={this.props.handleDiscard}
        handleAccept={this.props.handleAccept}
        foodsEditDialogOpen={this.props.open}
        foodsEditDialogVariant={"edit"}
      />
    ) : null;
  }
}

export default WrappedFoodEdit;

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import Typography from "@material-ui/core/Typography";
import StaticsServiceLocalization from 'common/StaticsServiceLocalization';
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {db} from 'common/firebase';
import DialogContentText from "@material-ui/core/DialogContentText";
import * as _ from 'lodash';
import Fab from "@material-ui/core/Fab";
import IconEdit from "@material-ui/icons/Edit";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { StyledButton } from "common/theme";


class Localization extends React.Component {

    localizationService;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            working: false,
            originalStaticsData: {},
            staticsData: {},
            version: null,
            editDialogOpen: false,
            publishDialogOpen: false,
            entityVal: {},
            entityText: {
                en_GB: '',
                de_DE: '',
            },
            changes: {},
        };
    }

    get statics() {
        return Object.entries(this.state.staticsData).map(staticsDatum => {
            return {
                val: {
                    key: staticsDatum[0],
                    en_GB: {
                        edited: this.state.originalStaticsData[staticsDatum[0]]['en_GB'] !== staticsDatum[1]['en_GB'],
                        value: staticsDatum[1]['en_GB']
                    },
                    de_DE: {
                        edited: this.state.originalStaticsData[staticsDatum[0]]['de_DE'] !== staticsDatum[1]['de_DE'],
                        value: staticsDatum[1]['de_DE']
                    },
                },
            }
        });
    }

    get publishDisabled() {
        return Object.entries(this.state.changes).length < 1;
    }

    get saveLocallyDisabled() {
        const key = this.state.entityVal.key;
        return this.state.changes[key] === this.state.originalStaticsData[key];
    }

    componentDidMount() {

        this.fetchData()
            .then(async () => {
                await this.setState({
                    loading: false,
                })
            })
    }

    async fetchData() {

        this.localizationService = new StaticsServiceLocalization();
        this.localizationService.fetchData()
            .then(async () => {
                const data = this.localizationService.toJSON()['localization'];
                await this.setState({
                    version: this.localizationService.version,
                    originalStaticsData: data,
                    staticsData: _.cloneDeep(data),
                })
            })
    }

    handleOpenEditDialog = (val) => async () => {
        this.setState({
            entityText: {
                en_GB: val.en_GB.value,
                de_DE: val.de_DE.value,
            },
            entityVal: val,
            editDialogOpen: true,
        })
    };

    handleCloseEditDialog = async () => {
        await this.setState({
            editDialogOpen: false,
            entityText: {
                en_GB: '',
                de_DE: '',
            },
            entityVal: {},
        })
    };

    handleTextFieldChange = lang => async event => {
        const entityText = this.state.entityText;
        entityText[lang] = event.target.value;
        await this.setState({entityText: entityText});
    };

    handleSaveOneChangeLocally = async () => {
        const {changes, entityVal, entityText, staticsData} = this.state;
        const valForSaving = {
            en_GB: entityText.en_GB,
            de_DE: entityText.de_DE,
        };
        changes[entityVal.key] = valForSaving;

        // save in local state to show in table until publish to DB
        staticsData[entityVal.key] = valForSaving;
        await this.setState({
            staticsData: staticsData,
            changes: changes,
        });
        this.handleCloseEditDialog();
    };

    handleRevertOneChangeLocally = key => async() => {
        const {staticsData, changes} = this.state;
        staticsData[key] = this.state.originalStaticsData[key];
        const newChanges = _.omit(changes, [key]);

        await this.setState({
            staticsData: staticsData,
            changes: newChanges,
        });
    };

    handleResetChanges = async () => {
        await this.fetchData();
        await this.setState({changes: []});
    };

    handleSaveAllChangesToDB = async () => {
        await this.setState({loading: true});
        const localizationDoc = await db.collection('masterdata').doc('localization').get();
        const newVersion = localizationDoc.data()._version + 1;
        await localizationDoc.ref.set({
            _version: newVersion,
            ...this.state.changes,
        }, {merge: true})
            .then(async () => {
                await this.handleResetChanges();
                this.handleCloseConfirmPublishDialog();
                this.setState({loading: false});
            })
            .catch(e => {
                console.error('E0046', e);
            });
    };

    handleCloseConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: false});
    };

    handleOpenConfirmPublishDialog = async () => {
        await this.setState({publishDialogOpen: true});
    };

    render() {

        const {classes} = this.props;
        const {
            loading,
            version,
            editDialogOpen,
            working,
            entityText,
            entityVal,
            publishDialogOpen,
        } = this.state;

        if (loading) {
            return <Loader/>
        }

        const options = {
            responsive: "scroll",
            selectableRows: false,
            rowHover: false,
            elevation: 1,
            filter: false,
            filterType: 'dropdown',
            print: false,
            download: false,
            search: true,
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 15, 100],
            textLabels: {
                body: {
                    noMatch:
                        'Sorry, there is no matching data to display',
                },
            },
        };

        const columns = [
            {
                label: "Actions",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (val) => {
                        return (
                            <React.Fragment>
                                <div className={classes.actionsContainer}>
                                    <Tooltip
                                        className={classes.actionButton}
                                        title='Edit Setting'
                                    >
                                        <Fab
                                            color="primary"
                                            aria-label="Edit"
                                            size="small"
                                            onClick={this.handleOpenEditDialog(val)}
                                        >
                                            <IconEdit/>
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip
                                        className={classes.actionButton}
                                        title='Revert Changes'
                                    >
                                        <span>
                                            <Fab
                                                color="secondary"
                                                aria-label="Revert"
                                                size="small"
                                                disabled={!val.en_GB.edited && !val.de_DE.edited}
                                                onClick={this.handleRevertOneChangeLocally(val.key)}
                                            >
                                                <SettingsBackupRestoreIcon />
                                            </Fab>
                                        </span>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        )
                    },
                },
            },
            {
                label: "Key",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: val => val.key,
                }
            },
            {
                label: "English",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (val) => {
                        return (
                            <span
                                className={val.en_GB.edited ? classes.editedValue : ''}
                            >
                                {val.en_GB.value}
                            </span>
                        )
                    }
                }
            },
            {
                label: "German",
                name: "val",
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (val) => {
                        return (
                            <span
                                className={val.de_DE.edited ? classes.editedValue : ''}
                            >
                                {val.de_DE.value}
                            </span>
                        )
                    }
                }
            },
        ];

        return (
            <React.Fragment>
                {version && (
                    <Typography className={classes.versionLabel}>version {version}</Typography>
                )}
                <MUIDataTable
                    title={"Localization"}
                    data={this.statics}
                    columns={columns}
                    options={{...options,}}
                />
                <Grid className={classes.fabPublish}>
                    <StyledButton
                        onClick={this.handleResetChanges}
                        disabled={this.publishDisabled}
                    >
                        <Typography>Discard Changes</Typography>
                    </StyledButton>
                    <StyledButton
                        variant={'contained'}
                        color={'primary'}
                        onClick={this.handleOpenConfirmPublishDialog}
                        disabled={this.publishDisabled}
                    >
                        <Typography>Publish</Typography>
                    </StyledButton>
                </Grid>
                <Dialog
                    open={editDialogOpen}
                    onClose={this.handleCloseEditDialog}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    {working ? (
                        <Loader/>
                    ) : (
                        <React.Fragment>
                            <DialogTitle id="form-dialog-title">
                                Entity: <strong>{entityVal.key}</strong>
                            </DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="entityTextField_en_GB"
                                    label={'English'}
                                    variant={"outlined"}
                                    fullWidth
                                    value={entityText.en_GB}
                                    onChange={this.handleTextFieldChange('en_GB')}
                                />
                                <TextField
                                    margin="dense"
                                    id="entityTextField_de_DE"
                                    variant={"outlined"}
                                    label={'German'}
                                    fullWidth
                                    value={entityText.de_DE}
                                    onChange={this.handleTextFieldChange('de_DE')}
                                />
                            </DialogContent>
                            <DialogActions>
                                <StyledButton
                                    onClick={this.handleCloseEditDialog}
                                >
                                    Cancel
                                </StyledButton>
                                <StyledButton
                                    onClick={this.handleSaveOneChangeLocally}
                                    variant={'contained'}
                                    color={'primary'}
                                    disabled={this.saveLocallyDisabled}
                                >
                                    Save
                                </StyledButton>
                            </DialogActions>
                        </React.Fragment>
                    )}
                </Dialog>
                <Dialog
                    open={publishDialogOpen}
                    onClose={this.handleSaveAllChangesToDB}
                    aria-labelledby="responsive-dialog-title"
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle id="responsive-dialog-title">{"Confirm Publish"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will publish changes to mobile App.<br/>
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <StyledButton
                            onClick={this.handleCloseConfirmPublishDialog}
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton
                            onClick={this.handleSaveAllChangesToDB}
                            color={'primary'}
                            variant={'contained'}
                            autoFocus
                        >
                            Confirm
                        </StyledButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

Localization.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Localization)
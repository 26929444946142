import React from "react";

import { StyledButton } from "common/theme";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

class ConfirmationDialog extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        disableBackdropClick={this.props.disableBackdropClick}
        disableEscapeKeyDown={this.props.disableEscapeKeyDown}
      >
        {this.props.title ? (
          <DialogTitle>{this.props.title}</DialogTitle>
        ) : null}
        {this.props.content ? (
          <DialogContent>
            <DialogContentText>{this.props.content}</DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          {this.props.action !== "save" ? (
            <StyledButton onClick={this.props.cancel}>Cancel</StyledButton>
          ) : null}
          <StyledButton
            onClick={this.props.confirm}
            color="primary"
            variant="contained"
          >
            OK
          </StyledButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationDialog;

export const getName = state => state.category.name;
export const getRecipes = state => state.category.recipes;
export const getEditedPreviously = state => state.category.editedPreviously;
export const getErrors = state => state.category.errors;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.category));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;

  data.recipes = data.recipes.filter(el => el);

  if (data.name)
    data.search_string = `${data.name.de_DE}${data.name.en_GB}`
      .replace(/\s/g, "")
      .toLowerCase();

  return data;
};

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {isNullOrUndefined} from "../common/utils";

// const nf = Intl.NumberFormat('de-DE');

const styles = theme => ({
    tableValueDimension: {
        marginLeft: '1px',
        fontSize: '.7em',
    },
    unit: {
        fontSize: '.8em',
        color: '#666666',
    }
});

class FoodTableCell extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        // console.info(this.props.column);
    }

    render() {

        const {classes, value, unit, forceString} = this.props;
        // const evaluatedValue = eval(value);
        let parsedValue = value;
        if (isNullOrUndefined(value)) {
            parsedValue = '-';
        } else {
            if (!isNaN(value) && !forceString) {
                // eslint-disable-next-line
                parsedValue = eval(value).toFixed(2);
            }
        }

        let parsedUnit = unit;
        if (isNullOrUndefined(unit)) {
            parsedUnit = '';
        }

        // console.info('value:: ', value);
        // console.info('column:: ', column);
        // return (<React.Fragment>xxx</React.Fragment>);
        return (<React.Fragment>{parsedValue}<span className={classes.unit}>{parsedUnit}</span></React.Fragment>);
        // return (<React.Fragment>{value[column]}</React.Fragment>);

        // return (
        //     <React.Fragment>
        //         {(value !== null && value !== undefined) ?
        //             ((() => {
        //                         return (
        //                             <React.Fragment>
        //                                 {() => {
        //                                     const val = eval(value);
        //                                     return (val !== null && val !== undefined) ? nf.format(eval(val).toFixed(2)) : value
        //                                 }}
        //                                 <span className={classes.tableValueDimension}>
        //                                     {/*{column.constraints.dimension.replace(/ +/g, "")}*/}
        //                                 </span>
        //                             </React.Fragment>
        //                         );
        //             })())
        //             : ''}
        //     </React.Fragment>
        // )
    }
}

FoodTableCell.propTypes = {
    classes: PropTypes.object.isRequired,
    unit: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    forceString: PropTypes.bool,
    // column: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FoodTableCell)
import React, {Component} from 'react';
// import firebase from "firebase";
import {auth} from "common/firebase"
import * as utils from "common/utils";

const qs = require('query-string');

class TermsOfConduct extends Component {

    componentDidMount() {
        const {__email, __password, __withBearer} = qs.parse(this.props.location.search);

        // Turn off phone auth app verification.

        if (utils.notNullOrUndefined(__email) && utils.notNullOrUndefined(__password)) {

            auth.settings.appVerificationDisabledForTesting = true;
            // const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
            auth
                .signInWithEmailAndPassword(__email, __password)
                // .signInWithPhoneNumber(`+${__mobile}`, appVerifier)
                .then(async confirmationResult => {
                            const t = await confirmationResult.user.getIdToken();
                            const tt = __withBearer ? `Bearer ${t}` : t;
                            console.info('tt:', tt);
                            await navigator.clipboard.writeText(tt);
                })
                .catch(function (error) {
                    console.error('E0019', error.toString());
                });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="recaptcha-container">

                </div>
                not found
            </React.Fragment>
        )
    }
}

export default TermsOfConduct


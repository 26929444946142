import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import Admin from "pages/Admin";
import EmailVerify from "pages/EmailVerify";
import Info from "pages/Info";
import Login from "pages/Login";
import Page404 from "pages/Page404";
import TermsOfConduct from "pages/TermsOfConduct";

import * as routes from "common/routes";
import adminTheme from "common/theme";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";

import activityReducer from "store/reducers/activities";
import authReducer from "store/reducers/auth";
import carbsReducer from "store/reducers/carbs";
import categoryReducer from "store/reducers/categories";
import masterDataReducer from "store/reducers/masterdata";
import newsReducer from "store/reducers/news";
import nutritionReducer from "store/reducers/nutritions";
import recipeReducer from "store/reducers/recipes";

const rootReducer = combineReducers({
  auth: authReducer,
  activity: activityReducer,
  carbs: carbsReducer,
  category: categoryReducer,
  masterdata: masterDataReducer,
  news: newsReducer,
  nutrition: nutritionReducer,
  recipe: recipeReducer
});

const store = createStore(rootReducer);

function AdminWrapper(props) {
  return (
    <MuiThemeProvider theme={adminTheme}>
      <CssBaseline />
      <Admin />
    </MuiThemeProvider>
  );
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path={routes.ADMIN} component={AdminWrapper} />
        <Route path={routes.LOGIN} component={Login} />
        <Route path={routes.INFO} component={Info} />
        <Route path={routes.EMAIL_VERIFY} component={EmailVerify} />
        <Route path={routes.TERMS_OF_CONDUCT} component={TermsOfConduct} />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import Loader from "components/Loader";
import {Redirect} from "react-router-dom";
import * as routes from 'common/routes';
import ReactTimeout from "react-timeout";
import Typography from "@material-ui/core/Typography";
import Copyright from "components/Copyright";
import Api from "common/Api";


const qs = require('query-string');

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        minHeight: '100vh',
    },
    main: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    divider: {
        margin: '4px',
        backgroundColor: 'inherit',
    }
});

const STATUS_WORKING = 'working';
const STATUS_INTENTIONAL_TIMEOUT = 'intentionalTimeout';
const STATUS_DONE = 'done';

class EmailVerify extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            status: STATUS_WORKING,
            redirect: null,
        }
    }

    componentDidMount() {

        const {__c, __e} = qs.parse(this.props.location.search);

        //TODO: validation!!

        Api.get(`users/email/verify?__e=${__e}&__c=${__c}`)
            .then(async res => {
                await this.setState({status: STATUS_INTENTIONAL_TIMEOUT});
                this.props.setInterval(async () => {
                    await this.setState({status: STATUS_DONE});
                }, 3000);
            })
            .catch(e => {
                this.setState({
                    redirect: {
                        pathname: routes.INFO,
                        state: {
                            from: routes.EMAIL_VERIFY,
                            message: 'Invalid Verification Data!',
                        }
                    }
                })
            });
    }

    render() {

        const {classes} = this.props;
        const {status, redirect} = this.state;

        if (redirect) {
            return <Redirect to={redirect}/>;
        }

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <div className={classes.main}>
                    {status === STATUS_INTENTIONAL_TIMEOUT && (
                        <Typography variant="h5" component="h2" gutterBottom>
                            Successfully Verified! Redirecting you to login...
                        </Typography>
                    )}
                    {status === STATUS_WORKING && (
                        <React.Fragment>
                            <Typography variant={"h5"}>
                                Please wait while we verify your email...
                            </Typography>
                            <Loader/>
                        </React.Fragment>
                    )}
                    {status === STATUS_DONE && (<Redirect to={routes.LOGIN}/>)}
                </div>
                <Copyright/>
            </div>
        );
    }
}

EmailVerify.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(EmailVerify));
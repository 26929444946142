import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";


const styles = theme => ({
    meGrid: {
        minHeight: theme.spacing(10)
    }
});

class Loader extends React.Component {

    render() {

        const {classes} = this.props;

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes.meGrid}
            >
                <CircularProgress className={classes.progress}/>
            </Grid>
        )
    }
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Loader)
import axios from 'axios';
import {auth} from 'common/firebase';
import * as utils from 'common/utils';

const API = process.env.REACT_APP_API_URL;

class Api {

    static get = (query, config = {}) => {
        return new Promise(async (resolve, reject) => {

                const dConfig = {...await Api.getDefaultHeaders(), ...config};

                axios.get(API + query, dConfig)
                    .then(result => {
                        console.log(`API call=> GET:'${API + query}' successful!`);
                        if (process.env.NODE_ENV === 'development') {
                            console.info(result);
                        }
                        resolve(result);
                    })
                    .catch(error => {
                        console.error(`E0008`, `API call=> GET:'${API + query}' unsuccessful!`);
                        if (process.env.NODE_ENV === 'development') {
                            console.info(error);
                        }
                        reject(error);
                    });
            }
        )
    };

    static post = async (query, data = {}, config = {}) => {
        const dConfig = {...await Api.getDefaultHeaders(), ...config};
        return new Promise((resolve, reject) => {
            axios.post(API + query, data, dConfig)
                .then(result => {
                    console.log(`API call=> POST:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`E0008`, `API call=> POST:'${API + query}' with data: '${JSON.stringify(data)}' unsuccessful!`);
                    reject(error);
                });
        });
    };

    static put = async (query, data = {}, config = {}) => {
        const dConfig = {...await Api.getDefaultHeaders(), ...config};
        return new Promise((resolve, reject) => {
            axios.put(API + query, data, dConfig)
                .then(result => {
                    console.log(`API call=> PUT:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`E0009`, `API call=> PUT:'${API + query}' with data: '${JSON.stringify(data)}' unsuccessful!`);
                    reject(error);
                });
        });
    };

    static delete = async (query, config = {}) => {
        const dConfig = {...await Api.getDefaultHeaders(), ...config};
        return new Promise((resolve, reject) => {
            axios.delete(API + query, dConfig)
                .then(result => {
                    console.log(`API call=> DELETE:'${API + query}' successful!`);
                    console.info(result);
                    resolve(result);
                })
                .catch(error => {
                    console.error(`E0010`, `API call=> DELETE:'${API + query}' unsuccessful!`);
                    reject(error);
                });
        });
    };

    static getToken = async () => {
        return new Promise(async (resolve, reject) => {
            if (utils.isNullOrUndefined(auth.currentUser)) {
                resolve(null);
            } else {
                await auth.currentUser.getIdToken()
                    .then(token => {
                        resolve(token);
                    })
            }
        });
    };

    static getDefaultHeaders = async () => {
        return new Promise(async (resolve, reject) => {
            const token = await Api.getToken();
            resolve({
                ...{
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                }
            });
        });
    };
}

export default Api;
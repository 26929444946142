import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: { en_GB: "Images", de_DE: "" },
    name: "images",
    visibility: 0,
    tab: "recipe",
    type: "images",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Title EN", de_DE: "Title DE" },
    name: "title",
    visibility: 1,
    required: true,
    localized: true,
    tab: "recipe",
    type: "textField"
  },
  {
    label: { en_GB: "Short Description EN", de_DE: "Short Description DE" },
    name: "shortDescription",
    visibility: 0,
    localized: true,
    tab: "recipe",
    type: "textField"
  },
  {
    label: { en_GB: "Description EN", de_DE: "Description DE" },
    name: "description",
    visibility: 1,
    localized: true,
    tab: "recipe",
    type: "textField"
  },
  {
    label: { en_GB: "Servings", de_DE: "" },
    name: "servings",
    visibility: 0,
    required: true,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    tab: "recipe",
    type: "number"
  },
  {
    label: { en_GB: "Ingredients", de_DE: "" },
    name: "ingredientsGroups",
    visibility: 0,
    tab: "ingredients",
    type: "ingredients",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    children: {
      name: {
        label: { en_GB: "Name EN", de_DE: "Name DE" },
        name: "name",
        localized: true,
        visibility: 0,
        type: "textField"
      },
      images: {
        label: { en_GB: "Images", de_DE: "" },
        name: "images",
        visibility: 0,
        type: "images",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      ingredient: {
        label: { en_GB: "Ingredient", de_DE: "" },
        name: "ingredient",
        visibility: 0,
        required: true, //at least one
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        type: "ingredient",
        children: {
          name: {
            disabled: true,
            label: { en_GB: "Name EN", de_DE: "Name DE" },
            name: "name",
            visibility: 0,
            localized: true,
            type: "textField"
          },
          amount: {
            label: { en_GB: "Amount", de_DE: "" },
            name: "amount",
            visibility: 0,
            type: "number",
            forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
          },
          baseUnit: {
            label: { en_GB: "Unit", de_DE: "" },
            name: "baseUnit",
            disabled: true,
            visibility: 0,
            type: "textField",
            forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
          },
          ref: {
            label: { en_GB: "Search for ingredient", de_DE: "" },
            name: "ref",
            visibility: 0,
            type: "searchFood",
            forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
          }
        }
      }
    }
  },
  {
    label: { en_GB: "Tools", de_DE: "" },
    name: "tools",
    visibility: 0,
    tab: "ingredients",
    type: "tools",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    children: {
      toolImages: {
        label: { en_GB: "Tool Images", de_DE: "" },
        name: "toolImages",
        visibility: 0,
        type: "images",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      }
    }
  },
  {
    label: { en_GB: "Instructions", de_DE: "" },
    name: "instructions",
    visibility: 0,
    tab: "instructions",
    type: "instructions",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    children: {
      name: {
        label: { en_GB: "Name EN", de_DE: "Name DE" },
        name: "name",
        visibility: 0,
        localized: true,
        type: "textField"
      },
      images: {
        label: { en_GB: "Images", de_DE: "" },
        name: "images",
        visibility: 0,
        type: "images",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      prepTime: {
        label: { en_GB: "Preparation time", de_DE: "" },
        name: "prepTime",
        visibility: 0,
        type: "number",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      cookingTime: {
        label: { en_GB: "Cooking time", de_DE: "" },
        name: "cookingTime",
        visibility: 0,
        type: "number",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      restTime: {
        label: { en_GB: "Rest time", de_DE: "" },
        name: "restTime",
        visibility: 0,
        type: "number",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      step: {
        label: { en_GB: "Step", de_DE: "" },
        name: "step",
        visibility: 0,
        type: "step",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        children: {
          selectedIngredients: {
            label: { en_GB: "Select Ingredients", de_DE: "" },
            name: "selectedIngredients",
            visibility: 0,
            type: "select",
            prop: "ingredient",
            forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
          },
          selectedTools: {
            label: { en_GB: "Select Tools", de_DE: "" },
            name: "selectedTools",
            visibility: 0,
            type: "select",
            prop: "tool",
            forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
          },
          text: {
            label: { en_GB: "Text EN", de_DE: "Text DE" },
            name: "text",
            visibility: 0,
            localized: true,
            type: "textArea"
          }
        }
      }
    }
  },
  {
    label: { en_GB: "Duration", de_DE: "" },
    name: "duration",
    visibility: 1,
    filter: false,
    tab: "info",
    type: "number",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Difficulty", de_DE: "" },
    name: "difficulty",
    visibility: 1,
    filter: true,
    tab: "info",
    type: "select",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Easy", de_DE: "" }, name: "easy", value: 0 },
      { label: { en_GB: "Hard", de_DE: "" }, name: "hard", value: 1 },
      { label: { en_GB: "Complex", de_DE: "" }, name: "complex", value: 2 }
    ]
  },
  {
    label: { en_GB: "Food Choice", de_DE: "" },
    name: "foodChoice",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: false,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Vegan", de_DE: "" }, name: "vegan" },
      { label: { en_GB: "Vegetarian", de_DE: "" }, name: "vegetarian" },
      { label: { en_GB: "Meat", de_DE: "" }, name: "meat" },
      { label: { en_GB: "Halal", de_DE: "" }, name: "halal" }
    ]
  },
  {
    label: { en_GB: "Allergens", de_DE: "" },
    name: "allergens",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: true,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Eggs", de_DE: "" }, name: "eggs" },
      { label: { en_GB: "Peanuts", de_DE: "" }, name: "peanuts" },
      { label: { en_GB: "Fish", de_DE: "" }, name: "fish" },
      {
        label: { en_GB: "Gluten-containing cereal", de_DE: "" },
        name: "gluten-cereal"
      },
      { label: { en_GB: "Crustaceans", de_DE: "" }, name: "crustaceans" },
      { label: { en_GB: "Lupine", de_DE: "" }, name: "lupine" },
      { label: { en_GB: "Milk", de_DE: "" }, name: "milk" },
      { label: { en_GB: "Nuts (nuts)", de_DE: "" }, name: "nuts" },
      { label: { en_GB: "Sulfur dioxide", de_DE: "" }, name: "so2" },
      { label: { en_GB: "Celery", de_DE: "" }, name: "celery" },
      { label: { en_GB: "Mustard", de_DE: "" }, name: "mustard" },
      { label: { en_GB: "Sesame", de_DE: "" }, name: "sesame" },
      { label: { en_GB: "Soy", de_DE: "" }, name: "soy" },
      { label: { en_GB: "Molluscs", de_DE: "" }, name: "moll" }
    ]
  },
  {
    label: { en_GB: "Nutrition Types", de_DE: "" },
    name: "nutritionTypes",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: true,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Low carb", de_DE: "" }, name: "lowcarb" },
      { label: { en_GB: "Protein rich", de_DE: "" }, name: "proteinrich" },
      { label: { en_GB: "Keto", de_DE: "" }, name: "keto" },
      { label: { en_GB: "Ichf", de_DE: "" }, name: "ichf" },
      { label: { en_GB: "Vegeterian", de_DE: "" }, name: "vegeterian" },
      { label: { en_GB: "Vegan", de_DE: "" }, name: "vegan" },
      { label: { en_GB: "Low calorie", de_DE: "" }, name: "lowcal" },
      { label: { en_GB: "Low fat", de_DE: "" }, name: "lowfat" }
    ]
  },
  {
    label: { en_GB: "Meal Types", de_DE: "" },
    name: "mealTypes",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: false,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Breakfast", de_DE: "" }, name: "lowcarb" },
      { label: { en_GB: "Main meal", de_DE: "" }, name: "mainmeal" },
      { label: { en_GB: "Snack", de_DE: "" }, name: "snack" },
      { label: { en_GB: "Drink", de_DE: "" }, name: "drink" },
      { label: { en_GB: "Dessert", de_DE: "" }, name: "dessert" },
      { label: { en_GB: "Baking", de_DE: "" }, name: "baking" },
      { label: { en_GB: "Salad", de_DE: "" }, name: "salad" },
      { label: { en_GB: "Shake", de_DE: "" }, name: "shake" },
      { label: { en_GB: "Smoothie", de_DE: "" }, name: "smoothie" },
      { label: { en_GB: "Soup", de_DE: "" }, name: "soup" },
      { label: { en_GB: "Appetizer", de_DE: "" }, name: "appetizer" }
    ]
  },
  {
    label: { en_GB: "Occasions", de_DE: "" },
    name: "occasions",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: true,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Soul food", de_DE: "" }, name: "soul" },
      { label: { en_GB: "Fast food", de_DE: "" }, name: "fast" },
      { label: { en_GB: "Party food", de_DE: "" }, name: "party" },
      { label: { en_GB: "To go", de_DE: "" }, name: "togo" },
      { label: { en_GB: "For kids", de_DE: "" }, name: "forkids" },
      { label: { en_GB: "Barbecue", de_DE: "" }, name: "bbq" },
      { label: { en_GB: "Halloween", de_DE: "" }, name: "halloween" },
      { label: { en_GB: "Quick&easy", de_DE: "" }, name: "q&e" },
      { label: { en_GB: "Christmas", de_DE: "" }, name: "christmas" }
    ]
  },
  {
    label: { en_GB: "Main Ingredients", de_DE: "" },
    name: "mainIngredients",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: true,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Beef", de_DE: "" }, name: "beef" },
      { label: { en_GB: "Poultry", de_DE: "" }, name: "poultry" },
      { label: { en_GB: "Fish", de_DE: "" }, name: "fish" },
      { label: { en_GB: "Vegetables", de_DE: "" }, name: "vegs" },
      { label: { en_GB: "Pasta", de_DE: "" }, name: "pasta" }
    ]
  },
  {
    label: { en_GB: "Culinary Regions", de_DE: "" },
    name: "culinaryRegions",
    visibility: 0,
    filter: true,
    tab: "info",
    type: "select",
    multiple: false,
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    selectData: [
      { label: { en_GB: "Italian", de_DE: "" }, name: "beef" },
      { label: { en_GB: "German", de_DE: "" }, name: "beef" },
      { label: { en_GB: "Asian", de_DE: "" }, name: "beef" },
      { label: { en_GB: "American", de_DE: "" }, name: "beef" },
      { label: { en_GB: "Tex-mex", de_DE: "" }, name: "beef" },
      { label: { en_GB: "Indian", de_DE: "" }, name: "beef" },
      { label: { en_GB: "Oriental", de_DE: "" }, name: "beef" }
    ]
  },
  {
    label: { en_GB: "Additional Info", de_DE: "" },
    name: "additionalInfo",
    visibility: 0,
    tab: "info",
    type: "textArea",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  }
];

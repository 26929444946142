import { createAction } from "redux-actions";

export const DELETE_MEAL = "news@@DELETE_MEAL";
export const SET_PROPERTY = "news@@SET_PROPERTY";
export const SET_ERROR = "news@@SET_ERROR";
export const SET_TO_DELETE = "news@@SET_TO_DELETE";
export const SET_INIT_DATA = "news@@SET_INIT_DATA";
export const SET_TO_DISCARD = "news@@SET_TO_DISCARD";
export const DISCARD_ALL_DATA = "news@@DISCARD_ALL_DATA";

const initialState = {
  active: false,
  search_string: null,
  description: null,
  title: null,
  subtitle: null,
  icon: null,
  image: null,
  link: null,
  editedPreviously: false,
  errors: null,
  toDelete: [],
  toDiscard: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY: {
      let newState = { ...state };
      const { property, value, lang } = action.payload;
      // console.log("SET PROPERTY");
      // console.log(property, value);

      if (lang) {
        if (!newState[property]) {
          newState[property] = {};
        }
        newState[property][lang] = value;
      } else {
        newState[property] = value;
      }

      return {
        ...newState,
        editedPreviously: true
      };
    }
    case SET_ERROR: {
      const { error, tab, name } = action.payload;
      // console.log("SET ERROR", error, tab, name);
      const errors = { ...state.errors };
      if (errors[tab] === undefined) {
        errors[tab] = [];
      }

      if (!errors[tab].includes(name)) {
        if (error) {
          errors[tab].push(name);
        }
      } else {
        if (!error) {
          for (var i = 0; i < errors[tab].length; i++) {
            if (errors[tab][i] === name) {
              errors[tab].splice(i, 1);
            }
          }
        }
      }

      if (errors[tab].length === 0) {
        delete errors[tab];
      }

      // console.log("SET ERROR", errors);
      return {
        ...state,
        errors
      };
    }
    case SET_TO_DELETE: {
      let { toDelete } = state;
      const imgURL = action.payload;
      // const decodedImgURL = decodeURIComponent(imgURL);
      toDelete.push(imgURL);

      // console.log("todelete", toDelete);
      return {
        ...state,
        toDelete: toDelete.slice(0),
        editedPreviously: true
      };
    }
    case SET_TO_DISCARD: {
      let { toDiscard } = state;
      toDiscard.push(action.payload);

      // console.log("todiscard", toDiscard);
      return {
        ...state,
        toDiscard: toDiscard.slice(0),
        editedPreviously: true
      };
    }
    case SET_INIT_DATA: {
      let newState = { ...state };
      if (action.payload !== null) {
        for (const key in newState) {
          if (Array.isArray(newState[key])) {
            newState[key] = action.payload[key] ? action.payload[key] : [];
          } else {
            newState[key] =
              action.payload[key] || action.payload[key] === false
                ? action.payload[key]
                : null;
          }
        }
      } else {
        newState = { ...initialState };
      }
      // console.log("set init data", newState);

      return {
        ...newState,
        editedPreviously: false
      };
    }
    case DISCARD_ALL_DATA: {
      // console.log("discard data", initialState);

      return {
        ...initialState
      };
    }
    case DELETE_MEAL: {
      delete state.meals[action.payload];
      return {
        ...state,
        editedPreviously: true
      };
    }
    default:
      return state;
  }
};

export const deleteMeal = createAction(DELETE_MEAL);
export const setProperty = createAction(SET_PROPERTY);
export const setError = createAction(SET_ERROR);
export const setToDelete = createAction(SET_TO_DELETE);
export const setToDiscard = createAction(SET_TO_DISCARD);
export const setInitData = createAction(SET_INIT_DATA);
export const discardAllData = createAction(DISCARD_ALL_DATA);

export default reducer;

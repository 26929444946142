// import adminTheme from "common/theme";

export default theme => ({
    // ...adminTheme,
    ...{
        root: {
            border: '1px solid rgba(0, 0, 0, .125)',
            boxShadow: 'none',
            '&:not(:last-child)': {
                borderBottom: 0,
            },
            '&:before': {
                display: 'none',
            },
            '&$expanded': {
                margin: 'auto',
            },
        },
        versionLabel: {
            textAlign: 'right',
        },
        fabPublish: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            display: "grid",
            gridAutoFlow: "column",
            gridGap: theme.spacing(1)
        },
        editedValue: {
            color: theme.palette.secondary.main,
            padding: theme.spacing(1),
        },
        vanillaValue: {
            padding: theme.spacing(1),
        },
        colorPreview: {
            padding: theme.spacing(.5),
            marginLeft: theme.spacing(.5),
            maxHeight: '40px',
            marginTop: '8px',
            flex: '0 0 40px',
            borderRadius: theme.spacing(.5),
        },
        actionsContainer: {
            display: 'flex',
        },
        actionButton: {
            marginLeft: theme.spacing(1),
        },
        hexWrapper: {
            display: 'flex',
            flexWrap: 'nowrap',
        }
    }
});

import React from "react";

import { StyledButton } from "common/theme";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

const styles = theme => ({
  accept: {
    backgroundColor: "#2dc455",
    color: "#fff",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#26a648"
    }
  },
  discard: {
    backgroundColor: "#ba2929",
    color: "#fff",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#992222"
    }
  }
});

class UserEditActions extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <DialogActions>
        <StyledButton onClick={this.props.cancel}>
          <Typography>Cancel</Typography>
        </StyledButton>
        <StyledButton
          className={classes.discard}
          variant="contained"
          onClick={this.props.discard}
        >
          <Typography>Discard</Typography>
        </StyledButton>
        <StyledButton
          className={classes.accept}
          variant="contained"
          onClick={this.props.accept}
        >
          <Typography>Accept</Typography>
        </StyledButton>
      </DialogActions>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UserEditActions);

export const getImages = state => state.recipe.images;
export const getTitle = state => state.recipe.title;
export const getDescription = state => state.recipe.description;
export const getShortDescription = state => state.recipe.shortDescription;
export const getServings = state => state.recipe.servings;
export const getIngredientsGroups = state => state.recipe.ingredientsGroups;
export const getIngredients = state => {
  let arr = [];
  state.recipe.ingredientsGroups.forEach(ing =>
    ing.ingredients.forEach(i => arr.push({ label: i.name, value: i.name }))
  );
  return arr;
};
export const getTools = state => state.recipe.tools;
export const getToolImages = state => state.recipe.toolImages;
export const getInstructions = state => state.recipe.instructions;
export const getDifficulty = state => state.recipe.difficulty;
export const getFoodChoice = state => state.recipe.foodChoice;
export const getAllergens = state => state.recipe.allergens;
export const getNutritionTypes = state => state.recipe.nutritionTypes;
export const getMealTypes = state => state.recipe.mealTypes;
export const getOccasions = state => state.recipe.occasions;
export const getMainIngredients = state => state.recipe.mainIngredients;
export const getCulinaryRegions = state => state.recipe.culinaryRegions;
export const getEditedPreviously = state => state.recipe.editedPreviously;
export const getErrors = state => state.recipe.errors;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.recipe));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;

  data.ingredientsGroups = data.ingredientsGroups.filter(el => el);
  data.instructions = data.instructions.filter(el => el);

  data.ingredientsGroups.forEach(ingredientGroup => {
    delete ingredientGroup.expanded;
    ingredientGroup.ingredients = ingredientGroup.ingredients.filter(el => el);
    ingredientGroup.ingredients.forEach(ingredient => {
      delete ingredient.expanded;
    });
  });

  data.instructions.forEach(instruction => {
    delete instruction.expanded;
    instruction.step = instruction.step.filter(el => el);
    instruction.step.forEach(step => {
      delete step.expanded;
    });
  });

  data.tools = data.tools.filter(el => el);
  data.toolImages = data.toolImages.filter(el => el);

  if (data.title)
    data.search_string = `${data.title.de_DE}${data.title.en_GB}`
      .replace(/\s/g, "")
      .toLowerCase();

  // console.log(data);
  return data;
};

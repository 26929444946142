import React from "react";

import Dropzone from "react-dropzone";
import classNames from "classnames";

import { storage } from "common/firebase";
import Api from "common/Api";

import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { StyledButton } from "common/theme";

const styles = theme => ({
  info: {
    padding: "5px 20px",
    margin: "15px 0"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  dropzone: {
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    textAlign: "center"
  },
  dropzoneAdd: {
    background: "lightgreen",
    cursor: "copy"
  },
  dropzoneErrorMessage: {
    color: theme.palette.secondary.main
  },
  warning: {
    color: theme.palette.secondary.main
  },
  doneLinearProgress: {
    backgroundColor: "lightgreen"
  }
});

class ImportDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      transformationsData: [],
      open: false,
      uploadingProgress: 0,
      dropzoneCssClasses: [this.props.classes.dropzone],
      dropzoneErrorMessages: [],
      fileToUpload: null
    };

    ImportDialog.__singletonRef = this;
  }

  static open() {
    ImportDialog.__singletonRef.__open();
  }

  __open() {
    this.setState({ open: true });
  }

  handleDropFile = files => {
    // console.info(files);
    // let reader = new FileReader();
    // reader.readAsText(files[0]);
    // reader.onload = e => {
    //   var fileString = e.target.result;
    //   console.log(fileString);
    //   // xhr.send(fileString)
    // };
  };

  handleSaveUpload = () => {
    this.setState({
      uploadingProgress: 0
    });
    if (!this.state.fileToUpload) {
      this.state.dropzoneErrorMessages.push("Invalid file for upload!");
      return;
    }
    // Create the file metadata
    const metadata = {
      contentType: "text/xml"
    };
    const id = new Date().getTime();
    const extension = this.state.fileToUpload.name.split(".")[1];
    const filePath = `uploads/recipes-${id}.${extension}`;
    // Create a reference to the file we want to upload
    const pathReference = storage.ref(filePath);
    let uploadTask = pathReference.put(this.state.fileToUpload, metadata);
    this.setState({ uploadTask: uploadTask });
    uploadTask.on(
      "state_changed",
      snapshot => {
        this.setState({
          uploadingProgress:
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        });
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      error => {
        const errorPrefix = "Firebase-put-file:: ";
        let errorMessage = "";
        switch (error.code) {
          case "storage/unauthorized":
            errorMessage = "User doesn't have permission to access the object";
            break;
          case "storage/canceled":
            errorMessage = "User canceled the upload";
            break;
          case "storage/unknown":
            errorMessage =
              "Unknown error occurred, inspect the error.serverResponse";
            break;
          default:
            errorMessage = "All gone haywire!!";
            break;
        }
        console.error(errorPrefix + errorMessage);
      },
      async () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async downloadURL => {
          console.log("File available at", downloadURL);
          console.info(id);
          await Api.post(`transformations`, {
            type: "recipes",
            originalFilename: this.state.fileToUpload.name,
            filename: `recipes-${id}.xml`
          });
          this.checkTransformations().then(() => {
            this.setState({
              open: false,
              uploadingProgress: 0,
              dropzoneErrorMessages: [],
              fileToUpload: null
            });
          });
        });
      }
    );
  };

  checkTransformations = async () => {
    // Api.get('transformations/recipes')
    //     .then(result => {
    //         this.setState({
    //             transformationsData: Object.values(result.data),
    //         });
    //     });
  };

  handleDropFileAccepted = (files, event) => {
    this.setState({
      dropzoneErrorMessages: [],
      fileToUpload: files[0]
    });
    this.resetDropzoneCSS();
  };

  handleDropFileRejected = (files, event) => {
    this.setState({
      dropzoneErrorMessages: []
    });

    if (files.length > 1) {
      this.state.dropzoneErrorMessages.push("Only one file is allowed!");
    }

    this.resetDropzoneCSS();
  };

  handleDragEnter = event => {
    const dropzoneCssClasses = this.state.dropzoneCssClasses;
    dropzoneCssClasses.push(this.props.classes.dropzoneAdd);
    this.setState({ dropzoneCssClasses: dropzoneCssClasses });
  };

  handleDragLeave = event => {
    this.resetDropzoneCSS();
  };

  resetDropzoneCSS = () => {
    const dropzoneCssClasses = this.state.dropzoneCssClasses.filter(
      el => el !== this.props.classes.dropzoneAdd
    );
    this.setState({
      dropzoneCssClasses: dropzoneCssClasses
    });
  };

  handleCloseUploadDialog = () => {
    this.setState({
      open: false,
      dropzoneErrorMessages: [],
      fileToUpload: null
    });
  };

  render() {
    const { classes, fullScreen } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.state.open}
        onClose={this.handleCloseUploadDialog}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Uploading file"}
        </DialogTitle>

        {this.state.uploadingProgress ? (
          <React.Fragment>
            <DialogContent>
              <DialogContentText>
                Please wait...
                <br />
                <span className={classes.warning}>
                  Leaving this page before the upload is done will cancel
                  transformation!
                </span>
              </DialogContentText>
              <LinearProgress
                variant="determinate"
                value={this.state.uploadingProgress}
              />
            </DialogContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogContent>
              <DialogContentText>
                This will upload file and start generating structure in the
                database.
              </DialogContentText>
              <Dropzone
                onDrop={this.handleDropFile}
                onDropAccepted={this.handleDropFileAccepted}
                onDropRejected={this.handleDropFileRejected}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                accept={"application/json, text/xml"}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className={classNames(this.state.dropzoneCssClasses)}>
                        Drag 'n' drop a file here, or click to select file
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <Typography>
                {this.state.fileToUpload && this.state.fileToUpload.name}
              </Typography>
              {this.state.dropzoneErrorMessages.map((errorMessage, key) => (
                <Typography key={key} className={classes.dropzoneErrorMessage}>
                  {errorMessage}
                </Typography>
              ))}
            </DialogContent>
            <DialogActions>
              <StyledButton
                onClick={this.handleCloseUploadDialog}
              >
                Cancel
              </StyledButton>
              <StyledButton
                disabled={!this.state.fileToUpload}
                onClick={this.handleSaveUpload}
                color="primary"
                variant="contained"
                autoFocus
              >
                Upload
              </StyledButton>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImportDialog);

import React from "react";

import { StyledButton } from "common/theme";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";

class EditActions extends React.Component {
  render() {
    return (
      <DialogActions>
        <StyledButton
          onClick={this.props.reset}
          disabled={
            this.props.edited !== undefined ? !this.props.edited : false
          }
        >
          <Typography>Reset All Changes</Typography>
        </StyledButton>
        <StyledButton onClick={this.props.cancel}>
          <Typography>Cancel</Typography>
        </StyledButton>
        <StyledButton
          color="primary"
          variant="contained"
          onClick={this.props.save}
        >
          <Typography>Save</Typography>
        </StyledButton>
      </DialogActions>
    );
  }
}

export default EditActions;

import React from "react";
import BasicProperty from "components/BasicProperty";

export default class LocalizedProperty extends React.Component {
  render() {
    const { template }  = this.props;
    return template.localized ? (
      <React.Fragment>
        <BasicProperty
          {...this.props}
          id={`${template.name}-en_GB`}
          value={this.props.value ? this.props.value.en_GB : ""}
          error={
            this.props.errors && this.props.errors[template.tab]
              ? this.props.errors[template.tab].includes(
                  `${template.name}-en_GB`
                )
              : false
          }
          lang={"en_GB"}
        />
        <BasicProperty
          {...this.props}
          id={`${template.name}-de_DE`}
          value={this.props.value ? this.props.value.de_DE : ""}
          error={
            this.props.errors && this.props.errors[template.tab]
              ? this.props.errors[template.tab].includes(
                  `${template.name}-de_DE`
                )
              : false
          }
          lang={"de_DE"}
        />
      </React.Fragment>
    ) : (
      <BasicProperty
        {...this.props}
        value={this.props.value}
        error={
          this.props.errors && this.props.errors[template.tab]
            ? this.props.errors[template.tab].includes(template.name)
            : false
        }
      />
    );
  }
}

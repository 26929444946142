import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Loader from "components/Loader";
import Api from "common/Api";
import Fab from "@material-ui/core/Fab";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import * as constants from 'common/constants';
import * as utils from 'common/utils';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import styles from './styles';
import moment from "moment";
import StatusChip from "components/StatusChip";
import {connect} from "react-redux";
import Auth from "common/Auth";
import { StyledButton } from "common/theme";

class Employees extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            usersData: [],
            anchorElement: null,
            theOneUser: null,
            newEmployeeDialogOpen: false,
            newEmployeeEmail: '',
            newEmployeePassword: '',
            deleteUserDialogOpen: false,
        };
    }

    get isNewEmployeeEmailValid() {
        return this.state.newEmployeeEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    get isNewEmployeePasswordValid() {
        return this.state.newEmployeePassword.length >= 8;
    }

    get isNewEmployeeLevelValid() {
        //TODO: check status!
        return true;
    }

    get isNewEmployeeCreateButtonDisabled() {
        if (!this.isNewEmployeePasswordValid) {
            return true;
        } else if (!this.isNewEmployeeEmailValid) {
            return true;
        } else if (!this.isNewEmployeeLevelValid) {
            return true;
        } else {
            return false;
        }
    }

    get users() {
        return this.state.usersData
            // remove GHOSTS (from previous versions of the app)
            .filter(userData => utils.notNullOrUndefined(userData.email));
    }

    get isUserAdmin() {
        return Auth.isAdmin(this.props.user);
    }

    componentDidMount() {
        this.reloadTable()
            .then(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    reloadTable = async () => {

        await Api.get('users')
            .then(async result => {
                await this.setState({
                    usersData: result.data.users,
                    loading: false,
                });
            })
            .catch(e => {
                console.error(e.toString());
                // this.props.onChangePage(menus[0].entries[0]);
            })
    };

    handleRowClick = async (rowData, rowMeta) => {
        await this.setState({
            theOneUser: {
                uid: rowData[0],
                disabled: rowData[1],
                accessLevel: rowData[2].accessLevel,
                email: rowData[3],
                emailVerified: rowData[4],
            }
        });
    };

    handleOpenUserMenu = async event => {
        this.setState({anchorElement: event.currentTarget});
    };

    handleCloseUserMenu = () => {
        this.setState({anchorElement: null});
    };

    handlePromoteUserToAdmin = async () => {
        this.handleCloseUserMenu();
        await this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}/claims`, {
            accessLevel: constants.USER_LEVEL_ADMIN
        });
        await this.reloadTable();
    };

    handlePromoteUserToExternalEmployee = async () => {
        this.handleCloseUserMenu();
        await this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}/claims`, {
            accessLevel: constants.USER_LEVEL_EXTERNAL_EMPLOYEE
        });
        await this.reloadTable();
    };

    handleDemoteUserToEmployee = async () => {
        this.handleCloseUserMenu();
        await this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}/claims`, {
            accessLevel: constants.USER_LEVEL_EMPLOYEE,
        });
        await this.reloadTable();
    };

    handleDisableUser = async () => {
        this.handleCloseUserMenu();
        await this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}`, {
            disabled: true,
        });
        await this.reloadTable();
    };

    handleEnableUser = async () => {
        this.handleCloseUserMenu();
        await this.setState({loading: true});
        await Api.put(`users/${this.state.theOneUser.uid}`, {
            disabled: false,
        });
        await this.reloadTable();
    };

    handleResendEmailVerification = (email) => async () => {

        this.handleCloseUserMenu();
        await this.setState({loading: true});

        await Api.get(`users/email/resend?__e=${this.state.theOneUser.email}`)
            .then(async () => {
                await this.setState({loading: false});
            });
    };

    handleOpenDeleteUserDialog = () => {
        this.handleCloseUserMenu();
        this.setState({deleteUserDialogOpen: true});
    };

    handleCloseDeleteUserDialog = () => {
        this.setState({deleteUserDialogOpen: false});
    };

    handleDeleteUser = async () => {
        this.handleCloseDeleteUserDialog();
        await this.setState({loading: true});
        await Api.delete(`users/${this.state.theOneUser.uid}`);
        await this.reloadTable();
    };

    handleOpenNewUserDialog = () => {
        this.setState({newEmployeeDialogOpen: true});
    };

    handleCloseNewEmployeeDialog = () => {
        this.setState({newEmployeeDialogOpen: false});
    };

    handleCreateNewEmployee = async () => {
        this.setState({loading: true});
        await Api.post('users', {
            email: this.state.newEmployeeEmail,
            password: this.state.newEmployeePassword,
        });
        this.handleCloseNewEmployeeDialog();
        this.setState({loading: false});
        await this.reloadTable();
    };

    handleNewEmployeeEmailChange = (event, prop) => {
        this.setState({newEmployeeEmail: event.target.value});
    };

    handleNewEmployeePasswordChange = (event, prop) => {
        this.setState({newEmployeePassword: event.target.value});
    };

    render() {

        const {classes} = this.props;

        const {
            loading, anchorElement, theOneUser, deleteUserDialogOpen, newEmployeePassword,
            newEmployeeDialogOpen, newEmployeeEmail,
        } = this.state;

        // Auth.isManager(user)
        // //TODO: complete rework of routing!!
        //     .then(result => {
        //         !result && this.props.onChangePage(menus[0].entries[0])
        //     }); // dashboard

        return (
            <React.Fragment>
                {loading ? <Loader/> : (
                    <React.Fragment>
                        <MUIDataTable
                            data={this.users}
                            columns={[
                                {
                                    name: "uid",
                                    options: {
                                        filter: false,
                                        sort: false,
                                        display: 'excluded',
                                    }
                                },
                                {
                                    name: "disabled",
                                    options: {
                                        filter: false,
                                        sort: false,
                                        display: 'excluded',
                                    }
                                },
                                {
                                    name: "customClaims",
                                    options: {
                                        filter: false,
                                        sort: false,
                                        display: 'excluded',
                                    }
                                },
                                {
                                    name: "email",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        display: "excluded",
                                    }
                                },
                                {
                                    name: "emailVerified",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        display: "excluded",
                                    }
                                },
                                {
                                    label: "Email",
                                    name: "email",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        customBodyRender: value => utils.notNullOrUndefined(value) ? value : '-',
                                    }
                                },
                                {
                                    label: "Email Verified",
                                    name: "emailVerified",
                                    options: {
                                        filter: false,
                                        sort: false,
                                        display: true,
                                        customBodyRender: value => value ? 'yes' : 'no',
                                    }
                                },
                                {
                                    label: "Name",
                                    name: "displayName",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        display: false,
                                        customBodyRender: value => utils.notNullOrUndefined(value) ? value : '-',
                                    }
                                },
                                {
                                    label: "Mobile",
                                    name: "phoneNumber",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        display: false,
                                        customBodyRender: value => utils.notNullOrUndefined(value) ? value : '-',
                                    }
                                },
                                {
                                    label: "Type",
                                    name: "customClaims",
                                    options: {
                                        filter: true,
                                        sort: true,
                                        customBodyRender: value => utils.notNullOrUndefined(value.accessLevel) ? value.accessLevel : '-',
                                    },
                                },
                                {
                                    label: "Status",
                                    name: "disabled",
                                    options: {
                                        filter: false,
                                        sort: true,
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return (<StatusChip
                                                value={value ? constants.ENTITY_STATUS_BLOCKED : constants.ENTITY_STATUS_ACTIVE}/>)
                                        }
                                    },
                                },
                                {
                                    label: "Last Login",
                                    name: "metadata",
                                    options: {
                                        filter: false,
                                        display: false,
                                        sort: false,
                                        customBodyRender: value => utils.notNullOrUndefined(value.lastSignInTime) ? moment(value.lastSignInTime).format("LLL") : '-',
                                    },
                                },
                                {
                                    label: "Date Created",
                                    name: "metadata",
                                    options: {
                                        filter: false,
                                        display: false,
                                        sort: false,
                                        customBodyRender: value => utils.notNullOrUndefined(value.creationTime) ? moment(value.creationTime).format("LLL") : '-',
                                    },
                                },
                                {
                                    label: "Actions",
                                    options: {
                                        filter: false,
                                        sort: false,
                                        display: this.isUserAdmin ? true : 'excluded',
                                        customBodyRender: (value, tableMeta, updateValue) => {
                                            return (
                                                <Button
                                                    aria-owns={anchorElement ? 'simple-menu' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={this.handleOpenUserMenu}
                                                >
                                                    <MoreVert/>
                                                </Button>
                                            )
                                        }
                                    },
                                },
                            ]}
                            options={{
                                filterType: 'checkbox',
                                responsive: "scroll",
                                selectableRows: false,
                                onRowClick: this.handleRowClick,
                                print: false,
                                download: false,
                            }}
                        />
                        <
                            Menu
                            id="simple-menu"
                            anchorEl={anchorElement}
                            open={Boolean(anchorElement)}
                            onClose={this.handleCloseUserMenu}
                        >
                            {theOneUser && theOneUser.accessLevel === constants.USER_LEVEL_EMPLOYEE && (
                                <MenuItem onClick={this.handlePromoteUserToAdmin}>Promote To Admin</MenuItem>
                            )
                            }
                            {theOneUser && theOneUser.accessLevel === constants.USER_LEVEL_EMPLOYEE && (
                                <MenuItem onClick={this.handlePromoteUserToExternalEmployee}>Promote To External
                                    Employee</MenuItem>
                            )
                            }
                            {theOneUser && theOneUser.accessLevel === constants.USER_LEVEL_EXTERNAL_EMPLOYEE && (
                                <MenuItem onClick={this.handleDemoteUserToEmployee}>Demote to Employee</MenuItem>
                            )
                            }
                            {
                                theOneUser && theOneUser.accessLevel === constants.USER_LEVEL_ADMIN && (
                                    <MenuItem onClick={this.handleDemoteUserToEmployee}>Demote to Employee</MenuItem>
                                )
                            }
                            {
                                theOneUser && theOneUser.accessLevel === constants.USER_LEVEL_NEW && (
                                    <MenuItem onClick={this.handleDemoteUserToEmployee}>Promote to Employee</MenuItem>
                                )
                            }
                            {
                                theOneUser && !theOneUser.emailVerified && (
                                    <MenuItem onClick={this.handleResendEmailVerification(theOneUser.email)}>Resend
                                        Email</MenuItem>
                                )
                            }
                            {
                                theOneUser && theOneUser.disabled === true && (
                                    <MenuItem onClick={this.handleEnableUser}>Unblock</MenuItem>
                                )
                            }
                            {
                                theOneUser && theOneUser.disabled === false && (
                                    <MenuItem onClick={this.handleDisableUser}>Block</MenuItem>
                                )
                            }
                            {
                                theOneUser && (
                                    <MenuItem
                                        onClick={this.handleOpenDeleteUserDialog}>Delete</MenuItem>
                                )
                            }
                        </Menu>
                        {this.isUserAdmin && (
                            <Fab className={classes.fab} color={"secondary"} onClick={this.handleOpenNewUserDialog}>
                                <AddIcon/>
                            </Fab>
                        )}
                        <Dialog
                            open={newEmployeeDialogOpen}
                            onClose={this.handleCloseNewEmployeeDialog}
                            aria-labelledby="responsive-dialog-title"
                            BackdropProps={{className: classes.backdrop}}
                        >
                            <DialogTitle id="responsive-dialog-title">{"Create new employee"}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    error={!this.isNewEmployeeEmailValid}
                                    id="outlined-name"
                                    label="Email"
                                    className={classes.textField}
                                    value={newEmployeeEmail}
                                    onChange={this.handleNewEmployeeEmailChange}
                                    margin="normal"
                                    variant="outlined"
                                    type="email"
                                />
                                <TextField
                                    error={!this.isNewEmployeePasswordValid}
                                    id="outlined-password"
                                    label="Password"
                                    className={classes.textField}
                                    value={newEmployeePassword}
                                    onChange={this.handleNewEmployeePasswordChange}
                                    margin="normal"
                                    variant="outlined"
                                    type="password"
                                />
                            </DialogContent>
                            <DialogActions>
                                <StyledButton onClick={this.handleCloseNewEmployeeDialog}>
                                    Cancel
                                </StyledButton>
                                <StyledButton
                                    onClick={this.handleCreateNewEmployee}
                                    disabled={this.isNewEmployeeCreateButtonDisabled}
                                    color="primary"
                                    variant="contained"
                                    autoFocus
                                >
                                    Create
                                </StyledButton>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={deleteUserDialogOpen}
                            keepMounted
                            onClose={this.handleCloseDeleteUserDialog}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            BackdropProps={{className: classes.backdrop}}
                        >
                            <DialogTitle id="alert-dialog-slide-title">
                                {"Are you sure you want to delete this user?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseDeleteUserDialog} color="primary">
                                    Disagree
                                </Button>
                                <Button onClick={this.handleDeleteUser} color="primary">
                                    Agree
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

Employees.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(Employees));
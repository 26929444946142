import React from "react";

import { withStyles } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import BasicProperty from "components/BasicProperty";

const styles = theme => ({
  table: {
    // border: "1px solid grey"
  },
  title: {
    flex: "1 1 100%"
  }
});

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };

    this.lang = "en_GB";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  onChange(obj) {
    this.props.onChange && this.props.onChange(obj);
  }

  onError(obj) {
    const { name, error } = obj;
    this.props.onError && this.props.onError({ name, error });
  }

  render() {
    const { classes, title, template, lang } = this.props;
    return (
      <Paper>
        <MuiTable size={this.props.size}>
          <MuiTableHead>
            <MuiTableRow>
              <MuiTableCell colSpan={2}>
                <Typography className={classes.title} variant="h6">
                  {title}
                </Typography>
              </MuiTableCell>
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            {template.map((el, i) => {
              const forbiddenAccess =
                el.forbiddenAccess &&
                el.forbiddenAccess.includes(this.props.userLevel);
              let value = this.state.data ? this.state.data[el.name] : null;
              return (
                <MuiTableRow key={`body-${i}`}>
                  <MuiTableCell>{el.label[lang]}</MuiTableCell>
                  <MuiTableCell>
                    <BasicProperty
                      value={value}
                      callback={this.onChange.bind(this)}
                      onError={this.onError.bind(this)}
                      template={el}
                      lang={lang}
                      disabled={forbiddenAccess}
                    />
                  </MuiTableCell>
                </MuiTableRow>
              );
            })}
          </MuiTableBody>
        </MuiTable>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Table);

import React from "react";

import { db } from "common/firebase";
import BasicProperty from "components/BasicProperty";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  odd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  even: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f8f0"
  },
  content: {
    minWidth: "300px",
    minHeight: "400px",
    justifyContent: "center",
    alignContent: "center",
    margin: "auto"
  },
  search: {
    height: "50px",
    width: "50px"
  },
  list: {
    minWidth: "300px",
    minHeight: "400px"
  },
  listItemText: {
    pointerEvents: "none",
    width: "50%",
    marginLeft: theme.spacing(2)
  },
  divider: {
    position: "absolute",
    height: "100%"
  },
  dialogTitle: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: 0
  },
  dialogTitleContainer: {
    justifyContent: "space-around"
  },
  dialogActions: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: 0
  }
});

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function cleanSearchString(string) {
  return string.replace(/\s/g, "").toLowerCase();
}

const relevantColumns = ["search_string"];

class SearchFood extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderBy: "search_string",
      orderDirection: "asc",
      currentPage: 0,
      lastVisible: [],
      numberOfRows: 100,
      data: [],
      fetchingFoodsData: false,
      searchText: this.props.value ? this.props.value : "",
      dropDown: false,
      value: "",
      label: ""
    };
  }

  componentDidMount() {
    this.setState({
      lastVisible: Object.values(this.state.data)[this.state.numberOfRows - 1]
    });
  }

  handleListItemClick(e) {
    const { id } = e.target;
    this.props.callback(this.state.data[id]);
    this.setState({
      dropDown: false,
      searchText: ""
    });
  }

  searchChange(obj) {
    // eslint-disable-next-line
    const { property, value } = obj;
    this.handleSearchChange(value);
  }

  /**
   *
   * @param searchText string
   * @returns {Promise<void>}
   */
  handleSearchChange = async searchText => {
    if (searchText === "") {
      // await this.reloadTable();
      return;
    }

    if (searchText.length === 2 && this.state.searchText.length === 3) {
      await this.setState({
        searchText: ""
      });
      // await this.reloadTable();
      return;
    }

    // do nothing
    if (searchText.length < 3) {
      return;
    }

    await this.setState({
      currentPage: 0,
      lastVisible: [],
      searchText: searchText
    });

    await this.reloadTableWithFullResults();
  };

  /**
   * for search and filter
   * @returns {Promise<void>}
   */
  async reloadTableWithFullResults() {
    const oldSearchText = this.state.searchText;

    // let time = performance.now();

    await this.setState({
      fetchingFoodsData: true,
      data: []
    });

    await asyncForEach(relevantColumns, async columnName => {
      // console.log(cleanSearchString(this.state.searchText));
      await db
        .collection("recipes")
        .orderBy(columnName)
        .startAt(cleanSearchString(this.state.searchText))
        .endAt(cleanSearchString(this.state.searchText) + "\uf8ff")
        // .limit(this.state.numberOfRows)
        .get()
        .then(async result => {
          // do NOT set data if this function trigger was NOT the last input char
          if (oldSearchText !== this.state.searchText) {
            return;
          }

          // console.log("result", result);
          const data = [];
          Object.values(result.docs).forEach(doc => {
            const docData = doc.data();
            data.push({
              docId: doc.id,
              title: { en_GB: docData.title.en_GB, de_DE: docData.title.de_DE }
            });
          });

          // console.log(this.state.data, data);
          await this.setState({
            data: [...this.state.data, ...data]
          });
        });
    });

    // console.log("total time", (performance.now() - time) / 1000);
    this.setState({
      fetchingFoodsData: false
    });
  }

  openDropDown() {
    this.setState({ dropDown: true });
    // this.handleSearchChange(this.state.);
  }

  closeDropDown() {
    this.setState({ dropDown: false });
  }

  render() {
    const { classes, template, lang, disabled } = this.props;
    return (
      <React.Fragment>
        <BasicProperty
          key={`recipe-search-${this.props.i}-${this.props.id}`}
          template={{ ...template, type: "textField" }}
          error={this.props.error}
          lang={lang}
          value={this.state.searchText}
          callback={this.searchChange.bind(this)}
          disabled={disabled}
        />
        {disabled ? null : <IconButton
          onClick={this.openDropDown.bind(this)}
          className={classes.search}
        >
          <SearchIcon />
        </IconButton>}
        <Dialog
          onClose={this.closeDropDown.bind(this)}
          onBackdropClick={this.closeDropDown.bind(this)}
          open={this.state.dropDown}
          className={classes.dialog}
        >
          <DialogActions className={classes.dialogActions}>
            <IconButton onClick={this.closeDropDown.bind(this)} color="primary">
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogTitle className={classes.dialogTitle}>
            <Grid container className={classes.dialogTitleContainer}>
              <Typography>EN</Typography>
              <Typography>DE</Typography>
            </Grid>
          </DialogTitle>

          {!this.state.fetchingFoodsData && this.state.data.length === 0 ? (
            <DialogContent className={classes.list}>
              <Typography>No match</Typography>
            </DialogContent>
          ) : (
            <List className={classes.list}>
              {this.state.data.map((el, i) => {
                return (
                  <ListItem
                    className={i % 2 ? classes.odd : classes.even}
                    button
                    key={`ing-list-${i}`}
                    id={i}
                    onClick={this.handleListItemClick.bind(this)}
                  >
                    <ListItemText
                      className={classes.listItemText}
                      primary={`${el.title.en_GB ? el.title.en_GB : ""}`}
                    />
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />
                    <ListItemText
                      className={classes.listItemText}
                      primary={`${el.title.de_DE ? el.title.de_DE : ""}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          )}

          {this.state.fetchingFoodsData ? (
            <Grid container className={classes.content}>
              <CircularProgress className={classes.progress} />
            </Grid>
          ) : null}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SearchFood);

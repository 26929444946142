import React from "react";
import { connect } from "react-redux";

import WrappedFoodEdit from "./WrappedFoodEdit";

import { dataTemplate } from "data/ingredients";
import DataTablePage from "components/DataTablePage";

import * as masterdata from "store/selectors/masterdata";

const stateToProps = state => ({
  count: masterdata.getUserFoodsCount(state)
});

const actionsToProps = dispatch => ({});

class UserGeneratedFood extends React.Component {
  render() {
    return (
      <DataTablePage
        addDisabled={true}
        collection="userFoods"
        count={this.props.count}
        dataTemplate={dataTemplate}
        edit={WrappedFoodEdit}
        tableTitle="User generated food"
        editTooltip="Edit this food"
        acceptTooltip="Accept this food"
        discardTooltip="Discard this food"
        orderBy="id_db"
      />
    );
  }
}

export default connect(stateToProps, actionsToProps)(UserGeneratedFood);

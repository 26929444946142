/* eslint no-useless-escape: 0 */
//TODO: when changing schema, make sure update formulas (prop "internal") in the FoodEdit component!!
// Current (internal)formula count: 5

const sortedSchema = [
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "id_db",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Datenbank-Nr",
            "en": ""
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "orig_id_db",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Ursprungs-Datenbank Nr",
            "en": ""
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": "SBLS",
            "rewe": "pid"
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "level_vis",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Level",
            "en": "Level"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "id_ean",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "EAN",
            "en": "EAN"
        },
        "sort(overview)": 10,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": "gtin"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "cat_1",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kategorie 1",
            "en": "Category 1"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "cat_2",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kategorie 2",
            "en": "Category 2"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "cat_3",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kategorie 3",
            "en": "Category 3"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "brand_def",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Marke",
            "en": "Brand"
        },
        "sort(overview)": 12,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": "brandKey"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "product_name_DE",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Produkt name DE",
            "en": "Product name DE"
        },
        "sort(overview)": 12,
        "supplement": 0,
        "transformation": {
            "bls": "ST",
            "rewe": "cleansedName"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "product_name_EN",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Product name EN",
            "en": "Product name EN"
        },
        "sort(overview)": 12,
        "supplement": 0,
        "transformation": {
            "bls": "STE",
            "rewe": null
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "orig_product_name",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Ursprünglicher Produktname",
            "en": "-"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": "ST",
            "rewe": "productName"
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "link_de",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Link DE",
            "en": "Link DE"
        },
        "sort(overview)": 12,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "link_en",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Link EN",
            "en": "Link EN"
        },
        "sort(overview)": 12,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "ingredients_list",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Zutaten",
            "en": "Ingredients"
        },
        "sort(overview)": 16,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": "ingredientStatement"
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "baseUnit",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Basiseinheit",
            "en": "Base Unit"
        },
        "sort(overview)": 13,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": "servingType"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "baseUnitConversion",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Umrechnungsfaktor",
            "en": "Conversion factor"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": "€"
        },
        "evalOrder": null,
        "id": "price_per_100g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Preis [€] pro 100 g",
            "en": "-"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": "pricePer100g"
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": null
        },
        "evalOrder": null,
        "id": "servingSizes",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Serving Sizes",
            "en": "Serving Sizes"
        },
        "sort(overview)": 17,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": null,
            "default": null,
            "increment": null,
            "max": null,
            "smallIncrement": null,
            "dimension": "kcal"
        },
        "evalOrder": 2,
        "id": "control_energy_kcal",
        "internal": "(((protein_g)*17+(fat_g)*37+(carbs_net_g)*17+(fiber_g)*8)/4.1868)",
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Energie (NEU berechnet)",
            "en": "-"
        },
        "sort(overview)": 0,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 0
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 180,
            "increment": 1,
            "dimension": "kcal",
            "max": 900,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "energy_kcal",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Energie (kcal)",
            "en": "Energy (kilocalorie)"
        },
        "sort(overview)": 1100,
        "supplement": 0,
        "transformation": {
            "bls": "GJZB",
            "rewe": "nv_ener_e14"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 800,
            "increment": 10,
            "dimension": "kJ",
            "max": 3800,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "energy_kj",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Energie (KJ)",
            "en": "Energy (kilojoule)"
        },
        "sort(overview)": 1101,
        "supplement": 0,
        "transformation": {
            "bls": "GCALZB",
            "rewe": "nv_ener_kjo"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fat_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Fett",
            "en": "Fat"
        },
        "sort(overview)": 1200,
        "supplement": 0,
        "transformation": {
            "bls": "ZF/1000",
            "rewe": "nv_fat_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fat_sat_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids (Total)"
        },
        "sort(overview)": 1201,
        "supplement": 0,
        "transformation": {
            "bls": "FS/1000",
            "rewe": "nv_fasat_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 140,
            "increment": 10,
            "dimension": "mg",
            "max": 2700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_f40_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Buttersäure",
            "en": "Butyric Acid"
        },
        "sort(overview)": 1202,
        "supplement": 0,
        "transformation": {
            "bls": "F40",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 70,
            "increment": 10,
            "dimension": "mg",
            "max": 1800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_f60_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Capronsäure",
            "en": "Capoic Acid"
        },
        "sort(overview)": 1203,
        "supplement": 0,
        "transformation": {
            "bls": "F60",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 60,
            "increment": 10,
            "dimension": "mg",
            "max": 5900,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_80_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Caprylsäure",
            "en": "Caprylic Acid"
        },
        "sort(overview)": 1204,
        "supplement": 0,
        "transformation": {
            "bls": "F80",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 80,
            "increment": 10,
            "dimension": "mg",
            "max": 4200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_100_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Caprinsäure",
            "en": "Capric Acid"
        },
        "sort(overview)": 1205,
        "supplement": 0,
        "transformation": {
            "bls": "F100",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 60,
            "increment": 10,
            "dimension": "mg",
            "max": 6000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_120_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Laurinsäure",
            "en": "Lauric Acid"
        },
        "sort(overview)": 1206,
        "supplement": 0,
        "transformation": {
            "bls": "F120",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 170,
            "increment": 10,
            "dimension": "mg",
            "max": 5700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_140_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Myristinsäure",
            "en": "Myristic Acid"
        },
        "sort(overview)": 1207,
        "supplement": 0,
        "transformation": {
            "bls": "F140",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 10,
            "dimension": "mg",
            "max": 1100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_150_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Pentadecansäure",
            "en": "Pentadecanoic Acid"
        },
        "sort(overview)": 1208,
        "supplement": 0,
        "transformation": {
            "bls": "F150",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1000,
            "increment": 10,
            "dimension": "mg",
            "max": 11800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_160_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Palmitinsäure",
            "en": "Palmitic Acid"
        },
        "sort(overview)": 1209,
        "supplement": 0,
        "transformation": {
            "bls": "F160",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 1300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_170_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Margarinsäure",
            "en": "Margaric Acid"
        },
        "sort(overview)": 1210,
        "supplement": 0,
        "transformation": {
            "bls": "F170",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 370,
            "increment": 10,
            "dimension": "mg",
            "max": 9800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_180_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Stearinsäure",
            "en": "Stearic Acid"
        },
        "sort(overview)": 1211,
        "supplement": 0,
        "transformation": {
            "bls": "F180",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 10,
            "dimension": "mg",
            "max": 2400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_200_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Arachinsäure",
            "en": "Arachidic Acid"
        },
        "sort(overview)": 1212,
        "supplement": 0,
        "transformation": {
            "bls": "F200",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 10,
            "dimension": "mg",
            "max": 1200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_220_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Behensäure",
            "en": "Behenic Acid"
        },
        "sort(overview)": 1213,
        "supplement": 0,
        "transformation": {
            "bls": "F220",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Gesättigte Fettsäuren",
            "en": "Saturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 10,
            "dimension": "mg",
            "max": 1400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_240_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Lignocerinsäure",
            "en": "Lignoceric Acid"
        },
        "sort(overview)": 1214,
        "supplement": 0,
        "transformation": {
            "bls": "F240",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fat_monosat_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "sort(overview)": 1215,
        "supplement": 0,
        "transformation": {
            "bls": "FU/1000",
            "rewe": "nv_famscis_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 40,
            "increment": 10,
            "dimension": "mg",
            "max": 1300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_141_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Tetradecensäure",
            "en": "Tetradecanoic Acid"
        },
        "sort(overview)": 1216,
        "supplement": 0,
        "transformation": {
            "bls": "F141",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_151_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Pentadecensäure",
            "en": "Pentadecenoic Acid"
        },
        "sort(overview)": 1217,
        "supplement": 0,
        "transformation": {
            "bls": "F151",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 110,
            "increment": 10,
            "dimension": "mg",
            "max": 5200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_161_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Palmitoleinsäure",
            "en": "Palmitoleic Acid"
        },
        "sort(overview)": 1218,
        "supplement": 0,
        "transformation": {
            "bls": "F161",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 1000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_171_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Heptadecensäure",
            "en": "Heptadecenic Acid"
        },
        "sort(overview)": 1219,
        "supplement": 0,
        "transformation": {
            "bls": "F171",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1400,
            "increment": 10,
            "dimension": "mg",
            "max": 77600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_181_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Ölsäure",
            "en": "Oleic Acid"
        },
        "sort(overview)": 1220,
        "supplement": 0,
        "transformation": {
            "bls": "F181",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 16000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_201_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Eicosensäure",
            "en": "Eicosenoic Acid"
        },
        "sort(overview)": 1221,
        "supplement": 0,
        "transformation": {
            "bls": "F201",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 10,
            "dimension": "mg",
            "max": 18300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_221_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Erucasäure",
            "en": "Erucic Acid"
        },
        "sort(overview)": 1222,
        "supplement": 0,
        "transformation": {
            "bls": "F221",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Einfach ungesättigte Fettsäuren",
            "en": "Mono Unsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4,
            "increment": 10,
            "dimension": "mg",
            "max": 600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_241_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Nervonsäure",
            "en": "Nervonic Acid"
        },
        "sort(overview)": 1223,
        "supplement": 0,
        "transformation": {
            "bls": "F241",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fat_polyunsat_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids (Total)"
        },
        "sort(overview)": 1224,
        "supplement": 0,
        "transformation": {
            "bls": "FP/1000",
            "rewe": "nv_fapucis_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "mg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_162_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Hexadecadiensäure",
            "en": "Hexadecadienoic Acid"
        },
        "sort(overview)": 1225,
        "supplement": 0,
        "transformation": {
            "bls": "F162",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "mg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_164_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Hexadecatetraensäure",
            "en": "Hexadecatetraenoic Acid"
        },
        "sort(overview)": 1226,
        "supplement": 0,
        "transformation": {
            "bls": "F164",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 390,
            "increment": 10,
            "dimension": "mg",
            "max": 75200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_182_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Linolsäure",
            "en": "Linoleic Acid"
        },
        "sort(overview)": 1227,
        "supplement": 0,
        "transformation": {
            "bls": "F182",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 70,
            "increment": 10,
            "dimension": "mg",
            "max": 52800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_183_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Linolensäure (ALA)",
            "en": "Linolenic Acid (ALA)"
        },
        "sort(overview)": 1228,
        "supplement": 1,
        "transformation": {
            "bls": "F183",
            "rewe": "nv_f18d3n3_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 10,
            "dimension": "mg",
            "max": 3300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_184_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Stearidonsäure",
            "en": "Stearidonic Acid"
        },
        "sort(overview)": 1229,
        "supplement": 0,
        "transformation": {
            "bls": "F184",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 10,
            "dimension": "mg",
            "max": 800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_193_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Nonadecatriensäure",
            "en": "Nonadecatrienoic acid"
        },
        "sort(overview)": 1230,
        "supplement": 0,
        "transformation": {
            "bls": "F193",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "mg",
            "max": 400,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_202_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Eicosadiensäure",
            "en": "Eicosadienoic Acid"
        },
        "sort(overview)": 1231,
        "supplement": 0,
        "transformation": {
            "bls": "F202",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 10,
            "dimension": "mg",
            "max": 4600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_203_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Eicosatriensäure",
            "en": "Eicosatrienoic Acid"
        },
        "sort(overview)": 1232,
        "supplement": 0,
        "transformation": {
            "bls": "F203",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 5100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_204_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Arachidonsäure",
            "en": "Arachidonic Acid"
        },
        "sort(overview)": 1233,
        "supplement": 0,
        "transformation": {
            "bls": "F204",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 10,
            "dimension": "mg",
            "max": 8600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_205_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Eicosapentaensäure (EPA)",
            "en": "Eicosapentaenoic Acid (EPA)"
        },
        "sort(overview)": 1234,
        "supplement": 1,
        "transformation": {
            "bls": "F205",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 7,
            "increment": 1,
            "dimension": "mg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_222_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Docosadiensäure",
            "en": "Docosadienoic Acid"
        },
        "sort(overview)": 1235,
        "supplement": 0,
        "transformation": {
            "bls": "F222",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "mg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_223_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Docosatriensäure",
            "en": "Docosatrienoic Acid"
        },
        "sort(overview)": 1236,
        "supplement": 0,
        "transformation": {
            "bls": "F223",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "mg",
            "max": 400,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_224_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Docosatetraensäure",
            "en": "Docosatetraenoic Acid"
        },
        "sort(overview)": 1237,
        "supplement": 0,
        "transformation": {
            "bls": "F224",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 10,
            "dimension": "mg",
            "max": 4800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_225_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Docosapentaensäure",
            "en": "Docosapentaoic Acid"
        },
        "sort(overview)": 1238,
        "supplement": 0,
        "transformation": {
            "bls": "F225",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": "Mehrfach ungesättigte Fettsäuren",
            "en": "Polyunsaturated Fatty Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 40,
            "increment": 10,
            "dimension": "mg",
            "max": 12700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_226_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Docosahexaensäure (DHA)",
            "en": "Docosahexaenoic Acid (DHA)"
        },
        "sort(overview)": 1239,
        "supplement": 1,
        "transformation": {
            "bls": "F226",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_shortch_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kurzkettige Fettsäuren",
            "en": "Shortchain Fatty Acids"
        },
        "sort(overview)": 1240,
        "supplement": 0,
        "transformation": {
            "bls": "FK/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_mediumch_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Mittelkettige Fettsäuren",
            "en": "Mediumchain Fatty Acids"
        },
        "sort(overview)": 1241,
        "supplement": 0,
        "transformation": {
            "bls": "FM/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 200,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_longch_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Langkettige Fettsäuren",
            "en": "Longchain Fatty Acids"
        },
        "sort(overview)": 1242,
        "supplement": 0,
        "transformation": {
            "bls": "FL/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 9,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_o3_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Omega-3-Fettsäuren",
            "en": "Omega-3 Fatty Acids"
        },
        "sort(overview)": 1243,
        "supplement": 1,
        "transformation": {
            "bls": "FO3/1000",
            "rewe": "nv_fapun3_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fa_o6_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Omega-6-Fettsäuren",
            "en": "Omega-6 Fatty Acids"
        },
        "sort(overview)": 1244,
        "supplement": 1,
        "transformation": {
            "bls": "FO6/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 3,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "glycerin_lipoid_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glycerin und Lipoide",
            "en": "Glycerol and Lipoids"
        },
        "sort(overview)": 1245,
        "supplement": 0,
        "transformation": {
            "bls": "FG/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Fett",
            "en": "Fat"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 50,
            "increment": 10,
            "dimension": "mg",
            "max": 2400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "cholesterin_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Cholesterin",
            "en": "Cholesterol"
        },
        "sort(overview)": 1246,
        "supplement": 0,
        "transformation": {
            "bls": "FC",
            "rewe": "nv_cholc_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "min": 0,
            "default": 20,
            "increment": 1,
            "max": 100,
            "smallIncrement": 0.1,
            "dimension": "g"
        },
        "evalOrder": null,
        "id": "carbs_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Kohlenhydrate gesamt",
            "en": "Total carbs"
        },
        "sort(overview)": 1350,
        "supplement": 0,
        "transformation": {
            "bls": "ZK/1000",
            "rewe": "nv_choavl_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sugar_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Zucker (gesamt)",
            "en": "Sugar (Total)"
        },
        "sort(overview)": 1351,
        "supplement": 0,
        "transformation": {
            "bls": "KMD/1000",
            "rewe": "nv_sugar_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Monosaccharide",
            "en": "Monosaccharides"
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_monosac_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Monosaccharide",
            "en": "Monosaccharides"
        },
        "sort(overview)": 1352,
        "supplement": 0,
        "transformation": {
            "bls": "KM/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Monosaccharide",
            "en": "Monosaccharides"
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_gluc_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glucose (Traubenzucker)",
            "en": "Glucose"
        },
        "sort(overview)": 1353,
        "supplement": 0,
        "transformation": {
            "bls": "KMT/1000",
            "rewe": "nv_glus_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Monosaccharide",
            "en": "Monosaccharides"
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_fruc_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Fructose (Fruchtzucker)",
            "en": "Fructose"
        },
        "sort(overview)": 1354,
        "supplement": 0,
        "transformation": {
            "bls": "KMF/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Monosaccharide",
            "en": "Monosaccharides"
        },
        "constraints": {
            "default": 3,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_gal_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Galactose (Schleimzucker)",
            "en": "Galactose"
        },
        "sort(overview)": 1355,
        "supplement": 0,
        "transformation": {
            "bls": "KMG/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Disaccharide",
            "en": "Disaccharides"
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_disac_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Disaccharide",
            "en": "Disaccharides"
        },
        "sort(overview)": 1360,
        "supplement": 0,
        "transformation": {
            "bls": "KD/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Disaccharide",
            "en": "Disaccharides"
        },
        "constraints": {
            "default": 8,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_sacch_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Saccharose (Rübenzucker)",
            "en": "Saccharose"
        },
        "sort(overview)": 1361,
        "supplement": 0,
        "transformation": {
            "bls": "KDS/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Disaccharide",
            "en": "Disaccharides"
        },
        "constraints": {
            "default": 7,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_malt_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Maltose",
            "en": "Maltose"
        },
        "sort(overview)": 1362,
        "supplement": 0,
        "transformation": {
            "bls": "KDM/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zucker",
            "en": "Sugar"
        },
        "category3": {
            "de": "Disaccharide",
            "en": "Disaccharides"
        },
        "constraints": {
            "default": 7,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sug_lac_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Laktose",
            "en": "Lactose"
        },
        "sort(overview)": 1363,
        "supplement": 0,
        "transformation": {
            "bls": "KDL/1000",
            "rewe": "nv_lacs_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_total_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Zuckeralkohole (Gesamt)",
            "en": "Sugar Alcohols (Total)"
        },
        "sort(overview)": 1370,
        "supplement": 0,
        "transformation": {
            "bls": "KA/1000",
            "rewe": "nv_polyl_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_ery_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Erythritol",
            "en": "Erythritol"
        },
        "sort(overview)": 1371,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_malt_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Maltitol",
            "en": "Maltitol"
        },
        "sort(overview)": 1372,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_man_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Mannitol",
            "en": "Mannitol"
        },
        "sort(overview)": 1373,
        "supplement": 0,
        "transformation": {
            "bls": "KAM/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_sorb_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Sorbitol",
            "en": "Sorbitol"
        },
        "sort(overview)": 1374,
        "supplement": 0,
        "transformation": {
            "bls": "KAS/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Zuckeralkohole",
            "en": "Sugar Alcohols"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sa_xylit_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Xylitol",
            "en": "Xylitol"
        },
        "sort(overview)": 1375,
        "supplement": 0,
        "transformation": {
            "bls": "KAX/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 8,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "oligosac_res_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Oligosaccharide, resorbierbar",
            "en": "Oligosaccharide, digestible"
        },
        "sort(overview)": 1380,
        "supplement": 0,
        "transformation": {
            "bls": "KPOR/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 9,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "oligosac_nonres_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Oligosaccharide, nicht resorbierbar",
            "en": "Oligosaccharide, non-digestible"
        },
        "sort(overview)": 1381,
        "supplement": 0,
        "transformation": {
            "bls": "KPON/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Polysaccharide",
            "en": "Polysaccharide"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "polysach_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Polysaccharide",
            "en": "Polysaccharide"
        },
        "sort(overview)": 1390,
        "supplement": 0,
        "transformation": {
            "bls": "KP/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Polysaccharide",
            "en": "Polysaccharide"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "starch_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Stärke",
            "en": "Starch"
        },
        "sort(overview)": 1391,
        "supplement": 0,
        "transformation": {
            "bls": "KPS/1000",
            "rewe": "nv_starch_g"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": "Polysaccharide",
            "en": "Polysaccharide"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "glycog_an_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glykogen",
            "en": "Glycogen"
        },
        "sort(overview)": 1392,
        "supplement": 0,
        "transformation": {
            "bls": "KPG/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Kohlenhydrate",
            "en": "Carbohydrates"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 50,
            "increment": 10,
            "dimension": "mg",
            "max": 2400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "inositol_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Inositol",
            "en": "Inositol"
        },
        "sort(overview)": 1395,
        "supplement": 1,
        "transformation": {
            "bls": null,
            "rewe": "nv_inotl_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": 1,
        "id": "carbs_net_g",
        "internal": "((carbs_total_g)-(sa_total_g))",
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kohlenhydrate verwertbar",
            "en": "Net carbs"
        },
        "sort(overview)": 1399,
        "supplement": 0,
        "transformation": {
            "bls": "(ZK-KA)/1000",
            "rewe": "(nv_choavl_g)-(nv_polyl_g)"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fiber_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "sort(overview)": 1400,
        "supplement": 0,
        "transformation": {
            "bls": "ZB/1000",
            "rewe": "nv_fibtg_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 50,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fiber_sol_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Wasserlösliche Ballaststoffe",
            "en": "Soluble Fiber"
        },
        "sort(overview)": 1401,
        "supplement": 0,
        "transformation": {
            "bls": "KBW/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 50,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "fiber_nonsol_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Wasserunlösliche Ballaststoffe",
            "en": "Insoluble Fiber"
        },
        "sort(overview)": 1402,
        "supplement": 0,
        "transformation": {
            "bls": "KBN/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "poly_pent_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Poly-Pentosen",
            "en": "Polypentose"
        },
        "sort(overview)": 1410,
        "supplement": 0,
        "transformation": {
            "bls": "KBP/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "poly_hex_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Poly-Hexosen",
            "en": "Polyhexose"
        },
        "sort(overview)": 1411,
        "supplement": 0,
        "transformation": {
            "bls": "KBH/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 40,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "poly_urac_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Poly-Uronsäure",
            "en": "Polyuronic Acids"
        },
        "sort(overview)": 1412,
        "supplement": 0,
        "transformation": {
            "bls": "KBU/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "cellulose_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Cellulose",
            "en": "Cellulose"
        },
        "sort(overview)": 1420,
        "supplement": 0,
        "transformation": {
            "bls": "KBC/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Ballaststoffe",
            "en": "Fiber"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "lignin_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Lignin",
            "en": "Lignin"
        },
        "sort(overview)": 1421,
        "supplement": 0,
        "transformation": {
            "bls": "KBL/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "protein_g",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "sort(overview)": 1500,
        "supplement": 0,
        "transformation": {
            "bls": "ZE/1000",
            "rewe": "nv_pro_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4300,
            "increment": 10,
            "dimension": "mg",
            "max": 12500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_ess_total_mg",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids (Total)"
        },
        "sort(overview)": 1501,
        "supplement": 0,
        "transformation": {
            "bls": "EEA",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 400,
            "increment": 10,
            "dimension": "mg",
            "max": 5500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_ile_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Isoleucin",
            "en": "Isoleucine"
        },
        "sort(overview)": 1502,
        "supplement": 1,
        "transformation": {
            "bls": "EILE",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 700,
            "increment": 10,
            "dimension": "mg",
            "max": 7700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_leu_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Leucin",
            "en": "Leucine"
        },
        "sort(overview)": 1503,
        "supplement": 1,
        "transformation": {
            "bls": "ELEU",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 600,
            "increment": 10,
            "dimension": "mg",
            "max": 7000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_lys_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Lysin",
            "en": "Lysine"
        },
        "sort(overview)": 1504,
        "supplement": 1,
        "transformation": {
            "bls": "ELYS",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 190,
            "increment": 10,
            "dimension": "mg",
            "max": 3300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_met_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Methionin",
            "en": "Methionine"
        },
        "sort(overview)": 1505,
        "supplement": 1,
        "transformation": {
            "bls": "EMET",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 110,
            "increment": 10,
            "dimension": "mg",
            "max": 3700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_cyc_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Cystein",
            "en": "Cysteine"
        },
        "sort(overview)": 1506,
        "supplement": 1,
        "transformation": {
            "bls": "ECYS",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 370,
            "increment": 10,
            "dimension": "mg",
            "max": 5300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_phe_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Phenylalanin",
            "en": "Phenylalanine"
        },
        "sort(overview)": 1507,
        "supplement": 1,
        "transformation": {
            "bls": "EPHE",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 290,
            "increment": 10,
            "dimension": "mg",
            "max": 3800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_tyr_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Tyrosin",
            "en": "Tyrosine"
        },
        "sort(overview)": 1508,
        "supplement": 1,
        "transformation": {
            "bls": "ETYR",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 350,
            "increment": 10,
            "dimension": "mg",
            "max": 4000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_thr_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Threonin",
            "en": "Threonine"
        },
        "sort(overview)": 1509,
        "supplement": 1,
        "transformation": {
            "bls": "ETHR",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 100,
            "increment": 10,
            "dimension": "mg",
            "max": 1600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_trp_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Tryptophan",
            "en": "Tryptophan"
        },
        "sort(overview)": 1510,
        "supplement": 1,
        "transformation": {
            "bls": "ETRP",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 460,
            "increment": 10,
            "dimension": "mg",
            "max": 6800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_val_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Valin",
            "en": "Valine"
        },
        "sort(overview)": 1511,
        "supplement": 1,
        "transformation": {
            "bls": "EVAL",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 480,
            "increment": 10,
            "dimension": "mg",
            "max": 7500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_arg_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Arginin",
            "en": "Arginine"
        },
        "sort(overview)": 1512,
        "supplement": 1,
        "transformation": {
            "bls": "EARG",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Essentielle Aminosäuren",
            "en": "Essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 220,
            "increment": 10,
            "dimension": "mg",
            "max": 2400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_his_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Histidin",
            "en": "Histidine"
        },
        "sort(overview)": 1513,
        "supplement": 1,
        "transformation": {
            "bls": "EHIS",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 4200,
            "increment": 10,
            "dimension": "mg",
            "max": 26500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_noness_total_mg",
        "internal": null,
        "isTotalCategoryValue": 1,
        "name": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids (Total)"
        },
        "sort(overview)": 1530,
        "supplement": 0,
        "transformation": {
            "bls": "ENA",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 440,
            "increment": 10,
            "dimension": "mg",
            "max": 5100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_ala_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Alanin",
            "en": "Alanine"
        },
        "sort(overview)": 1531,
        "supplement": 1,
        "transformation": {
            "bls": "EALA",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 800,
            "increment": 10,
            "dimension": "mg",
            "max": 5400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_asp_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Asparaginsäure",
            "en": "Aspartic Acid"
        },
        "sort(overview)": 1532,
        "supplement": 1,
        "transformation": {
            "bls": "EASP",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1600,
            "increment": 10,
            "dimension": "mg",
            "max": 6200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_gln_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glutaminsäure",
            "en": "Glutamic Acid"
        },
        "sort(overview)": 1533,
        "supplement": 1,
        "transformation": {
            "bls": "EGLU",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 370,
            "increment": 10,
            "dimension": "mg",
            "max": 3000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_gly_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glycin",
            "en": "Glycine"
        },
        "sort(overview)": 1534,
        "supplement": 1,
        "transformation": {
            "bls": "EGLY",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 500,
            "increment": 10,
            "dimension": "mg",
            "max": 3200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_pro_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Prolin",
            "en": "Proline"
        },
        "sort(overview)": 1535,
        "supplement": 1,
        "transformation": {
            "bls": "EPRO",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": "Nichtessentielle Aminosäuren",
            "en": "Non-essential Amino Acids"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 410,
            "increment": 10,
            "dimension": "mg",
            "max": 4300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "aa_ser_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Serin",
            "en": "Serine"
        },
        "sort(overview)": 1536,
        "supplement": 1,
        "transformation": {
            "bls": "ESER",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 50,
            "increment": 10,
            "dimension": "mg",
            "max": 1900,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "uric_acid_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Harnsäure",
            "en": "Uric Acid"
        },
        "sort(overview)": 1540,
        "supplement": 0,
        "transformation": {
            "bls": "EH",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Eiweiß (Protein)",
            "en": "Protein"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "mg",
            "max": 700,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "purin_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Purin",
            "en": "Purine"
        },
        "sort(overview)": 1541,
        "supplement": 0,
        "transformation": {
            "bls": "EP",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 6,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "salt_total_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Salz",
            "en": "Salt"
        },
        "sort(overview)": 1800,
        "supplement": 0,
        "transformation": {
            "bls": "GMKO/1000",
            "rewe": "nv_salteq_g"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": null,
            "en": null
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "alcohol_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Alkohol (Ethanol)",
            "en": "Alcohol (ethanol)"
        },
        "sort(overview)": 1810,
        "supplement": 0,
        "transformation": {
            "bls": "ZA/1000",
            "rewe": "nv_alcohol"
        },
        "visibility": 1
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 260,
            "increment": 10,
            "dimension": "µg",
            "max": 2900,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_a_req_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin A Retinoläquivalent",
            "en": "Vitamin A Retinol Equivalent"
        },
        "sort(overview)": 6100,
        "supplement": 1,
        "transformation": {
            "bls": "VA",
            "rewe": "nv_vita_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 290,
            "increment": 10,
            "dimension": "µg",
            "max": 2700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_a_r_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin A Retinol",
            "en": "Vitamin A Retinol"
        },
        "sort(overview)": 6101,
        "supplement": 1,
        "transformation": {
            "bls": "VAR",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 500,
            "increment": 10,
            "dimension": "µg",
            "max": 6200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_a_bcar_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin A Beta-Carotin",
            "en": "Vitamin A Beta-Carotene"
        },
        "sort(overview)": 6102,
        "supplement": 1,
        "transformation": {
            "bls": "VAC",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 2,
            "increment": 1,
            "dimension": "µg",
            "max": 400,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_d_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin D Calciferole",
            "en": "Vitamin D Calciferole"
        },
        "sort(overview)": 6103,
        "supplement": 1,
        "transformation": {
            "bls": "VD",
            "rewe": "nv_vitd_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 600,
            "increment": 10,
            "dimension": "µg",
            "max": 7600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_e_toceq_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin E Alpha-Tocopheroläquivalent",
            "en": "Vitamin E Alpha-Tocopherol Equivalent"
        },
        "sort(overview)": 6104,
        "supplement": 1,
        "transformation": {
            "bls": "VE",
            "rewe": "nv_vite_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 600,
            "increment": 10,
            "dimension": "µg",
            "max": 7600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_e_toc_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin E Alpha-Tocopherol",
            "en": "Vitamin E Alpha-Tocopherol"
        },
        "sort(overview)": 6105,
        "supplement": 1,
        "transformation": {
            "bls": "VEAT",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 1,
            "dimension": "µg",
            "max": 400,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_k_phyl_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin K Phyllochinon",
            "en": "Vitamin K Phyllochinone"
        },
        "sort(overview)": 6106,
        "supplement": 1,
        "transformation": {
            "bls": "VK",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 70,
            "increment": 1,
            "dimension": "µg",
            "max": 900,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b1_thia_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B1 Thiamin",
            "en": "Vitamin B1 Thiamin"
        },
        "sort(overview)": 6200,
        "supplement": 1,
        "transformation": {
            "bls": "VB1",
            "rewe": "nv_thia_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 170,
            "increment": 10,
            "dimension": "µg",
            "max": 1100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b2_ribo_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B2 Riboflavin",
            "en": "Vitamin B2 Riboflavin"
        },
        "sort(overview)": 6201,
        "supplement": 1,
        "transformation": {
            "bls": "VB2",
            "rewe": "nv_ribf_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1300,
            "increment": 10,
            "dimension": "µg",
            "max": 5600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b3_nia_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B3 Niacin, Nicotinsäure",
            "en": "Vitamin B3 Niacin, Nicotinic acid"
        },
        "sort(overview)": 6202,
        "supplement": 1,
        "transformation": {
            "bls": "VB3",
            "rewe": "nv_nia_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 3000,
            "increment": 10,
            "dimension": "µg",
            "max": 6700,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b3_niaeq_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B3 Niacinäquivalent",
            "en": "Vitamin B3 Niacin Equivalent"
        },
        "sort(overview)": 6203,
        "supplement": 1,
        "transformation": {
            "bls": "VB3A",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 380,
            "increment": 10,
            "dimension": "µg",
            "max": 3800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b5_pant_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B5 Pantothensäure",
            "en": "Vitamin B5 Pantothenic Acid"
        },
        "sort(overview)": 6204,
        "supplement": 1,
        "transformation": {
            "bls": "VB5",
            "rewe": "nv_pantac_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 120,
            "increment": 10,
            "dimension": "µg",
            "max": 900,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b6_pyri_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B6 Pyridoxin",
            "en": "Vitamin B6 Pyridoxine"
        },
        "sort(overview)": 6205,
        "supplement": 1,
        "transformation": {
            "bls": "VB6",
            "rewe": "nv_vitb6_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 6,
            "increment": 1,
            "dimension": "µg",
            "max": 200,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b7_biot_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B7 Biotin (Vitamin H)",
            "en": "Vitamin B7 Biotin (Vitamin H)"
        },
        "sort(overview)": 6206,
        "supplement": 1,
        "transformation": {
            "bls": "VB7",
            "rewe": "nv_biot_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 30,
            "increment": 1,
            "dimension": "µg",
            "max": 700,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b9_fol_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B9 gesamte Folsäure",
            "en": "Vitamin B9 Folate (total)"
        },
        "sort(overview)": 6207,
        "supplement": 1,
        "transformation": {
            "bls": "VB9G",
            "rewe": "nv_foldfe_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 3,
            "increment": 1,
            "dimension": "µg",
            "max": 200,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_b12_cobal_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin B12 Cobalamin",
            "en": "Vitamin B12 Cobalamin"
        },
        "sort(overview)": 6208,
        "supplement": 1,
        "transformation": {
            "bls": "VB12",
            "rewe": "nv_vitb12_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Vitamine",
            "en": "Vitamins"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 400,
            "increment": 10,
            "dimension": "mg",
            "max": 2000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "vit_C_asc_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Vitamin C Ascorbinsäure",
            "en": "Vitamin C Ascorbic Acid"
        },
        "sort(overview)": 6209,
        "supplement": 1,
        "transformation": {
            "bls": "VC/1000",
            "rewe": "nv_vitc_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 380,
            "increment": 10,
            "dimension": "mg",
            "max": 2500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_na_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Natrium",
            "en": "Sodium"
        },
        "sort(overview)": 6300,
        "supplement": 1,
        "transformation": {
            "bls": "MNA",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 210,
            "increment": 10,
            "dimension": "mg",
            "max": 3000,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_k_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kalium",
            "en": "Potassium"
        },
        "sort(overview)": 6301,
        "supplement": 1,
        "transformation": {
            "bls": "MK",
            "rewe": "nv_k_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 60,
            "increment": 10,
            "dimension": "mg",
            "max": 4200,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_ca_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Calcium",
            "en": "Calcium"
        },
        "sort(overview)": 6302,
        "supplement": 1,
        "transformation": {
            "bls": "MCA",
            "rewe": "nv_ca_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 20,
            "increment": 10,
            "dimension": "mg",
            "max": 2100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_mg_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Magnesium",
            "en": "Magnesium"
        },
        "sort(overview)": 6303,
        "supplement": 1,
        "transformation": {
            "bls": "MMG",
            "rewe": "nv_mg_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 140,
            "increment": 10,
            "dimension": "mg",
            "max": 1100,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_p_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Phosphor",
            "en": "Phosphorus"
        },
        "sort(overview)": 6304,
        "supplement": 1,
        "transformation": {
            "bls": "MP",
            "rewe": "nv_p_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 90,
            "increment": 1,
            "dimension": "mg",
            "max": 600,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_s_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Schwefel",
            "en": "Sulfur"
        },
        "sort(overview)": 6305,
        "supplement": 1,
        "transformation": {
            "bls": "MS",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Mineralstoffe",
            "en": "Minerals"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 210,
            "increment": 10,
            "dimension": "mg",
            "max": 3500,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_cl_mg",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Chlorid",
            "en": "Chloride"
        },
        "sort(overview)": 6306,
        "supplement": 1,
        "transformation": {
            "bls": "MCL",
            "rewe": "nv_cld_mg"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1100,
            "increment": 10,
            "dimension": "µg",
            "max": 8300,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_fe_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Eisen",
            "en": "Iron"
        },
        "sort(overview)": 6400,
        "supplement": 1,
        "transformation": {
            "bls": "MFE",
            "rewe": "nv_fe_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 1000,
            "increment": 10,
            "dimension": "µg",
            "max": 6600,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_zn_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Zink",
            "en": "Zinc"
        },
        "sort(overview)": 6401,
        "supplement": 1,
        "transformation": {
            "bls": "MZN",
            "rewe": "nv_zn_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 100,
            "increment": 10,
            "dimension": "µg",
            "max": 1400,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_cu_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kupfer",
            "en": "Copper"
        },
        "sort(overview)": 6402,
        "supplement": 1,
        "transformation": {
            "bls": "MCU",
            "rewe": "nv_cu_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 130,
            "increment": 10,
            "dimension": "µg",
            "max": 800,
            "smallIncrement": 1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_mn_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Mangan",
            "en": "Manganese"
        },
        "sort(overview)": 6403,
        "supplement": 1,
        "transformation": {
            "bls": "MMN",
            "rewe": "nv_mn_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 40,
            "increment": 1,
            "dimension": "µg",
            "max": 500,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_f_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Fluorid",
            "en": "Fluoride"
        },
        "sort(overview)": 6404,
        "supplement": 1,
        "transformation": {
            "bls": "MF",
            "rewe": "nv_fd_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "µg",
            "max": 400,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_i_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Iodid",
            "en": "Iodine"
        },
        "sort(overview)": 6405,
        "supplement": 1,
        "transformation": {
            "bls": "MJ",
            "rewe": "nv_id_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "µg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_cr_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Chrom",
            "en": "Chromium"
        },
        "sort(overview)": 6406,
        "supplement": 1,
        "transformation": {
            "bls": null,
            "rewe": "nv_cr_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mikronährstoffe",
            "en": "Micronutrients"
        },
        "category2": {
            "de": "Spurenelemente",
            "en": "Trace Elements"
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 10,
            "increment": 1,
            "dimension": "µg",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "m_se_mc",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Selen",
            "en": "Selenium"
        },
        "sort(overview)": 6407,
        "supplement": 1,
        "transformation": {
            "bls": null,
            "rewe": "nv_se_mc"
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 60,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "water_g",
        "internal": "100 - (protein_g + carbs_total_g + fiber_g + fat_g)",
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Wasser",
            "en": "Water"
        },
        "sort(overview)": 10000,
        "supplement": 0,
        "transformation": {
            "bls": "ZW/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 3,
            "increment": 1,
            "dimension": null,
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": 4,
        "id": "ce_value",
        "internal": "((carbs_net_g)/10)",
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Kohlenhydrateinheiten KE",
            "en": "Carbohydrate exchange"
        },
        "sort(overview)": 10001,
        "supplement": 0,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "crude_ash_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Mineralstoffe (Rohasche)",
            "en": "Crude ashes"
        },
        "sort(overview)": 10002,
        "supplement": 0,
        "transformation": {
            "bls": "ZM/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "organic_acid_g",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Organische Säuren",
            "en": "Organic acids"
        },
        "sort(overview)": 10003,
        "supplement": 0,
        "transformation": {
            "bls": "ZO/1000",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 3,
            "increment": 1,
            "dimension": null,
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": 3,
        "id": "be_value",
        "internal": "((carbs_net_g)/12)",
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Broteinheiten BE",
            "en": "Carbohydrate (bread) exchange"
        },
        "sort(overview)": 10004,
        "supplement": 0,
        "transformation": {
            "bls": "GKB",
            "rewe": null
        },
        "visibility": 2
    },
    {
        "category1": {
            "de": "Mehr",
            "en": "More"
        },
        "category2": {
            "de": null,
            "en": null
        },
        "category3": {
            "de": null,
            "en": null
        },
        "constraints": {
            "default": 5,
            "increment": 1,
            "dimension": "g",
            "max": 100,
            "smallIncrement": 0.1,
            "min": 0
        },
        "evalOrder": null,
        "id": "sup_glutamine",
        "internal": null,
        "isTotalCategoryValue": 0,
        "name": {
            "de": "Glutamin",
            "en": "Glutamine"
        },
        "sort(overview)": 10010,
        "supplement": 1,
        "transformation": {
            "bls": null,
            "rewe": null
        },
        "visibility": 2
    },
];

export default sortedSchema;
// import adminTheme from "common/theme";

export default theme => ({
    // ...adminTheme,
    ...{
        container: {},
        panel_type_single: {
            background: 'darkgray',
        },
        panel_type_multiple: {
            // background: 'green',
        },
        workingOverlay: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'block',
            zIndex: 1000000,
            backgroundColor: '#00000012',
        },
    }
});

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as _ from "lodash";
import * as utils from "common/utils";
import * as constants from "common/constants";
import FoodOneProperty from "components/FoodOneProperty";
import FoodServingSizes from "components/FoodServingSizes";
import sortedSchema from "data/schema";
import { DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import { connect } from "react-redux";
import FoodOneEAN from "components/FoodOneEAN";
import FoodOneBaseUnit from "components/FoodOneBaseUnit";
import EditActions from "components/EditActions";
import UserEditActions from "components/UserEditActions";

const finalStructure = {};
const structureArray = [];

sortedSchema.map(oneProp => structureArray.push(oneProp));

//TODO: sort sub-categories
//TODO: sort LVL2 and LVL3
structureArray.sort(function(a, b) {
  return a["sort(overview)"] - b["sort(overview)"];
});

const calculations = {
  control_energy_kcal: foodData => {
    return (
      (parseFloat(foodData["protein_g"]) * 17 +
        parseFloat(foodData["fat_g"]) * 37 +
        parseFloat(foodData["carbs_net_g"]) * 17 +
        parseFloat(foodData["fiber_g"]) * 8) /
      4.1868
    );
  },
  carbs_net_g: foodData => {
    return (
      parseFloat(foodData["carbs_total_g"]) - parseFloat(foodData["sa_total_g"])
    );
  },
  water_g: foodData => {
    return (
      100 -
      (parseFloat(foodData["protein_g"]) +
        parseFloat(foodData["carbs_total_g"]) +
        parseFloat(foodData["fiber_g"]) +
        parseFloat(foodData["fat_g"]))
    );
  },
  ce_value: foodData => {
    return parseFloat(foodData["carbs_net_g"]) / 10;
  },
  be_value: foodData => {
    return parseFloat(foodData["carbs_net_g"]) / 12;
  }
};

class FoodEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      working: false,
      changes: {},
      errors: {},
      dialogConfirmSaveOpen: false,
      dialogConfirmResetOpen: false
    };
  }

  get isEditedPreviously() {
    if (this.props.shadowDoc === null) {
      return false;
    }
    return this.props.shadowDoc.exists;
  }

  get areAllPropertiesValid() {
    let isValid = true;

    Object.entries(this.state.errors).forEach(propErrors => {
      if (propErrors[1].length > 0) {
        isValid = false;
      }
    });

    if (this.state.changes.hasOwnProperty("servingSizes")) {
      this.state.changes["servingSizes"].forEach(servingSize => {
        if (servingSize["amount"] === 0) {
          isValid = false;
        }
        if (servingSize["en0"] === "") {
          isValid = false;
        }
        if (servingSize["enN"] === "") {
          isValid = false;
        }
        if (servingSize["de0"] === "") {
          isValid = false;
        }
        if (servingSize["deN"] === "") {
          isValid = false;
        }
      });
    }

    return isValid;
  }

  componentDidMount() {
    this.createListStructure();
    this.combineData();
    this.setState({ loading: false });
  }

  createListStructure = () => {
    // eslint-disable-next-line
    return structureArray.map(oneProp => {
      if (oneProp.visibility > 0) {
        if (oneProp.category1.en === null) {
          // insert here level 1 MAIN prop
          finalStructure[oneProp.name.en] = oneProp;
        } else {
          // create empty level 1 prop
          if (utils.isNullOrUndefined(finalStructure[oneProp.category1.en])) {
            finalStructure[oneProp.category1.en] = {
              sub: {}
            };
          }

          if (oneProp.category2.en === null) {
            // insert here level 2 MAIN prop
            if (oneProp.isTotalCategoryValue === 1) {
              finalStructure[oneProp.category1.en] = {
                ...finalStructure[oneProp.category1.en],
                ...oneProp
              };
            } else {
              // insert here level 2 one prop
              finalStructure[oneProp.category1.en].sub[
                oneProp.name.en
              ] = oneProp;
              //
            }
          } else {
            // insert level 2 prop
            if (
              utils.isNullOrUndefined(
                finalStructure[oneProp.category1.en].sub[oneProp.category2.en]
              )
            ) {
              finalStructure[oneProp.category1.en].sub[oneProp.category2.en] = {
                sub: {}
              };
            }
            // insert here level2 one MAIN prop with subcategories
            if (oneProp.isTotalCategoryValue === 1) {
              finalStructure[oneProp.category1.en].sub[oneProp.category2.en] = {
                ...finalStructure[oneProp.category1.en].sub[
                  oneProp.category2.en
                ],
                ...oneProp
              };
            } else {
              // insert here level3 one props
              finalStructure[oneProp.category1.en].sub[
                oneProp.category2.en
              ].sub[oneProp.name.en] = oneProp;
            }
          }
        }
      }
    });
  };

  createEmptyFoodData() {
    const food = {};
    sortedSchema.forEach(column => {
      food[column.id] = null;
    });
    food.id_db = `S${new Date().getTime()}`;
    food.id_ean = [];
    food.baseUnit = "g";
    return food;
  }

  combineData() {
    const { foodData, shadowData } = this.props;
    let combinedData = this.createEmptyFoodData();

    if (foodData) {
      // console.log(combinedData, foodData);
      combinedData = {
        ...combinedData,
        ...foodData
      };
    }

    if (shadowData) {
      // console.log(combinedData, shadowData);
      combinedData = {
        ...combinedData,
        ...shadowData
      };
    }

    // console.log("combinedData", combinedData);
    this.setState({ combinedData });
  }

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleDiscard = () => {
    this.props.handleDiscard && this.props.handleDiscard();
  };

  handleAccept = async () => {
    this.props.handleAccept &&
      this.props.handleAccept({
        shadowDoc: this.props.shadowDoc,
        shadowData: this.props.shadowData,
        changes: this.state.changes,
        id_db: this.state.combinedData.id_db,
        baseUnit: this.state.combinedData.baseUnit
      });
  };

  handleReset = async () => {
    this.setState({ loading: true });
    this.props.shadowDoc.ref.delete().then(async () => {
      this.handleCloseDialogConfirmReset();
      await this.props.handleChange();
      await this.setState({ loading: false });
    });
  };

  applyCalculations = async () => {
    const changes = this.state.changes;
    const startingFoodData = {
      ...this.state.combinedData,
      ...this.state.changes
    };
    let ironedFoodData = _.cloneDeep(startingFoodData);

    // calculate variables that have formula
    Object.entries(calculations).forEach(calculation => {
      const calculatedProp = calculation[1](startingFoodData);
      ironedFoodData[calculation[0]] = calculatedProp;
    });

    // check if each variable with formula is different from original
    // and if so, update changes
    let changed = false;
    Object.entries(calculations).forEach(calculation => {
      if (startingFoodData[calculation[0]] !== ironedFoodData[calculation[0]]) {
        changes[calculation[0]] = ironedFoodData[calculation[0]];
        changed = true;
      }
    });

    // set changes in state if any new change
    if (changed) {
      await this.setState({ changes: changes });
    }
  };

  handleSave = async () => {
    this.setState({ loading: true });

    this.applyCalculations();

    utils.handleSave(
      {
        shadowDoc: this.props.shadowDoc,
        shadowData: this.props.shadowData,
        changes: this.state.changes,
        id_db: this.state.combinedData.id_db,
        baseUnit: this.state.combinedData.baseUnit
      },
      async () => {
        this.handleCloseDialogConfirmSave();
        await this.setState({ loading: false }, () =>
          this.props.handleChange()
        );
      }
    );
  };

  handleUpdateProperty = (key, value) => {
    let val = _.toNumber(value);
    // intentional direct state mutation (performance-vise...)
    // eslint-disable-next-line
    this.state.changes[key] = isNaN(val) ? value : val;
  };

  handleUpdateErrors = (key, value) => {
    // intentional direct state mutation (performance-vise...)
    // eslint-disable-next-line
    this.state.errors[key] = value;
  };

  handleOpenDialogConfirmSave = () => {
    if (!this.areAllPropertiesValid) {
      let errorString = "Please correct the errors before continuing!\n";
      Object.entries(this.state.errors).forEach(error => {
        if (error[1].length > 0) {
          errorString += `\n[${error[0]}]: ${error[1]}`;
        }
      });
      alert(errorString);
    } else {
      this.setState({ dialogConfirmSaveOpen: true });
    }
  };

  handleCloseDialogConfirmSave = () => {
    this.setState({ dialogConfirmSaveOpen: false });
  };

  handleOpenDialogConfirmReset = () => {
    this.setState({ dialogConfirmResetOpen: true });
  };

  handleCloseDialogConfirmReset = () => {
    this.setState({ dialogConfirmResetOpen: false });
  };

  render() {
    const {
      classes,
      shadowData,
      foodData,
      user,
      userFoods,
      foodsEditDialogVariant
    } = this.props;

    const {
      loading,
      dialogConfirmSaveOpen,
      dialogConfirmResetOpen
    } = this.state;

    // if (loading) {
    //   return <Loader />;
    // }

    const userAccessLevel = user.additionalData.claims.accessLevel;

    return (
      <React.Fragment>
        <Dialog
          open={this.props.foodsEditDialogOpen}
          fullWidth={true}
          // fullScreen
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.combinedData
              ? foodsEditDialogVariant === "new"
                ? `Add New Food - ID: (${this.state.combinedData.id_db})`
                : `Edit Food - ID: (${this.state.combinedData.id_db})`
              : null}
          </DialogTitle>
          <DialogContent>
            {!this.state.combinedData ? (
              <Loader />
            ) : (
              <div className={classes.container}>
                {// eslint-disable-next-line
                Object.entries(finalStructure).map(lvl1Prop => {
                  if (utils.notNullOrUndefined(lvl1Prop[1].category1)) {
                    if (lvl1Prop[1].category1.en === null) {
                      // servingSizes override
                      if (lvl1Prop[1].id === "servingSizes") {
                        return (
                          <FoodServingSizes
                            accessLevel={userAccessLevel}
                            key={utils.uuidv4()}
                            propertyValue={
                              this.state.combinedData["servingSizes"]
                            }
                            updateProperty={this.handleUpdateProperty}
                            updateErrors={this.handleUpdateErrors}
                            shadow={
                              userFoods &&
                              shadowData &&
                              shadowData["servingSizes"]
                              // eslint-disable-next-line
                                ? shadowData["servingSizes"] !=
                                  foodData["servingSizes"]
                                : false
                            }
                          />
                        );
                        // EAN override
                      } else if (lvl1Prop[1].id === "id_ean") {
                        return (
                          <FoodOneEAN
                            accessLevel={userAccessLevel}
                            key={utils.uuidv4()}
                            propertyValue={this.state.combinedData["id_ean"]}
                            updateProperty={this.handleUpdateProperty}
                            updateErrors={this.handleUpdateErrors}
                            shadow={
                              userFoods && shadowData && shadowData["id_ean"]
                              // eslint-disable-next-line
                                ? shadowData["id_ean"] != foodData["id_ean"]
                                : false
                            }
                          />
                        );
                        // EAN override
                      } else if (lvl1Prop[1].id === "baseUnit") {
                        return (
                          <FoodOneBaseUnit
                            accessLevel={userAccessLevel}
                            key={utils.uuidv4()}
                            propertyValue={this.state.combinedData["baseUnit"]}
                            updateProperty={this.handleUpdateProperty}
                            updateErrors={this.handleUpdateErrors}
                            shadow={
                              userFoods && shadowData && shadowData["baseUnit"]
                              // eslint-disable-next-line
                                ? shadowData["baseUnit"] != foodData["baseUnit"]
                                : false
                            }
                          />
                        );
                      } else {
                        // level1 without subcategories
                        return (
                          <FoodOneProperty
                            accessLevel={userAccessLevel}
                            key={utils.uuidv4()}
                            variant={constants.ONE_FOOD_PANEL_VARIANT_SINGLE}
                            propertyName={lvl1Prop[1].name.en}
                            categoryData={lvl1Prop[1]}
                            foodData={this.state.combinedData}
                            propertyValue={
                              this.state.combinedData[lvl1Prop[1].id]
                            }
                            shadowsValue={
                              shadowData ? shadowData[lvl1Prop[1].id] : null
                            }
                            level={1}
                            updateProperty={this.handleUpdateProperty}
                            updateErrors={this.handleUpdateErrors}
                            shadow={
                              userFoods &&
                              shadowData &&
                              shadowData[lvl1Prop[1].id]
                              // eslint-disable-next-line
                                ? shadowData[lvl1Prop[1].id] !=
                                  foodData[lvl1Prop[1].id]
                                : false
                            }
                          />
                        );
                      }
                    } else {
                      return (
                        // level1 with subcategories
                        <FoodOneProperty
                          accessLevel={userAccessLevel}
                          key={utils.uuidv4()}
                          variant={constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE}
                          propertyName={lvl1Prop[1].category1.en}
                          categoryData={lvl1Prop[1]}
                          foodData={this.state.combinedData}
                          propertyValue={
                            this.state.combinedData[lvl1Prop[1].id]
                          }
                          level={1}
                          updateProperty={this.handleUpdateProperty}
                          updateErrors={this.handleUpdateErrors}
                          shadow={
                            userFoods &&
                            shadowData &&
                            shadowData[lvl1Prop[1].id]
                            // eslint-disable-next-line
                              ? shadowData[lvl1Prop[1].id] !=
                                foodData[lvl1Prop[1].id]
                              : false
                          }
                        />
                      );
                    }
                  } else {
                    if (lvl1Prop[0] === "Micronutrients") {
                      return (
                        <FoodOneProperty
                          accessLevel={userAccessLevel}
                          key={utils.uuidv4()}
                          variant={constants.ONE_FOOD_PANEL_VARIANT_SPECIAL}
                          propertyName={lvl1Prop[0]}
                          categoryData={lvl1Prop[1]}
                          foodData={this.state.combinedData}
                          propertyValue={
                            this.state.combinedData[lvl1Prop[1].id]
                          }
                          level={1}
                          updateProperty={this.handleUpdateProperty}
                          updateErrors={this.handleUpdateErrors}
                          shadow={
                            userFoods &&
                            shadowData &&
                            shadowData[lvl1Prop[1].id]
                            // eslint-disable-next-line
                              ? shadowData[lvl1Prop[1].id] !=
                                foodData[lvl1Prop[1].id]
                              : false
                          }
                        />
                      );
                    }

                    if (lvl1Prop[0] === "More") {
                      return (
                        <FoodOneProperty
                          accessLevel={userAccessLevel}
                          key={utils.uuidv4()}
                          variant={constants.ONE_FOOD_PANEL_VARIANT_MULTIPLE}
                          propertyName={`${lvl1Prop[0]}...`}
                          categoryData={lvl1Prop[1]}
                          foodData={this.state.combinedData}
                          propertyValue={
                            this.state.combinedData[lvl1Prop[1].id]
                          }
                          level={1}
                          updateProperty={this.handleUpdateProperty}
                          updateErrors={this.handleUpdateErrors}
                          shadow={
                            userFoods &&
                            shadowData &&
                            shadowData[lvl1Prop[1].id]
                            // eslint-disable-next-line
                              ? shadowData[lvl1Prop[1].id] !=
                                foodData[lvl1Prop[1].id]
                              : false
                          }
                        />
                      );
                    }
                  }
                })}
              </div>
            )}
          </DialogContent>
          {!loading && !this.props.userFoods ? (
            <EditActions
              reset={this.handleOpenDialogConfirmReset}
              cancel={this.handleCancel}
              save={this.handleOpenDialogConfirmSave}
              edited={this.props.isEditedPreviously}
            />
          ) : null}
          {!loading && this.props.userFoods ? (
            <UserEditActions
              cancel={this.handleCancel}
              discard={this.handleDiscard}
              accept={this.handleAccept}
            />
          ) : null}
        </Dialog>
        <Dialog
          open={dialogConfirmSaveOpen}
          onClose={this.handleCloseDialogConfirmSave}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to SAVE changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogConfirmSave}
              variant={"contained"}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSave}
              variant={"contained"}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={dialogConfirmResetOpen}
          onClose={this.handleCloseDialogConfirmReset}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to RESET changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialogConfirmReset}
              variant={"contained"}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleReset}
              variant={"contained"}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

FoodEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  foodsEditDialogOpen: PropTypes.bool.isRequired,
  foodsEditDialogVariant: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(FoodEdit)
);

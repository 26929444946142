import React from "react";
import { connect } from "react-redux";

import { getUserLevel } from "store/selectors/auth";
import * as selectors from "store/selectors/recipes";
import {
  setProperty,
  setError,
  setIngredientGroup,
  deleteIngredientGroup,
  deleteIngredient,
  deleteInstruction,
  deleteStep,
  deleteTool,
  setTools,
  setInstructions,
  setToDelete,
  saveAction,
  setInitData,
  discardAllData,
  restoreVersionData
} from "store/reducers/recipes";

import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import AddIcon from "@material-ui/icons/Add";

import { StyledButton } from "common/theme";
import { db } from "common/firebase";
import { dateNow } from "common/utils";
import BasicProperty from "components/BasicProperty";
import LocalizedProperty from "components/LocalizedProperty";
import { dataTemplate } from "data/recipes";
import IngredientsGroup from "./ingredients/IngredientsGroup";
import Tools from "./tools/Tools";
import Instructions from "./instructions/Instructions";
import Images from "components/Images";
import Versions from "./versions/Versions";
import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

const styles = theme => ({
  container: {
    // border: "1px solid grey"
  },
  content: {
    flexDirection: "column",
    margin: theme.spacing(2)
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  root: {
    width: "100%"
  },
  tabs: {
    // boxShadow: "0px 0px 5px 5px #55555555"
    // backgroundColor: "#5555dd"
  },
  // tab: {
  //   "&.Mui-selected": {
  //     transition: "background-color color .7s",
  //     backgroundColor: theme.palette.primary.light,
  //     color: "#ffffff"
  //   }
  // },
  badge: {
    padding: theme.spacing(0, 1)
  }
});

const tabs = [
  { en_GB: "Recipe", de_DE: "", name: "recipe" },
  { en_GB: "Ingredients", de_DE: "", name: "ingredients" },
  { en_GB: "Instructions", de_DE: "", name: "instructions" },
  { en_GB: "Info", de_DE: "", name: "info" },
  { en_GB: "Versions", de_DE: "", name: "versions" }
];

const stateToProps = state => ({
  images: selectors.getImages(state),
  title: selectors.getTitle(state),
  shortDescription: selectors.getShortDescription(state),
  description: selectors.getDescription(state),
  servings: selectors.getServings(state),
  ingredientsGroups: selectors.getIngredientsGroups(state),
  ingredients: selectors.getIngredients(state),
  tools: selectors.getTools(state),
  toolImages: selectors.getToolImages(state),
  instructions: selectors.getInstructions(state),
  difficulty: selectors.getDifficulty(state),
  foodChoice: selectors.getFoodChoice(state),
  allergens: selectors.getAllergens(state),
  nutritionTypes: selectors.getNutritionTypes(state),
  mealTypes: selectors.getMealTypes(state),
  occasions: selectors.getOccasions(state),
  mainIngredients: selectors.getMainIngredients(state),
  culinaryRegions: selectors.getCulinaryRegions(state),
  editedPreviously: selectors.getEditedPreviously(state),
  errors: selectors.getErrors(state),
  saveData: selectors.getData(state),
  userLevel: getUserLevel(state)
});

const actionsToProps = dispatch => ({
  setProperty: payload => dispatch(setProperty(payload)),
  setError: payload => dispatch(setError(payload)),
  setIngredientGroup: payload => dispatch(setIngredientGroup(payload)),
  deleteIngredientGroup: payload => dispatch(deleteIngredientGroup(payload)),
  deleteIngredient: payload => dispatch(deleteIngredient(payload)),
  deleteInstruction: payload => dispatch(deleteInstruction(payload)),
  deleteStep: payload => dispatch(deleteStep(payload)),
  deleteTool: payload => dispatch(deleteTool(payload)),
  setTools: payload => dispatch(setTools(payload)),
  setInstructions: payload => dispatch(setInstructions(payload)),
  setToDelete: payload => dispatch(setToDelete(payload)),
  saveAction: payload => dispatch(saveAction(payload)),
  setInitData: payload => dispatch(setInitData(payload)),
  discardAllData: payload => dispatch(discardAllData(payload)),
  restoreVersionData: payload => dispatch(restoreVersionData(payload))
});

class RecipeEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      loading: true,
      data: null,
      changes: {},
      shadowDoc: null,
      panelStructure: {},
      topProperties: [],
      confirmationDialog: false
    };
  }

  componentDidMount() {
    if (this.props.doc && this.props.doc.data()) {
      let data = this.props.doc.data();
      this.props.setInitData(data);
      this.checkData();
    }
  }

  checkData() {
    let data = this.props.doc.data();
    dataTemplate.forEach(el => {
      if (el.required) {
        if (
          data[el.name] === undefined ||
          data[el.name] === null ||
          data[el.name] === ""
        ) {
          const { name, tab } = el;
          this.props.setError({ error: true, name, tab });
        }
      }
      if (el.name === "ingredientsGroups") {
        data[el.name].forEach((ingredientGroup, i) =>
          ingredientGroup.ingredients.forEach((ingredient, j) => {
            if (ingredient.ref === "missing") {
              this.props.setError({
                error: true,
                name: `${i}-${j}`,
                tab: "ingredients"
              });
              // console.log(ingredient);
            }
          })
        );
      }
    });
  }

  handleSave = async () => {
    // console.log(this.props.errors);
    if (this.props.errors && Object.values(this.props.errors).length > 0) {
      this.setState({
        confirmationAction: "save",
        confirmationDialog: true,
        confirmationText:
          "There are errors in filled in data. You need to fix them before you save."
      });
    } else {
      this.save();
    }
  };

  async save() {
    if (this.props.editedPreviously) {
      this.setState({ loading: true });
      let doc = null;
      if (this.props.doc) {
        doc = this.props.doc.ref;
      } else {
        doc = db.collection("/recipes").doc();
      }

      let versions = null;
      if (this.props.doc) {
        let previousVersions = this.props.doc.data().versions
          ? this.props.doc.data().versions
          : [];
        let previousData = this.props.doc.data();
        delete previousData.versions;

        previousData.timestamp = dateNow(Date.now());

        versions = [...previousVersions, previousData];
      }

      doc
        .set(
          {
            ...this.props.saveData,
            versions
          }
          // { merge: true }
        )
        .then(() => {
          this.props.handleChange();
          this.props.discardAllData();
        });
    } else {
      this.props.handleCancel();
      this.props.discardAllData();
    }
  }

  handleReset = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "reset",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to reset?"
      });
    }
  };

  handleClose = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "cancel",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to cancel?"
      });
    } else {
      this.props.handleCancel();
      this.props.discardAllData();
    }
  };

  handleConfirmation() {
    switch (this.state.confirmationAction) {
      case "cancel":
        this.props.handleCancel();
        this.props.discardAllData();
        break;
      case "reset":
        this.props.setInitData(this.props.doc ? this.props.doc.data() : null);
        break;

      default:
        break;
    }
    this.setState({ confirmationDialog: false });
  }

  changeTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes, lang, open } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} disableEnforceFocus fullScreen>
          <DialogContent>
            <Grid container className={classes.container}>
              <Paper className={classes.root}>
                <Tabs
                  centered={true}
                  indicatorColor="primary"
                  textColor="primary"
                  value={this.state.tab}
                  onChange={this.changeTab}
                  variant="fullWidth"
                  className={classes.tabs}
                >
                  {tabs.map(tab => {
                    return (
                      <Tab
                        className={classes.tab}
                        label={
                          <Badge
                            color="secondary"
                            variant="dot"
                            className={classes.badge}
                            invisible={
                              this.props.errors
                                ? Boolean(!this.props.errors[tab.name])
                                : true
                            }
                          >
                            {tab[lang]}
                          </Badge>
                        }
                        key={tab.name}
                      />
                    );
                  })}
                </Tabs>
              </Paper>

              <Grid container className={classes.content}>
                {dataTemplate.map(template => {
                  if (template.tab === tabs[this.state.tab].name) {
                    const forbiddenAccess =
                      template.forbiddenAccess &&
                      template.forbiddenAccess.includes(this.props.userLevel);
                    switch (template.type) {
                      case "textArea":
                      case "textField":
                      case "number": {
                        return (
                          <LocalizedProperty
                            key={`${template.name}-en`}
                            value={
                              this.props[template.name]
                                ? this.props[template.name]
                                : ""
                            }
                            errors={this.props.errors}
                            callback={this.props.setProperty}
                            onError={this.props.setError}
                            template={template}
                            lang={lang}
                            disabled={forbiddenAccess}
                          />
                        );
                      }
                      case "select":
                        return (
                          <BasicProperty
                            key={template.name}
                            template={template}
                            lang={lang}
                            multiple={template.multiple}
                            value={this.props[template.name]}
                            array={template.selectData.map((el, i) => {
                              return { label: el.label[lang], value: i };
                            })}
                            callback={this.props.setProperty}
                            onError={this.props.setError}
                            disabled={forbiddenAccess}
                          />
                        );
                      case "images":
                        return (
                          <Images
                            key={template.name}
                            template={template}
                            lang={lang}
                            images={this.props.images}
                            callback={this.props.setProperty}
                            onDelete={this.props.setToDelete}
                            onError={this.props.setError}
                            disabled={forbiddenAccess}
                          />
                        );
                      case "ingredients":
                        return (
                          <React.Fragment key={template.name}>
                            {this.props.ingredientsGroups.length > 0 ? (
                              this.props.ingredientsGroups.map((ig, i) => {
                                return (
                                  <IngredientsGroup
                                    key={`ingredient-group-${ig.name}-${i}`}
                                    i={i}
                                    lang={lang}
                                    data={ig}
                                    template={template}
                                    callback={this.props.setIngredientGroup}
                                    onDelete={this.props.deleteIngredientGroup}
                                    onDeleteIngredient={
                                      this.props.deleteIngredient
                                    }
                                    onError={this.props.setError}
                                    error={
                                      this.props.errors &&
                                      this.props.errors[template.tab]
                                        ? this.props.errors[
                                            template.tab
                                          ].filter(el => el.startsWith(i))
                                            .length > 0
                                        : false
                                    }
                                    userLevel={this.props.userLevel}
                                  />
                                );
                              })
                            ) : forbiddenAccess ? null : (
                              <Typography>Add some ingredients...</Typography>
                            )}
                            {forbiddenAccess ? null : (
                              <Paper className={classes.addButtonContainer}>
                                <Button
                                  className={classes.addButton}
                                  onClick={() =>
                                    this.props.setIngredientGroup({
                                      i: this.props.ingredientsGroups.length,
                                      data: {
                                        name: null,
                                        images: [],
                                        expanded: true,
                                        ingredients: []
                                      }
                                    })
                                  }
                                >
                                  <AddIcon />
                                </Button>
                              </Paper>
                            )}
                          </React.Fragment>
                        );
                      case "tools": {
                        return (
                          <Tools
                            key={`tools-${template.name}`}
                            lang={lang}
                            data={this.props.tools}
                            toolImages={this.props.toolImages}
                            template={template}
                            callback={this.props.setTools}
                            onDelete={this.props.deleteTool}
                            setProperty={this.props.setProperty}
                            onError={this.props.setError}
                            userLevel={this.props.userLevel}
                          />
                        );
                      }
                      case "instructions":
                        return (
                          <React.Fragment key={template.name}>
                            {this.props.instructions.length > 0 ? (
                              this.props.instructions.map((inst, i) => (
                                <Instructions
                                  ingredients={this.props.ingredients}
                                  tools={this.props.tools.map((el, i) => {
                                    return { label: el, value: i };
                                  })}
                                  key={`instructions-${inst.name}-${i}`}
                                  i={i}
                                  lang={lang}
                                  data={inst}
                                  template={template}
                                  callback={this.props.setInstructions}
                                  onDelete={this.props.deleteInstruction}
                                  onDeleteStep={this.props.deleteStep}
                                  onError={this.props.setError}
                                  userLevel={this.props.userLevel}
                                />
                              ))
                            ) : forbiddenAccess ? null : (
                              <Typography>Add some instructions...</Typography>
                            )}
                            {forbiddenAccess ? null : (
                              <Paper className={classes.addButtonContainer}>
                                <Button
                                  className={classes.addButton}
                                  onClick={() => {
                                    // console.log(this.props.instructions);
                                    return this.props.setInstructions({
                                      i: this.props.instructions.length,
                                      data: {
                                        name: null,
                                        images: [],
                                        expanded: true,
                                        prepTime: null,
                                        cookingTime: null,
                                        restTime: null,
                                        step: []
                                      }
                                    });
                                  }}
                                >
                                  <AddIcon />
                                </Button>
                              </Paper>
                            )}
                          </React.Fragment>
                        );
                      default:
                        return (
                          <Typography key={template.name}>
                            Not existing field type {template.type} for{" "}
                            {template.name}
                          </Typography>
                        );
                    }
                  } else {
                    return null;
                  }
                })}
                {this.props.userLevel !== USER_LEVEL_EXTERNAL_EMPLOYEE &&
                tabs[this.state.tab].name === "versions" ? (
                  <Versions
                    data={
                      this.props.doc ? this.props.doc.data().versions : null
                    }
                    restoreVersionData={this.props.restoreVersionData}
                    callback={()=>this.setState({ tab: 0 })}
                  />
                ) : null}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <StyledButton
              onClick={this.handleReset}
              disabled={!this.props.editedPreviously}
            >
              <Typography>Reset All Changes</Typography>
            </StyledButton>
            <StyledButton onClick={this.handleClose} color="primary">
              <Typography>Cancel</Typography>
            </StyledButton>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={this.handleSave}
            >
              <Typography>Save</Typography>
            </StyledButton>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.confirmationDialog}>
          <DialogTitle>{this.state.confirmationText}</DialogTitle>
          <DialogActions>
          {this.state.confirmationAction !== "save" ? (
              <StyledButton
                onClick={() => this.setState({ confirmationDialog: false })}
              >
                Cancel
              </StyledButton>
            ) : null}
            <StyledButton
              onClick={this.handleConfirmation.bind(this)}
              color="primary"
              variant="contained"
            >
              OK
            </StyledButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(stateToProps, actionsToProps)(RecipeEdit)
);

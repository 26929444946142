import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import LocalizedProperty from "components/LocalizedProperty";
import SearchRecipe from "components/SearchRecipe";
import { db } from "common/firebase";

const styles = theme => ({
  panelDetails: {
    alignItems: "center"
  },
  badge: {
    padding: theme.spacing(0, 1)
  }
});

class MealView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data:
        this.props.data !== null && this.props.data !== undefined
          ? this.props.data
          : {},
      changed: false,
      error: false,
      expanded: false,
    };
  }

  componentDidMount() {
    if (this.state.data["recipe"]) {
      db.collection("recipes")
        .doc(this.state.data["recipe"])
        .get()
        .then(doc => {
          let { data } = this.state;
          data.recipeTitle = doc.data().title;
          this.setState({ data });
        });
    }
  }

  onError(error) {
    const { tab } = this.props.template;
    this.props.onError &&
      this.props.onError({ error, name: this.props.i, tab });
  }

  handleExpandedChange() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleBlur(obj) {
    let data = { ...this.state.data };
    if (Array.isArray(obj)) {
      obj.forEach(el => (data[el.property] = el.value));
    } else {
      data[obj.property] = obj.value;
    }
    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback({ i: this.props.i, data: this.state.data })
    );
  }

  handleRecipeSearch(recipeData) {
    this.handleBlur([
      { property: "recipeTitle", value: recipeData.title },
      { property: "recipe", value: recipeData.docId }
    ]);
  }

  handleDelete() {
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  render() {
    const { classes, template, lang } = this.props;
    const details = [];
    const inputs = [];

    for (const key in template.children) {
      const child = template.children[key];
      const forbiddenAccess =
        child.forbiddenAccess &&
        child.forbiddenAccess.includes(this.props.userLevel);
      if (child.type !== "searchRecipe" && child.name !== "recipe") {
        inputs.push(
          <LocalizedProperty
            key={`meal-${child.name}-${key}-${this.props.i}-${this.props.id}`}
            template={child}
            disabled={child.disabled || forbiddenAccess}
            lang={lang}
            errors={this.props.errors}
            value={this.state.data[child.name]}
            callback={this.handleBlur.bind(this)}
          />
        );
        let detail = child.name === "startDateOffset" ? "Day " : "";
        detail +=
          typeof this.state.data[child.name] === "object" &&
          this.state.data[child.name]
            ? `EN: ${
                this.state.data.recipeTitle.en_GB
                  ? this.state.data.recipeTitle.en_GB
                  : "-"
              }, DE: ${
                this.state.data.recipeTitle.de_DE
                  ? this.state.data.recipeTitle.de_DE
                  : "-"
              }`
            : this.state.data[child.name] !== null
            ? this.state.data[child.name]
            : "-";

        details.push(`${detail}`);
      }
    }

    const forbiddenAccess =
    template.forbiddenAccess &&
    template.forbiddenAccess.includes(this.props.userLevel);

    return (
      <ExpansionPanel
        // expanded={this.state.data.expanded}
        expanded={this.state.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Badge
            color="secondary"
            variant="dot"
            className={classes.badge}
            invisible={!this.state.error}
          >
            <Typography className={classes.heading}>
              {!this.state.data.startDateOffset && !this.state.data.time
                ? "New meal"
                : !this.state.expanded
                ? details.map((el, i) => {
                    return `${el}${i < details.length - 1 ? ", " : ""}`;
                  })
                : `Day ${this.state.data.startDateOffset}, ${this.state.data.time}`}
            </Typography>
          </Badge>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          {inputs.map(el => el)}
          <SearchRecipe
            template={template.children.ref}
            value={this.state.data.ref}
            error={this.state.error}
            lang={lang}
            i={this.props.i}
            callback={this.handleRecipeSearch.bind(this)}
            disabled={forbiddenAccess}
          />
          {forbiddenAccess ? null : <IconButton
            onClick={this.handleDelete.bind(this)}
            className={classes.delete}
          >
            <DeleteIcon />
          </IconButton>}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MealView);

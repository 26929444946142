import { db } from "common/firebase";
import sortedSchema from "data/schema";
import * as _ from "lodash";

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    // eslint-disable-next-line eqeqeq,no-mixed-operators
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 *
 * @param str string
 * @return string
 */
export function jsUcfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * check and format phone number...
 * @param phoneNumber string
 * @param countryCode string
 * @return string
 */
export function ironPhoneNumber(phoneNumber, countryCode = "") {
  let ironedWhatever = normalizeStringForSearch(phoneNumber);
  if (countryCode && ironedWhatever.charAt(0) === "0") {
    ironedWhatever =
      /* + */ countryCode + ironedWhatever.substring(1, ironedWhatever.length);
  }
  return ironedWhatever;
}

/**
 *
 * @param string string
 * @return string
 */
export function normalizeStringForSearch(string) {
  let normalizedString;
  normalizedString = string.replace(/\s/g, "");
  normalizedString = normalizedString.toLowerCase();
  return normalizedString;
}

export function isUserAuthenticated(user) {
  return user !== null;
}

export function getDataWithIdFromFirebaseDocument(doc) {
  if (!doc.exists) {
    throw new Error(`Document with ID: '${doc.id}' does not exists!`);
  }
  return {
    ...doc.data(),
    ...{ id: doc.id }
  };
}

/**
 *
 * @param dataString string
 * @return string
 */
// @ts-ignore
export function decodeBase64Image(dataString) {
  try {
    // eslint-disable-next-line no-useless-escape
    const matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

    if (matches.length !== 3) {
      return new Error("Invalid input string");
    }

    return {
      type: matches[1],
      data: new Buffer(matches[2], "base64"),
      extension: matches[1].split("/")[1]
    };
  } catch (e) {
    console.info("E0011: ", e.errorInfo);
    return null;
  }
}

/**
 *
 * @param object object
 * @return object
 */
export function detailedLog(object) {
  const util = require("util");
  return util.inspect(object, { showHidden: true, depth: null });
}

/**
 *
 * @param max number
 * @return number
 */
export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/**
 *
 * @param timestamp string
 * @return string
 */
export function timestampToHumanDateTime(timestamp) {
  try {
    const moment = require("moment");
    return moment(parseInt(timestamp))
      .locale("rs")
      .format(moment.HTML5_FMT.DATETIME_LOCAL);
  } catch (e) {
    console.error("E0016", e);
    return "invalid!";
  }
}

/**
 *
 * @return string
 */
export function dateNow() {
  try {
    const now = new Date();
    let month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    const dd = String(now.getDate()).padStart(2, "0");
    const mm = String(month[now.getMonth()]);
    const yyyy = now.getFullYear();
    const h = String(now.getHours()).padStart(2, "0");
    const m = String(now.getMinutes()).padStart(2, "0");
    const s = String(now.getSeconds()).padStart(2, "0");
    //   console.log(`${mm} ${dd}, ${yyyy} ${h}:${m}:${s}`);
    return `${mm} ${dd}, ${yyyy} ${h}:${m}:${s}`;
  } catch (e) {
    console.error("E0016", e);
    return "invalid!";
  }
}

/**
 *
 * @param object Object
 * @return Object
 */
export function cleanObjectOfUndefineds(object) {
  Object.keys(object).forEach(
    key => object[key] === undefined && delete object[key]
  );
  return object;
}

/**
 *
 * @param date
 * @param timestampFormat
 * @return {*}
 */
export function getStartAndEndOfMonth(date, timestampFormat = true) {
  const moment = require("moment");
  const dateFrom = moment([date.year(), date.month(), 1]);
  const dateTo = moment([date.year(), 0, 31]).add(date.month(), "months");

  return timestampFormat
    ? { dateFrom: dateFrom.format("x"), dateTo: dateTo.format("x") }
    : { dateFrom: dateFrom.format("LLL"), dateTo: dateTo.format("LLL") };
}

export function notNullOrUndefined(value) {
  return value !== null && value !== undefined;
}

export function isNullOrUndefined(value) {
  return !notNullOrUndefined(value);
}

/**
 *
 * @param length
 * @return string
 */
export function randomString(length = 10) {
  return (
    Math.random()
      .toString(36)
      .substring(2, length / 2 + 3) +
    Math.random()
      .toString(36)
      .substring(2, length / 2 + 3)
  ).substring(0, length);
}

export function firstValidFromArray(array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== null && array[i] !== undefined) {
      return array[i];
    }
  }
}

export function generatePreview(htmlString, replacementData) {
  let replacedString = htmlString;
  if (replacementData) {
    Object.keys(replacementData).forEach(key => {
      replacedString = replacedString
        .split(`{${key}}`)
        .join(replacementData[key]);
    });
  }
  return replacedString;
}

export function timestampToHumanFriendlyObject(timestamp) {
  const moment = require("moment");

  return {
    timestamp: timestamp.toString(),
    parsed: moment(timestamp).format("LLL")
  };
}

export function sortObjectKeysAlphabetically(obj) {
  const sortObject = require("sort-object-keys");
  return sortObject(obj);
}

export function getDomain() {
  const domain = window.location.hostname;
  const parts = domain.split(".").reverse();
  const cnt = parts.length;
  if (cnt >= 3) {
    if (
      parts[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|io|info|biz)$/i)
    ) {
      return parts[2] + "." + parts[1] + "." + parts[0];
    }
  }

  if (isNullOrUndefined(parts[1])) {
    return parts[0];
  }

  return parts[1] + "." + parts[0];
}

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  console.info(
    "email is: ",
    re.test(String(email).toLowerCase()) ? "valid" : "invalid"
  );
  return re.test(String(email).toLowerCase());
}

export function isObjectEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function isStringNumerical(str) {
  return !!str.match(/^\d+$/);
}

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function handleSave(params, successCallback) {
  const { shadowDoc, shadowData, changes, id_db, baseUnit } = params;

  if (shadowDoc !== null) {
    // console.log({
    //   ...shadowData,
    //   ...changes
    // });
    await shadowDoc.ref
      .set(
        {
          ...shadowData,
          ...changes,
          id_db
        },
        { merge: true }
      )
      .then(async () => {
        await successCallback();
      })
      .catch(e => {
        console.error(e);
      });
  } else {
    // console.log({
    //   ...shadowData,
    //   ...changes
    // });
    await db
      .collection("shadows")
      .doc(id_db)
      .set({
        ...shadowData,
        ...changes,
        id_db,
        baseUnit: baseUnit,
        level_vis: 1
      })
      .then(async () => {
        await successCallback();
      })
      .catch(e => {
        console.error(e);
      });
  }
}

let schemaFields = [];
sortedSchema.map(oneProp => schemaFields.push(oneProp));

function equals(a, b) {
  if (
    // eslint-disable-next-line eqeqeq
    a == b ||
    // eslint-disable-next-line eqeqeq
    (a == undefined && b == "") ||
    // eslint-disable-next-line eqeqeq
    (b == undefined && a == "") ||
    // eslint-disable-next-line eqeqeq
    (a == 0 && b == "") ||
    // eslint-disable-next-line eqeqeq
    (b == 0 && a == "")
  ) {
    return true;
  }
  return false;
}

export function findFoodDiff(userFoods, foodData, shadowData) {
  let result = { ...shadowData };
  schemaFields.forEach(field => {
    if (
      !equals(foodData[field.id], userFoods[field.id]) &&
      field.visibility !== 0
    ) {
      if (typeof foodData[field.id] !== "object") {
        // console.log(
        //   "id =",
        //   field.id,
        //   "foods =",
        //   foodData[field.id] !== "" ? foodData[field.id] : '"',
        //   "userFoods =",
        //   userFoods[field.id] !== "" ? userFoods[field.id] : '"',
        //   "visibility =",
        //   field.visibility
        // );
        result[field.id] = userFoods[field.id];
      } else {
        if (
          !_.isEqual(foodData[field.id], userFoods[field.id]) &&
          field.visibility !== 0
        ) {
          // console.log(
          //   "id =",
          //   field.id,
          //   "foods =",
          //   foodData[field.id] !== "" ? foodData[field.id] : '"',
          //   "userFoods =",
          //   userFoods[field.id] !== "" ? userFoods[field.id] : '"',
          //   "visibility =",
          //   field.visibility
          // );
          result[field.id] = userFoods[field.id];
        }
      }
    }
  });
  return result;
}

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as constants from "common/constants";
import * as utils from "common/utils";
import TextField from "@material-ui/core/TextField";

class FoodOneEAN extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      propValue: null,
      errors: []
    };
  }

  componentDidMount() {
    this.setState({
      propValue: this.props.propertyValue,
      loading: false
    });
  }

  get isAllowedForUserToEdit() {
    if (this.props.accessLevel === constants.USER_LEVEL_ADMIN) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EMPLOYEE) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EXTERNAL_EMPLOYEE) {
      return false;
    }

    return false;
  }

  handleChange = () => event => {
    const errors = [];

    const eans = event.target.value.split(",");

    // validate
    eans.forEach(ean => {
      if (ean.length === 1 || ean.length === 0) {
        errors.push(
          `EAN needs to be 13 digit number, separated by comma for multiple entries`
        );
      } else if (ean.length !== 13) {
        errors.push(`'${ean}' needs to be 13 digit number`);
      } else if (!utils.isStringNumerical(ean)) {
        errors.push(`'${ean}' needs to have numbers only!`);
      } else {
        // do nothing, all good
      }
    });

    this.setState({
      propValue: eans,
      errors: errors
    });
    this.props.updateProperty("id_ean", eans);
    this.props.updateErrors("EAN", errors);
  };

  render() {
    const { classes } = this.props;

    const { loading, propValue, errors } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div className={classes.container}>
        <TextField
          className={classes.servingSizesTranslationInput}
          disabled={!this.isAllowedForUserToEdit}
          variant="outlined"
          helperText={errors.join(", ")}
          error={errors.length > 0 || this.props.shadow}
          fullWidth={true}
          label={"EAN"}
          value={propValue}
          onChange={this.handleChange()}
        />
      </div>
    );
  }
}

FoodOneEAN.propTypes = {
  classes: PropTypes.object,
  propertyValue: PropTypes.any,
  accessLevel: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(FoodOneEAN);

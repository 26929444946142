import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import { createMuiTheme } from "@material-ui/core";

import { deepOrange } from "@material-ui/core/colors";
import { blue } from "@material-ui/core/colors";

export const palette = {
  primary: {
    c100: blue[100],
    c200: blue[200],
    light: blue[300],
    main: blue[500],
    dark: blue[700]
  },
  secondary: {
    light: deepOrange[300],
    main: deepOrange[500],
    dark: deepOrange[700]
  }
};

const adminTheme = createMuiTheme({
  palette: palette,
  spreadThis: {
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      useNextVariants: true
    },
    anchor: {
      main: blue[500],
      selected: blue[700]
    },

    overrides: {
      MuiTable: {
        root: {
          outline: "none"
        }
      }
    }
  }
});

export default adminTheme;

export const StyledButton = withStyles(theme => ({
  root: {
    color: "rgba(0,0,0,.54)",
    borderRadius: theme.spacing(1),
    textTransform: "unset",
    "&.MuiButton-contained": {
      color: "#fff",
      borderRadius: theme.spacing(1),
      textTransform: "unset !important"
    }
  }
}))(Button);

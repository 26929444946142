import React from "react";

import { db } from "common/firebase";
import BasicProperty from "components/BasicProperty";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  odd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  even: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8f8f0"
  },
  content: {
    minWidth: "300px",
    minHeight: "400px",
    justifyContent: "center",
    alignContent: "center",
    margin: "auto"
  },
  search: {
    height: "50px",
    width: "50px"
  },
  listItemText: {
    pointerEvents: "none",
    width: "50%",
    marginLeft: theme.spacing(2)
  },
  divider: {
    position: "absolute",
    height: "100%"
  },
  dialogTitle: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: 0
  },
  dialogTitleContainer: {
    justifyContent: "space-around"
  },
  dialogActions: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: 0
  }
});

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function cleanSearchString(string) {
  return string.replace(/\s/g, "").toLowerCase();
}

const relevantColumns = [
  "id_db",
  "brand_def",
  "product_name_EN",
  "product_name_DE"
];

var sort_by;
(function() {
  // utility functions
  var default_cmp = function(a, b) {
      if (a === b) return 0;
      return a < b ? -1 : 1;
    },
    getCmpFunc = function(primer, reverse) {
      var dfc = default_cmp, // closer in scope
        cmp = default_cmp;
      if (primer) {
        cmp = function(a, b) {
          return dfc(primer(a), primer(b));
        };
      }
      if (reverse) {
        return function(a, b) {
          return -1 * cmp(a, b);
        };
      }
      return cmp;
    };

  // actual implementation
  sort_by = function() {
    var fields = [],
      n_fields = arguments.length,
      field,
      name,
      cmp;

    // preprocess sorting options
    for (var i = 0; i < n_fields; i++) {
      field = arguments[i];
      if (typeof field === "string") {
        name = field;
        cmp = default_cmp;
      } else {
        name = field.name;
        cmp = getCmpFunc(field.primer, field.reverse);
      }
      fields.push({
        name: name,
        cmp: cmp
      });
    }

    // final comparison function
    return function(A, B) {
      var name, result;
      for (var i = 0; i < n_fields; i++) {
        result = 0;
        field = fields[i];
        name = field.name;

        result = field.cmp(A[name], B[name]);
        if (result !== 0) break;
      }
      return result;
    };
  };
})();

class SearchFood extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderBy: "id_db",
      orderDirection: "asc",
      currentPage: 0,
      lastVisible: [],
      numberOfRows: 100,
      indexes: [],
      foodData: [],
      fetchingFoodsData: false,
      searchText: this.props.value ? this.props.value : "",
      dropDown: false,
      value: "",
      label: ""
    };
  }

  componentDidMount() {
    this.setState({
      lastVisible: Object.values(this.state.indexes)[
        this.state.numberOfRows - 1
      ]
    });
  }

  handleListItemClick(e) {
    const { id } = e.target;
    this.props.callback(this.state.foodData[id]);
    this.setState({
      dropDown: false,
      searchText: ""
    });
  }

  searchChange(obj) {
    // eslint-disable-next-line
    const { property, value } = obj;
    this.handleSearchChange(value);
  }

  /**
   *
   * @param searchText string
   * @returns {Promise<void>}
   */
  handleSearchChange = async searchText => {
    if (searchText === "") {
      // await this.reloadTable();
      return;
    }

    if (searchText.length === 2 && this.state.searchText.length === 3) {
      await this.setState({
        searchText: ""
      });
      // await this.reloadTable();
      return;
    }

    // do nothing
    if (searchText.length < 3) {
      return;
    }

    await this.setState({
      currentPage: 0,
      lastVisible: [],
      searchText: searchText
    });

    await this.reloadTableWithFullResults();
  };

  /**
   * for search and filter
   * @returns {Promise<void>}
   */
  async reloadTableWithFullResults() {
    const oldSearchText = this.state.searchText;

    // let time = performance.now();
    await this.setState({
      fetchingFoodsData: true,
      foodData: [],
      indexes: []
    });

    // const time2 = performance.now();
    await asyncForEach(relevantColumns, async columnName => {
      await db
        .collection("allFoodIndex")
        .orderBy(columnName)
        .startAt(cleanSearchString(this.state.searchText))
        .endAt(cleanSearchString(this.state.searchText) + "\uf8ff")
        // .limit(this.state.numberOfRows)
        .get()
        .then(async result => {
          // do NOT set data if this function trigger was NOT the last input char
          if (oldSearchText !== this.state.searchText) {
            return;
          }

          const indexes = [];
          Object.values(result.docs).forEach(doc => {
            indexes.push(doc.data());
          });

          // console.log(this.state.indexes, indexes);
          await this.setState({
            indexes: [...this.state.indexes, ...indexes]
          });
        });
    });
    // console.log("index total time", (performance.now() - time2) / 1000);

    // do NOT set data if this function trigger was NOT the last input char
    if (oldSearchText !== this.state.searchText) {
      return;
    }

    this.state.indexes.sort(sort_by("level_vis", "id_db"));
    let searchResults = this.state.indexes
      .slice(0, this.state.numberOfRows)
      .map(el => el.id_db.toUpperCase());
    // console.log(searchResults);

    if (searchResults.length > 0) {
      let i = 0;
      do {
        let food = [];
        await db
          .collection("/shadowed")
          .where(
            "id_db",
            "in",
            searchResults.slice(
              i,
              i + 10 < searchResults.length ? i + 10 : searchResults.length
            )
          )
          .get()
          .then(result => {
            Object.values(result.docs).forEach(doc => {
              const data = doc.data();
              food.push({
                docId: data.id_db,
                name: {
                  en_GB: data.product_name_EN,
                  de_DE: data.product_name_DE
                },
                baseUnit: data.baseUnit
              });
            });
            // console.log(food);
            this.setState({
              foodData: [...this.state.foodData, ...food]
            });
          });
        i += 10;
      } while (i < searchResults.length);
    }

    // console.log("total time", (performance.now() - time) / 1000);
    this.setState({
      fetchingFoodsData: false
    });
  }

  openDropDown() {
    this.setState({ dropDown: true });
    // this.handleSearchChange(this.state.);
  }

  closeDropDown() {
    this.setState({ dropDown: false });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.openDropDown();
    }
  }

  render() {
    const { classes, template, lang, disabled } = this.props;
    return (
      <React.Fragment>
        <BasicProperty
          key={`ingredient-search-${this.props.i}-${this.props.id}`}
          template={{ ...template, type: "textField" }}
          error={this.props.error}
          lang={lang}
          value={this.state.searchText}
          callback={this.searchChange.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          disabled={disabled}
        />
        {disabled ? null : (
          <IconButton
            onClick={this.openDropDown.bind(this)}
            className={classes.search}
          >
            <SearchIcon />
          </IconButton>
        )}
        <Dialog
          onClose={this.closeDropDown.bind(this)}
          onBackdropClick={this.closeDropDown.bind(this)}
          open={this.state.dropDown}
          className={classes.dialog}
        >
          <DialogActions className={classes.dialogActions}>
            <IconButton onClick={this.closeDropDown.bind(this)} color="primary">
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogTitle className={classes.dialogTitle}>
            <Grid container className={classes.dialogTitleContainer}>
              <Typography>EN</Typography>
              <Typography>DE</Typography>
            </Grid>
          </DialogTitle>

          <List className={classes.list}>
            {this.state.foodData.map((el, i) => {
              return (
                <ListItem
                  className={i % 2 ? classes.odd : classes.even}
                  button
                  key={`ing-list-${i}`}
                  id={i}
                  onClick={this.handleListItemClick.bind(this)}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={`${el.name.en_GB ? el.name.en_GB : ""}`}
                  />
                  <Divider className={classes.divider} orientation="vertical" />
                  <ListItemText
                    className={classes.listItemText}
                    primary={`${el.name.de_DE ? el.name.de_DE : ""}`}
                  />
                </ListItem>
              );
            })}
          </List>

          {this.state.fetchingFoodsData ? (
            <Grid container className={classes.content}>
              <CircularProgress className={classes.progress} />
            </Grid>
          ) : null}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SearchFood);

import { createAction } from "redux-actions";

export const SET_PROPERTY = "carbs@@SET_PROPERTY";
export const SET_ERROR = "carbs@@SET_ERROR";
export const SET_INIT_DATA = "carbs@@SET_INIT_DATA";
export const DISCARD_ALL_DATA = "carbs@@DISCARD_ALL_DATA";
export const RESET_EDIT = "carbs@@RESET_EDIT";

const initialState = {
  high: null,
  keto: null,
  medium: null,
  moderate: null,
  standard: null,
  standardLow: null,
  strict: null,
  editedPreviously: false,
  errors: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY: {
      let newState = { ...state };
      const { property, value, lang } = action.payload;
      // console.log("SET PROPERTY");
      // console.log(property, value);

      if (lang) {
        if (!newState[property]) {
          newState[property] = {};
        }
        newState[property][lang] = value;
      } else {
        newState[property] = value;
      }

      return {
        ...newState,
        editedPreviously: true
      };
    }
    case SET_ERROR: {
      const { error, name } = action.payload;
      let errors = { ...state.errors };

      errors[name] = error;

      // console.log("SET ERROR", errors);
      return {
        ...state,
        errors
      };
    }
    case SET_INIT_DATA: {
      let newState = { ...state };
      if (action.payload !== null) {
        for (const key in newState) {
          if (Array.isArray(newState[key])) {
            newState[key] = action.payload[key] ? action.payload[key] : [];
          } else {
            newState[key] =
              action.payload[key] || action.payload[key] === false
                ? action.payload[key]
                : null;
          }
        }
      } else {
        newState = { ...initialState };
      }
      // console.log("set init data", newState);

      return {
        ...newState,
        editedPreviously: false
      };
    }
    case RESET_EDIT: {
      return { ...state, editedPreviously: false };
    }
    case DISCARD_ALL_DATA: {
      // console.log("discard data", initialState);

      return {
        ...initialState
      };
    }
    default:
      return state;
  }
};

export const setProperty = createAction(SET_PROPERTY);
export const setError = createAction(SET_ERROR);
export const setInitData = createAction(SET_INIT_DATA);
export const discardAllData = createAction(DISCARD_ALL_DATA);
export const resetEdit = createAction(RESET_EDIT);

export default reducer;

import React from "react";

import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import RecipePreview from "./RecipePreview";

const styles = theme => ({
  list: {
    // border: "1px solid red"
  },
  details: {
    alignItems: "flex-end"
  },
  button: {
    height: theme.spacing(5),
    bottom: theme.spacing(1)
  }
});

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

class Versions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      confirmationDialog: false,
      i: -1
    };
  }

  handleExpandedChange(i) {
    this.setState({
      expanded: i !== this.state.expanded ? i : false
    });
  }

  handleConfirmation() {
    this.props.restoreVersionData(this.props.data[this.state.i]);
    this.setState({ confirmationDialog: false }, () => this.props.callback && this.props.callback());
  }

  restoreClick(i) {
    this.setState({ confirmationDialog: true, i: i });
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.list}>
        {data
          ? data
              .slice(0)
              .reverse()
              .map((el, i) => {
                return (
                  <ExpansionPanel
                    key={`version-${i}`}
                    square
                    expanded={this.state.expanded === i}
                    onChange={this.handleExpandedChange.bind(this, i)}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <ExpansionPanelSummary className={classes.listItemText}>
                      <Typography>{`Version ${data.length -
                        1 -
                        i} - ${el.timestamp}`}</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails className={classes.details}>
                      <RecipePreview data={el} />
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        onClick={this.restoreClick.bind(this, i)}
                      >
                        Restore
                      </Button>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })
          : "No previous versions."}
        <Dialog open={this.state.confirmationDialog}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            This action will discard all later changes. Are you sure you want to
            restore this version?{" "}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleConfirmation.bind(this)}
              color="primary"
            >
              OK
            </Button>
            <Button
              color="primary"
              onClick={() => this.setState({ confirmationDialog: false })}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Versions);

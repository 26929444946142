import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: { en_GB: "Name EN", de_DE: "Name DE" },
    name: "name",
    visibility: 1,
    localized: true,
    required: true,
    tab: "recipes",
    type: "textField"
  },
  {
    label: { en_GB: "Recipes", de_DE: "" },
    name: "recipes",
    visibility: 0,
    type: "recipes",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    children: {
      title: {
        label: { en_GB: "Title EN", de_DE: "Title DE" },
        name: "title",
        visibility: 0,
        localized: true,
        disabled: true,
        type: "textField"
      },
      ref: {
        label: { en_GB: "Search for recipe", de_DE: "" },
        name: "ref",
        visibility: 0,
        type: "searchRecipe",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      }
    }
  }
];

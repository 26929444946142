import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: { en_GB: "Title EN", de_DE: "Title DE" },
    name: "title",
    visibility: 1,
    localized: true,
    required: true,
    tab: "nutritions",
    type: "textField"
  },
  {
    label: { en_GB: "Description EN", de_DE: "Description DE" },
    name: "description",
    visibility: 1,
    localized: true,
    tab: "nutritions",
    type: "textField"
  },
  {
    label: { en_GB: "Image", de_DE: "" },
    name: "image",
    visibility: 0,
    required: true,
    tab: "nutritions",
    type: "images",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
  },
  {
    label: { en_GB: "Meals", de_DE: "" },
    name: "meals",
    visibility: 0,
    type: "meals",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    children: {
      startDateOffset: {
        label: { en_GB: "Start date offset", de_DE: "" },
        name: "startDateOffset",
        visibility: 0,
        type: "number",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      time: {
        label: { en_GB: "Time", de_DE: "" },
        name: "time",
        visibility: 0,
        type: "textField",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      recipe: {
        label: { en_GB: "Recipe ID", de_DE: "" },
        name: "recipe",
        visibility: 0,
        disabled: true,
        type: "textField",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      recipeTitle: {
        label: { en_GB: "Recipe EN", de_DE: "Recipe DE" },
        name: "recipeTitle",
        visibility: 0,
        localized: true,
        disabled: true,
        type: "textField",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      },
      ref: {
        label: { en_GB: "Search for recipe", de_DE: "" },
        name: "ref",
        visibility: 0,
        type: "searchRecipe",
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE]
      }
    }
  }
];

import React from "react";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core";
import { getUserLevel } from "store/selectors/auth";
import { dataTemplate } from "data/carbs";
import { db } from "common/firebase";
import * as selectors from "store/selectors/carbs";
import {
  setProperty,
  setError,
  setInitData,
  resetEdit
} from "store/reducers/carbs";

import { StyledButton } from "common/theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "./Table";

const styles = theme => ({
  actions: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    gridGap: theme.spacing(1)
  }
});

const stateToProps = state => ({
  userLevel: getUserLevel(state),
  editedPreviously: selectors.getEditedPreviously(state),
  errors: selectors.getErrors(state),
  high: selectors.getHigh(state),
  keto: selectors.getKeto(state),
  medium: selectors.getMedium(state),
  moderate: selectors.getModerate(state),
  standard: selectors.getStandard(state),
  standardLow: selectors.getStandardLow(state),
  strict: selectors.getStrict(state),
  data: selectors.getData(state)
});

const actionsToProps = dispatch => ({
  setProperty: payload => dispatch(setProperty(payload)),
  setInitData: payload => dispatch(setInitData(payload)),
  setError: payload => dispatch(setError(payload)),
  resetEdit: payload => dispatch(resetEdit(payload))
});

class CarbsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initData: null,
      confirmationDialog: false
    };

    this.lang = "en_GB";
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const doc = db.doc("masterdata/carbs");
    doc.get().then(async snapshot => {
      this.setState({ doc, initData: snapshot.data() }, () =>
        this.props.setInitData(snapshot.data())
      );
    });
  }

  handleSave = async () => {
    let error = false;
    if (this.props.errors) {
      Object.values(this.props.errors).forEach(err => {
        if (err) {
          error = true;
        }
      });
    }

    if (error) {
      this.setState({
        confirmationAction: "save",
        confirmationDialog: true,
        confirmationText:
          "There are errors in filled in data. You need to fix them before you save."
      });
    } else {
      this.save();
    }
  };

  async save() {
    if (this.props.editedPreviously) {
      this.state.doc
        .set({
          ...this.props.data
        })
        .then(() => {
          this.setState({ initData: this.props.data }, () =>
            this.props.resetEdit()
          );
        });
    }
  }

  handleReset = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "reset",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to reset?"
      });
    }
  };

  handleConfirmation() {
    switch (this.state.confirmationAction) {
      case "reset":
        this.props.setInitData(this.state.initData);
        break;

      default:
        break;
    }
    this.setState({ confirmationDialog: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {dataTemplate.map((template, i) => {
          switch (template.type) {
            case "table": {
              return (
                <Table
                  key={template.name}
                  title={template.label[this.lang]}
                  template={template.tableData}
                  data={this.props.data}
                  lang={this.lang}
                  userLevel={this.props.userLevel}
                  onChange={this.props.setProperty}
                  onError={this.props.setError}
                  size="small"
                />
              );
            }
            default:
              return (
                <Typography key={template.name}>
                  Not existing field type {template.type} for {template.name}
                </Typography>
              );
          }
        })}
        <Grid className={classes.actions}>
          <StyledButton
            onClick={this.handleReset}
            disabled={!this.props.editedPreviously}
          >
             <Typography>Discard Changes</Typography>
          </StyledButton>
          <StyledButton
            onClick={this.handleSave}
            disabled={!this.props.editedPreviously}
            color="primary"
            variant="contained"
          >
             <Typography>Publish</Typography>
          </StyledButton>
        </Grid>
        <Dialog open={this.state.confirmationDialog}>
          <DialogTitle>{this.state.confirmationText}</DialogTitle>
          <DialogActions>
            {this.state.confirmationAction !== "save" ? (
              <StyledButton
                color="primary"
                onClick={() => this.setState({ confirmationDialog: false })}
              >
                Cancel
              </StyledButton>
            ) : null}
            <StyledButton
              onClick={this.handleConfirmation.bind(this)}
              color="primary"
              variant="contained"
            >
              OK
            </StyledButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(stateToProps, actionsToProps)(CarbsEdit)
);

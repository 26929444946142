import React from "react";
import { connect } from "react-redux";

import { dataTemplate } from "data/news";
import NewsEdit from "components/news/NewsEdit";
import DataTablePage from "components/DataTablePage";

import * as masterdata from "store/selectors/masterdata";

const stateToProps = state => ({
  count: masterdata.getNewsCount(state)
});

const actionsToProps = dispatch => ({});

class News extends React.Component {
  render() {
    return (
      <DataTablePage
        collection="news"
        count={this.props.count}
        dataTemplate={dataTemplate}
        edit={NewsEdit}
        tableTitle="News"
        editTooltip="Edit this news"
      />
    );
  }
}

export default connect(stateToProps, actionsToProps)(News);

import React from "react";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

// import BasicProperty from "components/BasicProperty";
import LocalizedProperty from "components/LocalizedProperty";

const styles = theme => ({
  panelDetails: {
    flexDirection: "column"
  },
  item: {
    margin: theme.spacing(2)
  },
  deleteContainer: {
    justifyContent: "center",
    width: "100%"
  }
});

class Instruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //TODO implement adding new Instruction
      data: { ...this.props.data, expanded: false },
      changed: false
    };
  }

  handleExpandedChange() {
    let { data } = this.state;
    data.expanded = !this.state.data.expanded;
    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback(this.props.i, this.state.data)
    );
  }

  handleBlur(obj) {
    const { lang, property, value } = obj;
    let { data } = this.state;

    if (lang) {
      data[property][lang] = value;
    } else {
      data[property] = value;
    }

    this.setState(
      { data },
      () =>
        this.props.callback &&
        this.props.callback(this.props.i, this.state.data)
    );
  }

  handleDelete() {
    this.props.onDelete && this.props.onDelete(this.props.i);
  }

  render() {
    const { classes, template, lang } = this.props;
    const details = [];
    const inputs = [];
    const forbiddenAccess =
      template.forbiddenAccess &&
      template.forbiddenAccess.includes(this.props.userLevel);

    for (const key in template.children) {
      const child = template.children[key];
      let array = null;
      let multiple = false;

      if (child.type === "select") {
        switch (child.prop) {
          case "ingredient":
            array = this.props.ingredients;
            multiple = true;
            break;
          case "tool":
            array = this.props.tools;
            multiple = true;
            break;
          default:
            break;
        }
      }

      const disabled =
        child.forbiddenAccess &&
        child.forbiddenAccess.includes(this.props.userLevel);

      inputs.push(
        <LocalizedProperty
          className={classes.item}
          key={`ingredient-${child.name}-${key}-${this.props.i}-${this.props.id}`}
          template={child}
          lang={lang}
          multiple={multiple}
          value={this.state.data[child.name]}
          array={array}
          callback={this.handleBlur.bind(this)}
          disabled={disabled}
        />
      );
      details.push(`${this.state.data[child.name]}`);
    }

    return (
      <ExpansionPanel
        expanded={this.state.data.expanded}
        onChange={this.handleExpandedChange.bind(this)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {!this.state.data.text ||
            (!this.state.data.text.en_GB && !this.state.data.text.de_DE)
              ? "New Step"
              : !this.state.data.expanded
              ? ` Step ${this.props.i + 1} - EN:${
                  this.state.data.text.en_GB
                    ? this.state.data.text.en_GB.substring(0, 20) + "..."
                    : "-"
                } , DE: ${
                  this.state.data.text.de_DE
                    ? this.state.data.text.de_DE.substring(0, 20) + "..."
                    : "-"
                }`
              : `Step ${this.props.i + 1}`}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panelDetails}>
          {inputs.map(el => el)}
          {forbiddenAccess ? null : (
            <Grid container className={classes.deleteContainer}>
              <IconButton
                onClick={this.handleDelete.bind(this)}
                className={classes.delete}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Instruction);

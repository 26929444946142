export const getTitle = state => state.news.title;
export const getSubtitle = state => state.news.subtitle;
export const getDescription = state => state.news.description;
export const getIcon = state => state.news.icon;
export const getImage = state => state.news.image;
export const getActive = state => state.news.active;
export const getLink = state => state.news.link;
export const getEditedPreviously = state => state.news.editedPreviously;
export const getErrors = state => state.news.errors;
export const getToDelete = state => state.news.toDelete;
export const getToDiscard = state => state.news.toDiscard;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.news));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;
  delete data.toDiscard;

  if (data.title)
    data.search_string = `${data.title.de_DE}${data.title.en_GB}`
      .replace(/\s/g, "")
      .toLowerCase();

  // console.log(data);
  return data;
};

import {
  // USER_LEVEL_ADMIN,
  // USER_LEVEL_EMPLOYEE,
  USER_LEVEL_EXTERNAL_EMPLOYEE
} from "common/constants";

export const dataTemplate = [
  {
    label: {
      en_GB: "Carb amount (g) in carb choice",
      de_DE: ""
    },
    name: "carbs",
    type: "table",
    forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
    tableData: [
      {
        name: "keto",
        label: {
          en_GB: "Very strict low carb (Keto)",
          de_DE: "Sehr strikt low carb (Keto)"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "strict",
        label: {
          en_GB: "Strict low carb",
          de_DE: "Strikt low carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "standardLow",
        label: {
          en_GB: "Standard low carb",
          de_DE: "Standard low carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "moderate",
        label: {
          en_GB: "Moderate low carb",
          de_DE: "Moderat low carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "medium",
        label: {
          en_GB: "Medium carb",
          de_DE: "Medium carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "standard",
        label: {
          en_GB: "Standard carb",
          de_DE: "Standard carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      },
      {
        name: "high",
        label: {
          en_GB: "High carb",
          de_DE: "High carb"
        },
        forbiddenAccess: [USER_LEVEL_EXTERNAL_EMPLOYEE],
        required: true,
        type: "number"
      }
    ]
  }
];

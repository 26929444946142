import React from 'react';
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {AdminContextConsumer} from "pages/Admin";
import {menus} from "components/Menus";
import uuid from "uuid/v1"
import * as utils from "common/utils";
import Typography from "@material-ui/core/Typography";
import Loader from "components/Loader";
import {getUserLevel} from "store/selectors/auth"

const styles = theme => ({
    listItemHighlight: {
        backgroundColor: theme.palette.primary.main,
    },
    listItemIcon: {
        color: 'white',
    },
    listItemText: {
        color: 'white',
    },
    listRoot: {
        height: '100vh',
    },
});


class DrawerMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isAdmin: false,
            currentPage: 'Dashboard',
            userAccessLevel: null,
        };
    }

    handleListItemClick = async (entry) => {
        await this.setState({currentPage: entry.primary});
    };

    render() {

        const {classes} = this.props;
        const {currentPage, loading, } = this.state;

        return (
            <React.Fragment>
                <AdminContextConsumer>
                    {({drawerOpen, onMenuChange}) => (
                        <React.Fragment>
                            {loading ? (<Loader/>) : (
                                <div
                                    className={classes.listRoot}
                                >
                                    {menus
                                        .filter(entry => {
                                            return entry.visibleTo.indexOf(this.props.userLevel) !== -1;
                                        })
                                        .map(menu => {
                                            return (
                                                <div key={uuid()}>
                                                    {utils.notNullOrUndefined(menu.title) && drawerOpen ? (
                                                        <ListSubheader disableSticky={true}>{menu.title}</ListSubheader>
                                                    ) : (utils.notNullOrUndefined(menu.title) && !drawerOpen &&
                                                        <Divider/>)}
                                                    {
                                                        menu.entries
                                                            .filter(entry => {
                                                                return entry.visibleTo.indexOf(this.props.userLevel) !== -1;
                                                            })
                                                            .map(entry => {
                                                                return (
                                                                    <ListItem
                                                                        button
                                                                        onClick={async e => {
                                                                            await this.handleListItemClick(entry);
                                                                            return onMenuChange(entry);
                                                                        }}
                                                                        key={entry.primary}
                                                                        className={currentPage === entry.primary ? classes.listItemHighlight : ''}
                                                                    >
                                                                        <Tooltip
                                                                            title={utils.notNullOrUndefined(entry.tooltip) ? entry.tooltip : entry.primary}>
                                                                            <ListItemIcon
                                                                                className={currentPage === entry.primary ? classes.listItemIcon : ''}
                                                                            >
                                                                                {entry.icon}
                                                                            </ListItemIcon>
                                                                        </Tooltip>
                                                                        <ListItemText
                                                                            primary={(<Typography
                                                                                className={currentPage === entry.primary ? classes.listItemText : ''}
                                                                            >
                                                                                {entry.primary}
                                                                            </Typography>)}
                                                                        />
                                                                    </ListItem>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </AdminContextConsumer>
            </React.Fragment>
        )
    }
}

DrawerMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        userLevel: getUserLevel(state)
    }
};


export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(DrawerMenu));
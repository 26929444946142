import React from "react";

import Api from "common/Api";
import { storage } from "common/firebase";

import { withStyles } from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import CancelIcon from "@material-ui/icons/Cancel";
import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
  container: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  error: {
    borderColor: "red"
  },
  heading: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.54)",
    padding: theme.spacing(2)
  },
  gridList: {
    // flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    textAlign: "center",
    minHeight: "315px"
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.3) 100%)"
  },
  margin: {
    margin: theme.spacing(2)
  },
  image: {
    maxHeight: "315px",
    maxWidth: "560px"
  },
  item: {
    border: "2px solid rgba(0, 0, 0, 0.54)",
    height: "300px",
    width: "300px",
    justifyContent: "center",
    alignContent: "center",
    cursor: "pointer",
    margin: "auto"
  },
  disabled: {
    cursor: "auto",
    borderColor: "#dddddd",
    color: "#dddddd"
  },
  icon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%) "
  },
  circularProgress: {
    justifyContent: "center",
    alignContent: "center",
    marginBottom: theme.spacing(2)
  },
  fileInput: {
    display: "none"
  }
});

class Images extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: this.props.error,
      changed: false,
      loading: false,
      largeFilePrompt: false,
      deletePrompt: false,
      deleteIndex: null
    };
  }

  componentDidMount() {
    if (
      this.props.template.required &&
      !this.props.images &&
      !this.props.error &&
      !this.state.error
    ) {
      this.setState({ error: true }, () => this.onError(true));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.single) {
      if (this.props.images !== prevProps.images) {
        if (this.props.images !== undefined && this.props.images !== null) {
          let error = this.props.template.required && this.props.images === "";
          this.setState(
            { error },
            () => this.props.template.required && this.onError(error)
          );
        } else {
          this.setState({ error: this.props.template.required }, () =>
            this.onError(this.props.template.required)
          );
        }
      }
    }
    //TODO for multiple images if needed
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async onChange(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size / 1024 / 1024 <= 3) {
        let img = await this.toBase64(e.target.files[0]);
        //TODO reset files value somehow
        //   e.target.value = "";
        this.setState(
          { loading: true },
          async () =>
            await Api.post("add/photo", {
              photo: img
            })
              .then(result => {
                const storageRef = storage.ref();
                const childRef = storageRef.child(result.data.storagePath);
                childRef.getDownloadURL().then(url => {
                  this.setState({ loading: false, error: false }, () => {
                    this.keepChanges(url);
                    this.onError(false);
                    this.setToDiscard(result.data.storagePath);
                  });
                });
              })
              .catch(err => console.error(err.message))
        );
      } else {
        e.target.value = "";
        this.setState({ largeFilePrompt: true });
      }
    }
  }

  onError(error) {
    const { name, tab } = this.props.template;
    this.props.onError && this.props.onError({ error, name, tab });
  }

  onDelete(e) {
    this.setState({ deletePrompt: true, deleteIndex: e.currentTarget.id });
  }

  setToDiscard(url) {
    this.props.toDiscard && this.props.toDiscard(url);
  }

  handleLargeFileClose(e) {
    this.setState({ largeFilePrompt: false });
  }

  handleDeleteClose(e) {
    if (this.state.deleteIndex !== null && e.currentTarget.id === "yes") {
      let { images } = this.props;
      const toDelete = this.props.single
        ? images
        : images[this.state.deleteIndex];

      if (!this.props.single) {
        images.splice(this.state.deleteIndex, 1);
      } else {
        this.keepChanges("");
      }

      this.setState(
        {
          deletePrompt: false,
          deleteIndex: null
        },
        () =>
          this.props.onDelete &&
          this.props.onDelete(
            toDelete,
            this.props.template.name,
            this.state.deleteIndex
          )
      );
    } else {
      this.setState({ deletePrompt: false });
    }
  }

  keepChanges(url) {
    if (this.props.callback) {
      if (!this.props.single) {
        this.props.callback({
          property: this.props.template.name,
          value: [...this.props.images, url],
          id: this.props.id
        });
      } else {
        this.props.callback({
          property: this.props.template.name,
          value: url,
          id: this.props.id
        });
      }
    }
  }

  render() {
    const { classes, template, lang } = this.props;

    return (
      <Grid
        className={`${classes.container} ${
          this.state.error ? classes.error : ""
        }`}
      >
        <Typography className={classes.heading}>
          {template.label[lang]}
        </Typography>
        <GridList
          className={classes.gridList}
          cellHeight="auto"
          cols={
            this.props.images && this.props.images.length + 1 < 4
              ? this.props.images.length + 1
              : 4
          }
        >
          {!this.props.single ? (
            this.props.images &&
            this.props.images.map((imgSrc, i) => (
              <GridListTile key={`img-${i}-${template.name}-${this.props.id}`}>
                <img className={classes.image} src={imgSrc} alt={imgSrc} />
                <GridListTileBar
                  title={imgSrc}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title
                  }}
                  actionIcon={
                    <IconButton
                      aria-label={`delete-${i}`}
                      className={classes.margin}
                      id={i}
                      onClick={this.onDelete.bind(this)}
                      disabled={this.props.disabled}
                    >
                      <CancelIcon className={classes.title} />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))
          ) : this.props.images ? (
            <GridListTile key={`img-${template.name}-${this.props.id}`}>
              <img
                className={classes.image}
                src={this.props.images}
                alt={this.props.images}
              />
              <GridListTileBar
                title={this.props.images}
                classes={{
                  root: classes.titleBar,
                  title: classes.title
                }}
                actionIcon={
                  <IconButton
                    aria-label={`delete`}
                    className={classes.margin}
                    onClick={this.onDelete.bind(this)}
                    disabled={this.props.disabled}
                  >
                    <CancelIcon className={classes.title} />
                  </IconButton>
                }
              />
            </GridListTile>
          ) : null}

          {!this.props.single ? (
            <GridListTile key={`img-${template.name}`}>
              <input
                accept="image/*"
                className={classes.fileInput}
                type="file"
                id={`add-${template.name}`}
                onChange={this.onChange.bind(this)}
                disabled={this.props.disabled}
              />
              <label htmlFor={`add-${template.name}`}>
                <Grid
                  container
                  className={`${classes.item} ${
                    this.props.disabled ? classes.disabled : ""
                  }`}
                >
                  <AddIcon
                    className={`${classes.icon} ${
                      this.props.disabled ? classes.disabled : ""
                    }`}
                  />
                </Grid>
              </label>
            </GridListTile>
          ) : !this.props.images ? (
            <GridListTile key={`img-${template.name}`}>
              <input
                accept="image/*"
                className={classes.fileInput}
                type="file"
                id={`add-${template.name}`}
                onChange={this.onChange.bind(this)}
                disabled={this.props.disabled}
              />
              <label htmlFor={`add-${template.name}`}>
                <Grid
                  container
                  className={`${classes.item} ${
                    this.props.disabled ? classes.disabled : ""
                  }`}
                >
                  <AddIcon
                    className={`${classes.icon} ${
                      this.props.disabled ? classes.disabled : ""
                    }`}
                  />
                </Grid>
              </label>
            </GridListTile>
          ) : null}
        </GridList>
        <Dialog
          open={this.state.deletePrompt}
          onClose={this.handleDeleteClose.bind(this)}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">{"Delete image"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              Are you sure you want to delete this picture?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id={"no"}
              onClick={this.handleDeleteClose.bind(this)}
              color="primary"
            >
              No
            </Button>
            <Button
              id={"yes"}
              onClick={this.handleDeleteClose.bind(this)}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.largeFilePrompt}
          onClose={this.handleLargeFileClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"File upload limit"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Maximum image size is 3MB.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              id={"ok"}
              onClick={this.handleLargeFileClose.bind(this)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.loading}>
          <DialogTitle>Uploading image...</DialogTitle>
          <DialogContent>
            <Grid container className={classes.circularProgress}>
              <CircularProgress />
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Images);

export const getTitle = state => state.nutrition.title;
export const getDescription = state => state.nutrition.description;
export const getImage = state => state.nutrition.image;
export const getMeals = state => state.nutrition.meals;
export const getEditedPreviously = state => state.nutrition.editedPreviously;
export const getErrors = state => state.nutrition.errors;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.nutrition));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;

  data.meals.forEach(meal => {
    if (meal) {
      delete meal.recipeTitle;
    }
  });

  if (data.title)
    data.search_string = `${data.title.de_DE}${data.title.en_GB}`
      .replace(/\s/g, "")
      .toLowerCase();

  // console.log(data);
  return data;
};

export const getName = state => state.activity.name;
export const getIcon = state => state.activity.icon;
export const getUsage = state => state.activity.usage;
export const getEditedPreviously = state => state.activity.editedPreviously;
export const getErrors = state => state.activity.errors;
export const getToDelete = state => state.activity.toDelete;
export const getToDiscard = state => state.activity.toDiscard;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.activity));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;
  delete data.toDiscard;

  if (data.name)
    data.search_string = `${data.name.de_DE}${data.name.en_GB}`
      .replace(/\s/g, "")
      .toLowerCase();

  // console.log(data);
  return data;
};

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReactTimeout from "react-timeout";
import Fab from "@material-ui/core/Fab";
import MUIDataTable from "mui-datatables";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Loader from "components/Loader";
import * as constants from 'common/constants';
import * as utils from 'common/utils';
import {db} from "common/firebase";
import moment from "moment";
import ConfirmationDialog from "components/ConfirmationDialog";

const SUFFIX_DEBUG = "";
const TRANSFORMATION_CHECK_INTERVAL = 6666;

const styles = theme => ({
    info: {
        padding: '5px 20px',
        margin: '15px 0'
    },
    fabShadowed: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    fabExport: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(10),
    },
    warning: {
        color: theme.palette.secondary.main,
    },
    doneLinearProgress: {
        backgroundColor: 'lightgreen',
    },
    failedLinearProgress: {
        backgroundColor: 'red',
    },
});

class FoodsExport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            transformationsData: [],
            startExportButtonDisabled: true,
            confirmShadowedDialogOpen: false,
        };
    }

    get transformationsData() {
        return this.state.transformationsData;
    }

    componentDidMount() {

        this.checkTransformations()
            .then(() => {
                this.setState({loading: false});
                this.props.setInterval(this.checkTransformations, TRANSFORMATION_CHECK_INTERVAL);
            });
    }

    shouldButtonStartExportShouldBeDisabled = () => {
        let disabled = false;
        // eslint-disable-next-line
        this.state.transformationsData.some(transformationsDatum => {
            if (transformationsDatum.progress < 1) {
                disabled = true;
            }
        });
        return disabled;
    };

    checkTransformations = async () => {
        await db.collection(`transformations${SUFFIX_DEBUG}`)
            .orderBy('created', 'desc')
            .get()
            .then(async transformationsDocs => {
                await this.setState({
                    transformationsData: transformationsDocs.docs
                        .filter(transformationsDoc => {
                            return [
                                // constants.TRANSFORMATION_TYPE_EXPORT,
                                constants.TRANSFORMATION_TYPE_SHADOWED,
                            ].indexOf(transformationsDoc.data().type) !== -1;
                            // return transformationsDoc.data().type === constants.TRANSFORMATION_TYPE_SHADOWED
                        })
                        .map(transformationsDoc => utils.getDataWithIdFromFirebaseDocument(transformationsDoc)),
                })
            })
            .catch(e => {
                console.error('E0037', e);
            });

        await this.setState({
            startExportButtonDisabled: this.shouldButtonStartExportShouldBeDisabled(),
        });

    };

    handleOpenConfirmShadowedDialog = () => {
        this.setState({confirmShadowedDialogOpen: true});
    };

    handleCloseConfirmShadowedDialog = () => {
        this.setState({confirmShadowedDialogOpen: false});
    };

    handleStartShadowed = async () => {
        await this.setState({
            startShadowedButtonDisabled: true,
            // loading: true,
        });
        this.handleCloseConfirmShadowedDialog();

        await db
            .collection(`transformations${SUFFIX_DEBUG}`)
            .doc(`${constants.TRANSFORMATION_TYPE_SHADOWED}-${moment().format('YYYY-MM-DD-HH-mm-ss')}`)
            // .doc(`${request.body.type}-${dateFormat('yyyy-mm-dd-HH-MM-ss')}`)
            .set({
                filename: null,
                originalFilename: null,
                pointer: 0,
                progress: 0,
                status: 'inProgress',
                created: new Date(),
                type: constants.TRANSFORMATION_TYPE_SHADOWED,
            }, {merge: true});

        await this.checkTransformations();
        // await this.setState({
        //     loading: false,
        // });
    };

    render() {

        const {classes} = this.props;
        const {loading, startExportButtonDisabled, confirmShadowedDialogOpen} = this.state;

        if (loading) {
            return <Loader/>;
        }

        return (

            <React.Fragment>
                <React.Fragment>
                    <MUIDataTable
                        title={"Food Export Upload History"}
                        data={this.transformationsData}
                        columns={[
                            {
                                name: "created",
                                label: 'Started at',
                                options: {
                                    filter: true,
                                    sort: false,
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        if (value !== null && value !== undefined) {
                                            return moment.unix(value.seconds).format("LLL");
                                        } else {
                                            return '';
                                        }
                                    },
                                }
                            },
                            {
                                name: "progress",
                                label: 'Progress',
                                options: {
                                    filter: true,
                                    sort: false,
                                    customBodyRender: (value, tableMeta, updateValue) => {
                                        return (
                                            <LinearProgress
                                                classes={{
                                                    barColorPrimary: value === 1 && classes.doneLinearProgress
                                                }}
                                                variant="determinate"
                                                value={value * 100}
                                            />
                                        );
                                    },
                                }
                            },
                            {
                                name: "status",
                                label: 'Status',
                                options: {
                                    filter: false,
                                    sort: false,
                                }
                            },
                        ]}
                        options={{
                            responsive: "scroll",
                            print: false,
                            download: false,
                            search: false,
                            filter: false,
                            rowHover: false,
                            selectableRows: false,
                            customSort: (data, colIndex, order) => {
                                return data.sort((a, b) => {
                                    return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
                                });
                            },
                        }}
                    />
                        <span>
                            <Fab
                                className={classes.fabShadowed}
                                color={"secondary"}
                                onClick={this.handleOpenConfirmShadowedDialog}
                                disabled={startExportButtonDisabled}
                            >
                                <ImportExportIcon/>
                            </Fab>
                        </span>
                    <ConfirmationDialog
                        open={confirmShadowedDialogOpen}
                        onClose={this.handleCloseConfirmShadowedDialog}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        title={"Confirm Shadowed generation"}
                        content={<React.Fragment>This will generate data combining base imports and changes made in the admin.<br/>
                        After that process has finished, it will push changes to mobile App.<br/>
                        Are you sure?</React.Fragment>}
                        cancel={this.handleCloseConfirmShadowedDialog}
                        confirm={this.handleStartShadowed}
                    />
                </React.Fragment>
            </React.Fragment>
        )
    }
}

FoodsExport.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default ReactTimeout(withStyles(styles, {withTheme: true})(FoodsExport));
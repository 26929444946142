export const ENTITY_STATUS_ACTIVE = 'active';
export const ENTITY_STATUS_BLOCKED = 'blocked';
export const USER_LEVEL_NEW = 'new';
export const USER_LEVEL_ADMIN = 'admin';
export const USER_LEVEL_EMPLOYEE = 'employee';
export const USER_LEVEL_EXTERNAL_EMPLOYEE = 'external';
export const USER_LEVEL_UNKNOWN = 'unknown';
export const EMAIL_STATUS_NEW = 'new';
export const EMAIL_STATUS_VERIFICATION_SENT = 'verification_sent';
export const EMAIL_STATUS_VERIFIED = 'verified';
export const TRANSFORMATION_TYPE_BLS = 'bls';
export const TRANSFORMATION_TYPE_REWE = 'rewe';
export const TRANSFORMATION_TYPE_SHADOWED = 'shadowed';
export const TRANSFORMATION_TYPE_EXPORT = 'export';
export const ONE_FOOD_PANEL_VARIANT_SINGLE = 'ONE_FOOD_PANEL_VARIANT_SINGLE';
export const ONE_FOOD_PANEL_VARIANT_MULTIPLE = 'ONE_FOOD_PANEL_VARIANT_MULTIPLE';
export const ONE_FOOD_PANEL_VARIANT_SPECIAL = 'ONE_FOOD_PANEL_VARIANT_SPECIAL';
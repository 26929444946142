export const getEditedPreviously = state => state.carbs.editedPreviously;
export const getHigh = state => state.carbs.high;
export const getKeto = state => state.carbs.keto;
export const getMedium = state => state.carbs.medium;
export const getModerate = state => state.carbs.moderate;
export const getStandard = state => state.carbs.standard;
export const getStandardLow = state => state.carbs.standardLow;
export const getStrict = state => state.carbs.string;
export const getErrors = state => state.carbs.errors;
export const getData = state => {
  let data = JSON.parse(JSON.stringify(state.carbs));
  delete data.errors;
  delete data.editedPreviously;
  delete data.toDelete;
  delete data.toDiscard;

  // console.log(data);
  return data;
};

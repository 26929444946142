// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

export const firebaseApp = firebase.initializeApp(config);
export const db = firebaseApp.firestore();
export const auth = firebase.auth();
export const authObject = firebase.auth;
export const storage = firebase.storage();

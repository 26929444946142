import React from "react";

import moment from "moment";

import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import InputLabel from "@material-ui/core/InputLabel";

import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import LineChart from "recharts/lib/chart/LineChart";
import Line from "recharts/lib/cartesian/Line";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import CartesianGrid from "recharts/lib/cartesian/CartesianGrid";
import Tooltip from "recharts/lib/component/Tooltip";
import Legend from "recharts/lib/component/Legend";

import Loader from "components/Loader";
import { db } from "common/firebase";
import { isNullOrUndefined, asyncForEach } from "common/utils";

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  item: {
    height: "100%"
  },
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline"
  },
  header: {
    margin: theme.spacing(5)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: theme.spacing(15)
  }
});

function SimpleLineChart(props) {
  const { data } = props;
  return (
    // 99% per https://github.com/recharts/recharts/issues/172
    <ResponsiveContainer width="99%" height={320}>
      <LineChart data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="first_open" stroke="#04ADA9" />
        <Line
          type="monotone"
          dataKey="session_start"
          stroke="#FF6344"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="app_remove"
          stroke="#2196f3"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      period: "7days"
    };
  }

  componentDidMount() {
    this.reload();
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.reload();
    });
  };

  reload = () => {
    this.setState({ loading: true }, async () => {
      try {
        const params = ["session_start", "first_open", "app_remove"];
        const data = [];
        const days = [];

        let numberOfDays = 7;
        let when = moment();

        switch (this.state.period) {
          case "7days":
            numberOfDays = 7;
            when = moment();
            break;
          case "30days":
            numberOfDays = 30;
            when = moment();
            break;
          case "thisMonth":
            numberOfDays = moment().date() - 1;
            when = moment();
            break;
          case "lastMonth":
            numberOfDays = moment()
              .subtract(1, "months")
              .daysInMonth();
            when = moment()
              .subtract(1, "months")
              .endOf("month");
            break;
          default:
            numberOfDays = 7;
        }

        for (let day = numberOfDays; day >= 0; day--) {
          const ago = moment(when)
            .startOf("day")
            .subtract(day, "days");
          days.push(ago);
        }

        await asyncForEach(params, async el => {
          let collection = await db
            .doc(`analytics/${el}`)
            .collection("events")
            .where(
              "logTime",
              ">",
              days[0].format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
            )
            .get();

          const events = collection.docs.reduce((accumulator, entry) => {
            const data = entry.data();

            const logTime = moment(data.logTime);
            const dayname = logTime.format("D.M");
            const count = !isNullOrUndefined(accumulator[dayname])
              ? accumulator[dayname]
              : 0;
            accumulator[dayname] = count + 1;
            return accumulator;
          }, {});

          // console.log(el, events);

          for (let i = 0; i < days.length; i++) {
            const label = days[i].format("D.M");

            const eventsCount = !isNullOrUndefined(events[label])
              ? events[label]
              : 0;

            if (isNullOrUndefined(data[i])) {
              data[i] = { name: label };
            }
            data[i][el] = eventsCount;
          }
        });

        // console.log("data", data);
        this.setState({ data: data, loading: false });
      } catch (error) {
        console.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <Grid>
            <div className={classes.head}>
              <Typography
                variant={"h5"}
                className={classes.header}
                align={"center"}
              >
                Events from{" "}
              </Typography>

              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="age-simple">Period</InputLabel> */}
                <Select
                  value={this.state.period}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "period",
                    id: "period"
                  }}
                >
                  <MenuItem value={"7days"}>Last 7 days</MenuItem>
                  <MenuItem value={"30days"}>Last 30 days</MenuItem>
                  <MenuItem value={"lastMonth"}>Last month</MenuItem>
                  <MenuItem value={"thisMonth"}>This month</MenuItem>
                </Select>
              </FormControl>
            </div>
            <SimpleLineChart data={this.state.data} />
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Dashboard);

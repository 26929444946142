import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {Button, withStyles} from '@material-ui/core';
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Copyright from "components/Copyright";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        minHeight: '100vh',
    },
    main: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    divider: {
        margin: '4px',
        backgroundColor: 'inherit',
    }
});

class Info extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
        }
    }

    handleActionButtonClick = (url) => async () => {

        await this.setState({
            redirect: {
                pathname: url,
            }
        });
    };

    render() {

        const {classes} = this.props;
        const {redirect} = this.state;
        const {message, title, actionButtons} = this.props.location.state;

        if (redirect) {
            if (redirect) {
                return <Redirect to={redirect}/>;
            }
        }

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <div className={classes.main}>
                    <Typography variant="h2" component="h1" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        {message}
                    </Typography>
                    {actionButtons && actionButtons.map(actionButton => (
                            <div>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={this.handleActionButtonClick(actionButton.url)}
                                >
                                    {actionButton.text}
                                </Button>
                                <Divider className={classes.divider}/>
                            </div>
                        )
                    )}

                </div>
                <Copyright/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    }
};

Info.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.string,
    actionButtons: PropTypes.array,
    title: PropTypes.string,
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(Info));
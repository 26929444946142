import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Loader from "components/Loader";
import styles from "./styles";
import * as constants from "common/constants";
import TextField from "@material-ui/core/TextField";

const allowedForExternalBoiWhitelist = ["en0", "enN", "de0", "deN"];

class FoodOneServingSize extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      servingSizePropValue: null,
      errors: []
    };
  }

  componentDidMount() {
    const errors = [];

    if (
      this.props.servingSizePropValue === "" ||
      this.props.servingSizePropValue === 0
    ) {
      errors.push(`Empty value!`);
      this.props.updateErrors(
        this.props.servingSizeIndex,
        this.props.servingSizePropKey,
        "Empty value!"
      );
    }

    this.setState({
      servingSizePropValue: this.props.servingSizePropValue,
      loading: false,
      errors: errors
    });
    // this.props.updateErrors("Serving Sizes", errors);
  }

  get isAllowedForUserToEdit() {
    if (this.props.accessLevel === constants.USER_LEVEL_ADMIN) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EMPLOYEE) {
      return true;
    }

    if (this.props.accessLevel === constants.USER_LEVEL_EXTERNAL_EMPLOYEE) {
      return (
        allowedForExternalBoiWhitelist.indexOf(
          this.props.servingSizePropKey
        ) !== -1
      );
    }

    return false;
  }

  handleChange = () => event => {
    const errors = [];

    if (event.target.value === "" || event.target.value === 0) {
      errors.push(`Invalid value!`);
    }
    this.setState({
      servingSizePropValue: event.target.value,
      errors: errors
    });

    this.props.updateProperty(
      this.props.servingSizeIndex,
      this.props.servingSizePropKey,
      event.target.value
    );
    this.props.updateErrors("Serving Sizes", errors);
  };

  render() {
    const { classes, servingSizePropLabel } = this.props;

    const { loading, servingSizePropValue, errors } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <TextField
        className={classes.servingSizesTranslationInput}
        disabled={!this.isAllowedForUserToEdit}
        helperText={errors.join(", ")}
        error={errors.length > 0}
        variant="outlined"
        fullWidth={true}
        label={servingSizePropLabel}
        value={servingSizePropValue}
        onChange={this.handleChange()}
      />
    );
  }
}

FoodOneServingSize.propTypes = {
  classes: PropTypes.object,
  servingSizePropKey: PropTypes.string.isRequired,
  servingSizePropLabel: PropTypes.string.isRequired,
  servingSizeIndex: PropTypes.number.isRequired,
  servingSizePropValue: PropTypes.any,
  accessLevel: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(FoodOneServingSize);

import React from "react";
import { connect } from "react-redux";

import { getUserLevel } from "store/selectors/auth";
import * as selectors from "store/selectors/news";
import {
  setProperty,
  setError,
  setToDelete,
  setToDiscard,
  setInitData,
  discardAllData
} from "store/reducers/news";

import { withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { db, storage } from "common/firebase";
import BasicProperty from "components/BasicProperty";
import ConfirmationDialog from "components/ConfirmationDialog";
import EditActions from "components/EditActions";
import Images from "components/Images";
import LocalizedProperty from "components/LocalizedProperty";

// import MealView from "./MealView";
import { dataTemplate } from "data/news";

const styles = theme => ({
  container: {
    border: "1px solid grey"
  },
  content: {
    flexDirection: "column",
    margin: theme.spacing(2)
  },
  addButtonContainer: {
    backgroundColor: theme.palette.primary.light,
    // boxShadow:
    //   "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    margin: theme.spacing(2),
    textAlign: "center"
    // cursor: "pointer"
  },
  addButton: {
    color: "#ffffff",
    width: "100%"
  },
  root: {
    width: "100%"
  },
  badge: {
    padding: theme.spacing(0, 1)
  }
});

const stateToProps = state => ({
  title: selectors.getTitle(state),
  subtitle: selectors.getSubtitle(state),
  description: selectors.getDescription(state),
  link: selectors.getLink(state),
  active: selectors.getActive(state),
  icon: selectors.getIcon(state),
  image: selectors.getImage(state),
  editedPreviously: selectors.getEditedPreviously(state),
  errors: selectors.getErrors(state),
  saveData: selectors.getData(state),
  userLevel: getUserLevel(state),
  toDiscard: selectors.getToDiscard(state),
  toDelete: selectors.getToDelete(state)
});

const actionsToProps = dispatch => ({
  setProperty: payload => dispatch(setProperty(payload)),
  setError: payload => dispatch(setError(payload)),
  setToDelete: payload => dispatch(setToDelete(payload)),
  setToDiscard: payload => dispatch(setToDiscard(payload)),
  setInitData: payload => dispatch(setInitData(payload)),
  discardAllData: payload => dispatch(discardAllData(payload))
});

class NewsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      loading: true,
      data: null,
      changes: {},
      shadowDoc: null,
      panelStructure: {},
      topProperties: [],
      confirmationDialog: false
    };
  }

  componentDidMount() {
    if (this.props.doc && this.props.doc.data()) {
      let data = this.props.doc.data();
      this.props.setInitData(data);
    }
  }

  handleSave = async () => {
    if (this.props.errors && Object.values(this.props.errors).length > 0) {
      this.setState({
        confirmationAction: "save",
        confirmationDialog: true,
        confirmationText:
          "There are errors in filled in data. You need to fix them before you save."
      });
    } else {
      this.save();
    }
  };

  async save() {
    if (this.props.editedPreviously) {
      this.setState({ loading: true });
      let doc = null;
      if (this.props.doc) {
        doc = this.props.doc.ref;
      } else {
        doc = db.collection("/news").doc();
      }

      let timestamp = null;
      if (!this.props.doc) {
        timestamp = `${Date.now()}`;
      } else if (this.props.doc.data()) {
        timestamp = this.props.doc.data().timestamp;
      }

      doc
        .set(
          { ...this.props.saveData, timestamp }
          // { merge: true }
        )
        .then(() => {
          this.deleteImages();
          this.props.handleChange();
          this.props.discardAllData();
        });
    }
  }

  handleReset = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "reset",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to reset?"
      });
    }
  };

  handleClose = () => {
    if (this.props.editedPreviously) {
      this.setState({
        confirmationAction: "cancel",
        confirmationDialog: true,
        confirmationText:
          "You made some changes, are you sure you want to cancel?"
      });
    } else {
      this.props.handleCancel();
      this.discardAllData();
    }
  };

  handleConfirmation() {
    switch (this.state.confirmationAction) {
      case "cancel":
        this.props.handleCancel();
        this.discardAllData();
        break;
      case "reset":
        this.discardImages();
        this.props.setInitData(this.props.doc ? this.props.doc.data() : null);
        break;

      default:
        break;
    }
    this.setState({ confirmationDialog: false });
  }

  discardAllData() {
    this.discardImages();
    this.props.discardAllData();
  }

  discardImages() {
    let { toDiscard } = this.props;
    // console.log("discardImages: ", toDiscard);
    for (let i = 0; i < toDiscard.length; i++) {
      let imgRef = storage.ref().child(toDiscard[i]);
      this.deleteImage(imgRef);
    }
  }

  deleteImages() {
    let { toDelete } = this.props;
    // console.log("deleteImages: ", toDelete);
    for (let i = 0; i < toDelete.length; i++) {
      let imgRef = storage.refFromURL(toDelete[i]);
      this.deleteImage(imgRef);
    }
  }

  deleteImage(imgRef) {
    const { fullPath } = imgRef;
    imgRef
      .delete()
      .then(function() {
        console.log("File deleted successfully", fullPath);
      })
      .catch(function(error) {
        console.log(
          "Ops. some error occured deleting a file : ",
          fullPath,
          error
        );
      });
  }

  changeTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes, lang, open } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} disableEnforceFocus fullScreen>
          <DialogContent>
            <Grid container className={classes.container}>
              <Grid container className={classes.content}>
                {dataTemplate.map(template => {
                  const forbiddenAccess =
                    template.forbiddenAccess &&
                    template.forbiddenAccess.includes(this.props.userLevel);
                  switch (template.type) {
                    case "textField":
                    case "number":
                      return (
                        <LocalizedProperty
                          key={template.name}
                          value={this.props[template.name]}
                          errors={this.props.errors}
                          callback={this.props.setProperty}
                          onError={this.props.setError}
                          template={template}
                          lang={lang}
                          disabled={forbiddenAccess}
                        />
                      );
                    case "boolean": {
                      return (
                        <BasicProperty
                          key={template.name}
                          value={this.props[template.name]}
                          callback={this.props.setProperty}
                          onError={this.props.setError}
                          template={template}
                          lang={lang}
                          disabled={forbiddenAccess}
                        />
                      );
                    }
                    case "select":
                      return (
                        <BasicProperty
                          key={template.name}
                          template={template}
                          lang={lang}
                          multiple={template.multiple}
                          value={this.props[template.name]}
                          array={template.selectData.map((el, i) => {
                            return { label: el.label[lang], value: i };
                          })}
                          callback={this.props.setProperty}
                          onError={this.props.setError}
                          disabled={forbiddenAccess}
                        />
                      );
                    case "images":
                      return (
                        <Images
                          key={template.name}
                          template={template}
                          lang={lang}
                          images={this.props[template.name]}
                          single={true}
                          callback={this.props.setProperty}
                          onDelete={this.props.setToDelete}
                          onError={this.props.setError}
                          toDiscard={this.props.setToDiscard}
                          disabled={forbiddenAccess}
                        />
                      );
                    default:
                      return (
                        <Typography key={template.name}>
                          Not existing field type {template.type} for{" "}
                          {template.name}
                        </Typography>
                      );
                  }
                })}
              </Grid>
            </Grid>
          </DialogContent>
          <EditActions
            reset={this.handleReset}
            cancel={this.handleClose}
            save={this.handleSave}
            edited={this.props.editedPreviously}
          />
        </Dialog>
        <ConfirmationDialog
          action={this.state.confirmationAction}
          open={this.state.confirmationDialog}
          title={this.state.confirmationText}
          cancel={() => this.setState({ confirmationDialog: false })}
          confirm={this.handleConfirmation.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(stateToProps, actionsToProps)(NewsEdit)
);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DrawerMenu from "components/DrawerMenu";
import {menus} from "components/Menus";
import {auth} from "common/firebase";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import * as utils from 'common/utils';
import * as routes from 'common/routes';
import {Redirect} from "react-router-dom";
import Auth from "common/Auth";
import Loader from "components/Loader";
import {connect} from "react-redux";
import * as actionTypes from "store/actions";
import Dialog from "@material-ui/core/Dialog";
import styles from "./styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogActions from "@material-ui/core/DialogActions";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import axios from 'axios';
import DB from "common/DB";
import DBParams from "common/DBParams";
import Api from "common/Api";

const AdminContext = React.createContext();

class Admin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            masterdataFetching: true,
            open: true,
            page: menus[process.env.REACT_APP_PAGE_SELECTED_LVL1].entries[process.env.REACT_APP_PAGE_SELECTED_LVL2],
            user: null,
            anchorEl: null,
            redirect: null,

            // change pass stuff
            changePasswordDialogOpen: false,
            passwordChangeInProgress: false,
            oldPasswordCheckResultErrorMessage: '',
            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',
            showOldPassword: false,
            showNewPassword: false,
            showConfirmationPassword: false,
            authVerifiedAtLeastOnce: false,

            changeEmailDialogOpen: false,
            emailChangeInProgress: false,
            email: '',
            emailCheckResultErrorMessage: '',
        };

        auth.onAuthStateChanged(async (user) => {

            if (utils.isNullOrUndefined(user)) {
                axios.defaults.headers.common['Authorization'] = null;
                await this.setState({
                    loading: false,
                    redirect: {pathname: routes.LOGIN},
                    authVerifiedAtLeastOnce: true,
                });
                await this.props.onRemoveUser();
            } else {
                user.getIdToken(true)
                    .then(idToken => {
                        // User is signed in.
                        if (process.env.NODE_ENV !== 'production') {
                            console.info(`token: Bearer ${idToken}`);
                        }
                        axios.defaults.headers.common['Authorization'] = `Bearer ${idToken}`;
                        user.getIdTokenResult()
                            .then(additionalData => {
                                user.additionalData = additionalData;
                                this.props.onAddUser(user);
                                this.setState({
                                    loading: false,
                                    email: user.email,
                                });
                        });
                    })
                    .catch(async error => {
                        console.error('E0020: ', error);
                        await this.props.onRemoveUser();
                        await this.setState({
                            loading: false,
                            redirect: {pathname: routes.LOGIN},
                            authVerifiedAtLeastOnce: true,
                        });
                        axios.defaults.headers.common['Authorization'] = null;
                    });
            }
        });
    }

    get isAuth() {
        return utils.notNullOrUndefined(this.props.user);
    }

    get arePasswordsValid() {
        // return true;
        let ok = true;
        [
            'oldPassword',
            'newPassword',
            'confirmationPassword',
        ].forEach(pass => {
            if (this.state[pass].length < 8) {
                ok = false;
            }
            if (!this.isPasswordValid(pass)) {
                ok = false;
            }
        })
        return ok;
    }

    get isEmailValid() {
        return this.state.emailCheckResultErrorMessage.length === 0;
    }

    componentDidMount() {
        this.fetchMasterdata()
            .then(() => {
                this.setState({
                    masterdataFetching: false,
                })
            })
    }

    fetchMasterdata = async () => {
        let masterdata = {};
        await DB.get('masterdata', new DBParams(), true)
            .then(res => {
                res.docs.forEach(doc => {
                    masterdata[doc.id] = doc.data();
                });
                this.props.onFetchMasterdata(masterdata);
            })
            .catch(e => {
                console.error('E0025', e)
            });
    };

    handleOpenChangePasswordDialog = () => {
        this.clearAuthManagementTextFields();
        this.setState({changePasswordDialogOpen: true});
        this.handleClose(); // dropdown menu
    };

    handleCloseChangePasswordDialog = () => {
        this.setState({changePasswordDialogOpen: false});
    };

    handleOpenChangeEmailDialog = () => {
        this.clearAuthManagementTextFields();
        this.setState({changeEmailDialogOpen: true});
        this.handleClose(); // dropdown menu
    };

    handleCloseChangeEmailDialog = () => {
        this.setState({changeEmailDialogOpen: false});
    };

    isPasswordValid = (type) => {
        return this.getErrorText(type) === ' ';
    };

    handleChangePassword = () => {
        this.setState({passwordChangeInProgress: true});

        // check old pass
        auth.signInWithEmailAndPassword(this.props.user.email, this.state.oldPassword)
            .then(async res => {
                // old password valid, try to change pass
                await this.props.user.updatePassword(this.state.newPassword)
                    .then(() => {
                        // successfully changed pass
                        this.setState({
                            passwordChangeInProgress: false,
                        });
                        this.handleCloseChangePasswordDialog();
                        //TODO: force logout ??
                        // firebase.auth().signOut().then(function() {
                        //     // Sign-out successful.
                        // }).catch(function(error) {
                        //     // An error happened.
                        // });
                    })
                    .catch(e => {
                        // something went terribly wrong with firebase!!
                        console.error('E0022', 'error password update', e);
                        this.setState({
                            oldPasswordCheckResultErrorMessage: e.message,
                            passwordChangeInProgress: false,
                        });
                    })
            })
            .catch(e => {
                // old password is not valid
                console.error('E0023', e.toString());
                this.setState({
                    oldPasswordCheckResultErrorMessage: e.message,
                    passwordChangeInProgress: false,
                });
            });
    };

    handleChangeEmail = () => {
        this.setState({emailChangeInProgress: true});

        // check old pass
        auth.signInWithEmailAndPassword(this.props.user.email, this.state.oldPassword)
            .then(async res => {
                // old password valid, try to change pass
                await this.props.user.updateEmail(this.state.email)
                    .then(() => {
                        // successfully changed pass
                        Api.get(`users/email/resend?__e=${this.state.email}`)
                            .then(async () => {
                                await this.setState({
                                    emailCheckResultErrorMessage: false,
                                });
                                this.handleCloseChangeEmailDialog();
                            });
                        //TODO: force logout ??
                        // firebase.auth().signOut().then(function() {
                        //     // Sign-out successful.
                        // }).catch(function(error) {
                        //     // An error happened.
                        // });
                    })
                    .catch(e => {
                        // something went terribly wrong with firebase!!
                        console.error('E0028', 'error email update', e);
                        this.setState({
                            emailCheckResultErrorMessage: e.message,
                            emailChangeInProgress: false,
                        });
                    })
            })
            .catch(e => {
                // old password is not valid
                console.error('E0027', e.toString());
                this.setState({
                    emailCheckResultErrorMessage: e.message,
                    emailChangeInProgress: false,
                });
            });
    };

    getErrorText = type => {
        let msg = ' ';
        const passInQuestion = this.state[type];

        // if (passInQuestion.length === 0) {
        //     return msg;
        // }

        // eslint-disable-next-line no-unused-vars
        const {oldPassword, newPassword, confirmationPassword, oldPasswordCheckResultErrorMessage} = this.state;

        if (passInQuestion.length < 8) {
            msg = 'Password must be at least 8 chars long'
        }

        switch (type) {

            case 'oldPassword':
                if (oldPasswordCheckResultErrorMessage.length > 0) {
                    msg = oldPasswordCheckResultErrorMessage;
                } else {
                    msg = passInQuestion.length === 0 ? ' ' : msg;
                }
                break;
            case 'newPassword':
                msg = passInQuestion.length === 0 ? ' ' : msg;
                break;
            case 'confirmationPassword':
                if (newPassword !== confirmationPassword) {
                    msg = 'Passwords mismatch!';
                }
                msg = passInQuestion.length === 0 ? ' ' : msg;
                break;
            default:
                break;
        }
        return msg;
    };

    handlePasswordInputFieldChange = type => (event, props) => {
        this.setState({
            [type]: event.target.value,
            oldPasswordCheckResultErrorMessage: '',
        });
    };

    handleEmailInputFieldChange = (event, props) => {
        const valid = utils.validateEmail(event.target.value);
        this.setState({
            email: event.target.value,
            emailCheckResultErrorMessage: valid ? '' : 'Invalid Email!',
        });
    };

    handleClickShowPassword = type => () => {
        const showPassword = this.state[type];
        this.setState({[type]: !showPassword})
    };

    clearAuthManagementTextFields = () => {
        this.setState({
            passwordChangeInProgress: false,
            oldPasswordCheckResultErrorMessage: '',
            email: this.props.user.email,
            emailCheckResultErrorMessage: '',
            oldPassword: '',
            newPassword: '',
            confirmationPassword: '',
            showOldPassword: false,
            showNewPassword: false,
            showConfirmationPassword: false,
            authVerifiedAtLeastOnce: false,
        })
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    handleMenuChange = (page) => {
        this.setState({page: page})
    };

    handleMenu = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleLogout = async () => {

        await auth.signOut();
        await this.props.onRemoveUser();

        this.handleClose();
    };

    render() {
        const {classes, user} = this.props;

        const {
            loading, redirect, page, open, anchorEl,
            masterdataFetching,
            // change pass stuff
            changePasswordDialogOpen,
            passwordChangeInProgress,
            confirmationPassword,
            newPassword,
            oldPassword,
            showOldPassword,
            showNewPassword,
            showConfirmationPassword,

            changeEmailDialogOpen,
            email,
            emailCheckResultErrorMessage,
            emailChangeInProgress,

        } = this.state;

        if (redirect) {
            return <Redirect to={redirect}/>;
        }

        if (user) {
            Auth.validate(user)
                .then(() => {})
                .catch(async redirect => {
                    await this.setState({
                        redirect: redirect,
                    });
                });
        }

        if (masterdataFetching) {
            return <Loader/>
        }

        return (
            <div className={classes.root}>
                {loading ? (<Loader/>) : (
                    <AdminContext.Provider
                        value={{
                            drawerOpen: open,
                            onMenuChange: this.handleMenuChange
                        }}
                    >
                        <CssBaseline/>
                        <AppBar
                            position="absolute"
                            className={classNames(classes.appBar, open && classes.appBarShift)}
                        >
                            <Toolbar disableGutters={!open} className={classes.toolbar}>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerOpen}
                                    className={classNames(
                                        classes.menuButton,
                                        open && classes.menuButtonHidden,
                                    )}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    {page.primary}
                                </Typography>
                                <Button color="inherit"
                                        onClick={this.handleMenu}>
                                    {isWidthUp('md', this.props.width) &&
                                    user && user.email}
                                    <AccountCircle className={classes.rightIcon}/>
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.handleOpenChangeEmailDialog}>Change Email</MenuItem>
                                    <MenuItem onClick={this.handleOpenChangePasswordDialog}>Change Password</MenuItem>
                                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                </Menu>
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            classes={{
                                paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                            }}
                            open={open}
                        >
                            <div className={classes.toolbarIcon}>
                                <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon/>
                                </IconButton>
                            </div>
                        <List>{this.props.user ? <DrawerMenu/> : null}</List>
                        </Drawer>
                        <main className={classes.content}>
                            <div className={classes.appBarSpacer}/>
                            {page.component}
                        </main>
                        <Dialog
                            open={changePasswordDialogOpen}
                            onClose={this.handleCloseChangePasswordDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            {passwordChangeInProgress ? (
                                <Loader/>
                            ) : (
                                <React.Fragment>
                                    <DialogTitle id="responsive-dialog-title">{"Edit Account"}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            className={clsx(classes.margin, classes.textField)}
                                            variant="outlined"
                                            fullWidth={true}
                                            error={!this.isPasswordValid('oldPassword')}
                                            helperText={this.getErrorText('oldPassword')}
                                            type={showOldPassword ? 'text' : 'password'}
                                            label="Old Password"
                                            value={oldPassword}
                                            onChange={this.handlePasswordInputFieldChange('oldPassword')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('showOldPassword')}
                                                        >
                                                            {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Divider
                                            light={true}
                                        />
                                        <TextField
                                            className={clsx(classes.margin, classes.textField)}
                                            variant="outlined"
                                            fullWidth={true}
                                            error={!this.isPasswordValid('newPassword')}
                                            helperText={this.getErrorText('newPassword')}
                                            type={showNewPassword ? 'text' : 'password'}
                                            label="New Password"
                                            value={newPassword}
                                            onChange={this.handlePasswordInputFieldChange('newPassword')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('showNewPassword')}
                                                        >
                                                            {showNewPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Divider
                                            light={true}
                                        />
                                        <TextField
                                            className={clsx(classes.margin, classes.textField)}
                                            variant="outlined"
                                            fullWidth={true}
                                            error={!this.isPasswordValid('confirmationPassword')}
                                            helperText={this.getErrorText('confirmationPassword')}
                                            type={showConfirmationPassword ? 'text' : 'password'}
                                            label="Confirm New Password"
                                            value={confirmationPassword}
                                            onChange={this.handlePasswordInputFieldChange('confirmationPassword')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('showConfirmationPassword')}
                                                        >
                                                            {showConfirmationPassword ? <VisibilityOff/> :
                                                                <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseChangePasswordDialog} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={this.handleChangePassword}
                                            disabled={!this.arePasswordsValid}
                                            color="primary"
                                            autoFocus
                                        >
                                            Change
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            )}
                        </Dialog>
                        <Dialog
                            open={changeEmailDialogOpen}
                            onClose={this.handleCloseNewEmployeeDialog}
                            aria-labelledby="responsive-dialog-title"
                        >
                            {emailChangeInProgress ? (
                                <Loader/>
                            ) : (
                                <React.Fragment>
                                    <DialogTitle id="responsive-dialog-title">{"Change Email"}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            className={clsx(classes.margin, classes.textField, classes.accountEditInput, classes.accountEditInputEmail)}
                                            variant="outlined"
                                            fullWidth={true}
                                            error={emailCheckResultErrorMessage.length > 0}
                                            helperText={emailCheckResultErrorMessage}
                                            type={'email'}
                                            label="Email"
                                            value={email}
                                            onChange={this.handleEmailInputFieldChange}
                                        />
                                        <Divider
                                            light={true}
                                        />
                                        <TextField
                                            className={clsx(classes.margin, classes.textField)}
                                            variant="outlined"
                                            fullWidth={true}
                                            error={!this.isPasswordValid('oldPassword')}
                                            helperText={this.getErrorText('oldPassword')}
                                            type={showOldPassword ? 'text' : 'password'}
                                            label="Old Password"
                                            value={oldPassword}
                                            onChange={this.handlePasswordInputFieldChange('oldPassword')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword('showOldPassword')}
                                                        >
                                                            {showOldPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseChangeEmailDialog} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={this.handleChangeEmail}
                                            disabled={!this.isEmailValid}
                                            color="primary"
                                            autoFocus
                                        >
                                            Change
                                        </Button>
                                    </DialogActions>
                                </React.Fragment>
                            )}
                        </Dialog>
                    </AdminContext.Provider>
                )}
            </div>
        );
    }
}

Admin.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        metadata: state.metadata,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddUser: (user) => dispatch({type: actionTypes.ADD_USER, user: user}),
        onRemoveUser: () => dispatch({type: actionTypes.REMOVE_USER}),
        onFetchMasterdata: (masterdata) => dispatch({type: actionTypes.FETCH_MASTER_DATA, masterdata: masterdata})
    }
};

export const AdminContextConsumer = AdminContext.Consumer;

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(withStyles(styles, {withTheme: true})(Admin)));